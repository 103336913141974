import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  lng: 'es',
  debug: false,
  fallbackLng: 'es',
  resources: {
    es: {
      translation: {
        'Tus datos': 'Tus datos',
        'Datos de cuenta': 'Datos de cuenta',
        'Datos personales': 'Datos personales',
        Username: 'Username',
        Email: 'Email',
        'Email invalido': 'Ingrese un email válido',
        'Ingrese emails válidos': 'Ingrese emails válidos',
        Contraseña: 'Contraseña',
        'Contraseña actual': 'Contraseña actual',
        'Nueva contraseña': 'Nueva contraseña',
        'Confirmar contraseña': 'Confirmar contraseña',
        'Contraseña incorrecta': 'Contraseña incorrecta',
        'Contraseña Invalida': 'La contraseña debe contener al menos 8 dígitos',
        'Cambiar contraseña': 'Cambiar contraseña',
        'Iniciar Sesión': 'Iniciar Sesión',
        Error: 'Ha ocurrido un error',
        Teléfono: 'Teléfono',
        CUIT: 'CUIT',
        Documento: 'Documento',
        'Nombre y apellido': 'Nombre y apellido',
        'Nombre de usuario': 'Nombre de usuario',
        formatNumber: '{{val, number(minimumFractionDigits: 2)}}',
        formatCurrency: '{{val, currency(USD)}}',
        'Información de tokens': 'Información de tokens',
        Guardar: 'Guardar',
        'Mis datos': 'Mis datos',
        'Cerrar sesión': 'Cerrar sesión',
        'La contraseña debe tener al menos 8 carácteres':
          'La contraseña debe tener al menos 8 carácteres',
        'Las contraseñas no coinciden': 'Las contraseñas no coinciden',
        'Este campo es requerido': 'Este campo es requerido',
        'La contraseña debe contener al menos una minúscula, una mayúscula y un dígito (1 - 9)':
          'La contraseña debe contener al menos una minúscula, una mayúscula y un dígito (1 - 9)',
        'Las contraseñas deben coincidir': 'Las contraseñas deben coincidir',
        'La nueva contraseña debe diferir de la anterior':
          'La nueva contraseña debe diferir de la anterior',
        'Ingrese un email válido': 'Ingrese un email válido',
        'Envío de notificaciones': 'Envío de notificaciones',
        Enviar: 'Enviar',
        Template: 'Template',
        Asunto: 'Asunto',
        Destinatario: 'Destinatario',
        'Para enviar a todos ingrese "todos". Para enviar a varios separarlos por ";"':
          'Para enviar a todos ingrese "todos". Para enviar a varios separarlos por ";"',
        'La contraseña se ha actualizado con éxito': 'La contraseña se ha actualizado con éxito',
        'Distribucion de BTC': 'Distribución de BTC',
        'Total cantidad a distribuir': 'Total cantidad a distribuir',
        'Total a distribuir debe ser mayor a 0': 'Total a distribuir debe ser mayor a 0',
        'Debe ingresar un numero': 'Debe ingresar un número',
        Solicitar: 'Solicitar',
        'Confirmar BTC Distribucion':
          'Al confirmar se le asignará a los usuarios {{BTCPerAsset}} BTC por cada {{unitAsset}} {{asset}} que dispongan',
        Confirmar: 'Confirmar',
        Cancelar: 'Cancelar',
        Atención: 'Atención',
        'Distribucion OK': 'La distribución de activos se ha realizado correctamente',
        'Distribucion Error':
          'Sin embargo ha ocurrido un error al enviar el mail a los siguientes usuarios: {{errorMails}}',
        Distribuciones: 'Distribuciones',
        Notificar: 'Notificar',
        'Los X han sido solicitados. En breve recibirá un correo electrónico con información sobre la transferencia.':
          'Los {{asset}} han sido solicitados. En breve recibirá un correo electrónico con información sobre la transferencia.',
        'Hubo un error al intentar solicitar la transferencia de Bitcoins. Vuelva a intentarlo en unos instantes.':
          'Hubo un error al intentar solicitar la transferencia de Bitcoins. Vuelva a intentarlo en unos instantes.',
        Finalizar: 'Finalizar',
        '¿Olvidaste tu contraseña?': '¿Olvidaste tu contraseña?',
        '¿Tienes problemas para iniciar sesión?': '¿Tenés problemas para iniciar sesión?',
        'Ingresa tu correo electrónico y te enviaremos un enlace para que recuperes el acceso a tu cuenta.':
          'Ingresa tu correo electrónico y te enviaremos un enlace para que recuperes el acceso a tu cuenta.',
        'Hemos enviado un email con un link para que puedas recuperar el acceso a tu cuenta.':
          'Hemos enviado un email con un link para que puedas recuperar el acceso a tu cuenta.',
        'Algo salió mal. Por favor reintente.': 'Algo salió mal. Por favor reintente.',
        'Crea una contraseña segura': 'Crea una contraseña segura',
        'Su contraseña debe tener 8 o más carácteres e incluir al menos un número, una minúscula y una mayúscula.':
          'Su contraseña debe tener 8 o más carácteres e incluir al menos un número, una minúscula y una mayúscula.',
        'La contraseña se ha actualizado con éxito.': 'La contraseña se ha actualizado con éxito.',
        Reestablecer: 'Reestablecer',
        'Reestablecer contraseña': 'Reestablecer contraseña',
        Acumulado: 'Acumulado',
        'Mis criptos': 'Mis criptos',
        'Asegúrese que la dirección sea correcta': 'Asegúrese que la dirección sea correcta',
        'Dirección de la wallet': 'Dirección de la wallet',
        Aceptar: 'Aceptar',
        Disponible: 'Disponible',
        'Enviado con éxito': 'Enviado con éxito',
        'Notificar usuarios Error':
          'Ha ocurrido un error al enviar el mail a los siguientes usuarios {{errorMails}}',
        'La dirección ingresada no es válida': 'La dirección ingresada no es válida',
        'La wallet fue asociada con éxito.': 'La wallet fue asociada con éxito.',
        Modificar: 'Modificar',
        '¿Donde encuentro mi dirección?': '¿Donde encuentro mi dirección?',
        'Mi wallet': 'Mi wallet',
        'X Distribuido': '{{tokenId}} Distribuido',
        ToDistribute: 'Pendiente',
        Requested: 'Solicitado',
        Delivered: 'Transferido',
        InTransit: 'Procesando',
        Failed: 'Fallido',
        'Total asignado': 'Total asignado',
        Asignado: 'Asignado',
        'Contraseña de la wallet': 'Contraseña de la wallet',
        'Contraseña del admin': 'Contraseña del admin',
        'Información de la distribución': 'Información de la distribución',
        Período: 'Período',
        Responsable: 'Responsable',
        'Usuarios pagados': 'Usuarios pagados',
        'Pendiente de envio': 'Pendiente de envio',
        Enviado: 'Enviado',
        'Fecha Desde': 'Fecha Desde',
        'Fecha Hasta': 'Fecha Hasta',
        'La fecha final debe ser mayor a la fecha inicial':
          'La fecha final debe ser mayor a la fecha inicial',
        'Historial de distribuciones BTC': 'Historial de distribuciones BTC',
        'Detalle de la distribución': 'Detalle de la distribución',
        'Formato de fecha inválido': 'Formato de fecha inválido',
        'A distribuir entre X Y': 'A distribuir entre {{totalTenency}} {{asset}}',
        'Transferir a pendientes': 'Transferir a pendientes',
        'No se encontró la distribución solicitada. Por favor, intente nuevamente.':
          'No se encontró la distribución solicitada. Por favor, intente nuevamente.',
        'Últ. 7 días': 'Últ. 7 días',
        'Default admin user': 'Default admin user',
        'No se encontraron registros': 'No se encontraron registros',
        'Asociar wallet': 'Asociar wallet',
        'no posee una wallet activa. Puede enviarle un correo electrónico para solicitarle que asocie una presionando el botón de abajo.':
          'no posee una wallet activa. Puede enviarle un correo electrónico para solicitarle que asocie una presionando el botón de abajo.',
        'Solicitar wallet': 'Solicitar wallet',
        Volver: 'Volver',
        Estado: 'Estado',
        Total: 'Total',
        '¡Aún no tienes una wallet X registrada!':
          '¡Aún no tienes una wallet {{tokenId}} registrada!',
        'Acción ejecutada con éxito': 'Acción ejecutada con éxito',
        'El valor no puede tener más de X decimales':
          'El valor no puede tener más de {{decimals}} decimales',
        'Solo se enviará a los usuarios pendientes y que dispongan de una wallet asociada.':
          'Solo se enviará a los usuarios pendientes y que dispongan de una wallet asociada.',
        'Se ha actualizado la web a la ultima versión!':
          'Se ha actualizado la web a la ultima versión!',
        'Por favor ingrese nuevamente': 'Por favor ingrese nuevamente',
        'Vincular wallet de pagos': 'Vincular wallet de pagos',
        'Los BTC que se envien provendrán de la wallet a la que pertenezca la clave privada.':
          'Los BTC que se envien provendrán de la wallet a la que pertenezca la clave privada.',
        'Este proceso puede demorar varios minutos.': 'Este proceso puede demorar varios minutos.',
        'Ya hay una wallet vinculada. La puede reemplazar vinculando una nueva.':
          'Ya hay una wallet vinculada. La puede reemplazar vinculando una nueva.',
        'Clave privada': 'Clave privada',
        'Se ha iniciado el proceso para importar la wallet exitosamente.':
          'Se ha iniciado el proceso para importar la wallet exitosamente.',
        Vincular: 'Vincular',
        'Debe ser formato WIF (Wallet Import Format)':
          'Debe ser formato WIF (Wallet Import Format)',
        'Frase de contraseña': 'Frase de contraseña',
        'Será la clave para poder realizar operaciones con la wallet vinculada.':
          'Será la clave para poder realizar operaciones con la wallet vinculada.',
        'Vincular nueva wallet de pagos': 'Vincular nueva wallet de pagos',
        Wallet: 'Wallet',
        'Desbloquear wallet de pagos': 'Desbloquear wallet de pagos',
        'La wallet de pagos ha sido desbloqueada con éxito.':
          'La wallet de pagos ha sido desbloqueada con éxito.',
        'La wallet de pagos ha sido bloqueada con éxito.':
          'La wallet de pagos ha sido bloqueada con éxito.',
        'La clave privada ingresada se sigue procesando.':
          'La clave privada ingresada se sigue procesando.',
        'La wallet de pagos debe estar desbloqueada para poder enviar.':
          'La wallet de pagos debe estar desbloqueada para poder enviar.',
        'No se pudo bloquear. Por favor reintente.': 'No se pudo bloquear. Por favor reintente.',
        'No se pudo desbloquear. Por favor reintente.':
          'No se pudo desbloquear. Por favor reintente.',
        Bloqueada: 'Bloqueada',
        Desbloqueada: 'Desbloqueada',
        Procesando: 'Procesando',
        '¿Esta seguro que desea bloquear la wallet de pagos?':
          '¿Esta seguro que desea bloquear la wallet de pagos?',
        'Para enviar X es necesario tener vinculada la wallet de pagos.':
          'Para enviar {{asset}} es necesario tener vinculada la wallet de pagos.',
        'La wallet de pagos se está importando.': 'La wallet de pagos se está importando.',
        'Este usuario es administrador': 'Este usuario es administrador',
        Copiar: 'Copiar',
        Editar: 'Editar',
        Nombre: 'Nombre',
        Apellido: 'Apellido',
        'Tipo documento': 'Tipo documento',
        'Número de documento': 'Número de documento',
        'Número de teléfono': 'Número de teléfono',
        Contacto: 'Contacto',
        'Carta oferta': 'Carta oferta',
        UIF: 'UIF',
        PEP: 'PEP',
        'Contrato de fideicomiso': 'Contrato de fideicomiso',
        'Es admin': 'Es admin',
        'Poseedor de documentos': 'Poseedor de documentos',
        '¡Copiado al portapapeles!': '¡Copiado al portapapeles!',
        Usuarios: 'Usuarios',
        'Hubo un error al obtener la información del usuario X. Por favor, intente nuevamente o contacte al administrador.':
          'Hubo un error al obtener la información del usuario {{userId}}. Por favor, intente nuevamente o contacte al administrador.',
        'Confirmar operación': 'Confirmar operación',
        Si: 'Si',
        No: 'No',
        '¿Seguro que desea darle permisos de Administrador a este usuario?':
          '¿Seguro que desea darle permisos de Administrador a este usuario?',
        'Los cambios se han guardado con éxito.': 'Los cambios se han guardado con éxito.',
        'Ocurrió un error al guardar los cambios.': 'Ocurrió un error al guardar los cambios.',
        Assets: 'Assets',
        'Ver tx': 'Ver tx',
        'Historial de rendimientos': 'Historial de rendimientos',
        Recibido: 'Recibido',
        'En camino': 'En camino',
        'Mis ganancias X': 'Mis ganancias {{asset}}',
        'Mis X': 'Mis {{asset}}',
        Solicitados: 'Solicitados',
        'Ver más': 'Ver más',
        'No se encontraron rendimientos': 'No se encontraron rendimientos',
        'Monto total asignado.': 'Monto total asignado.',
        'Monto enviado a su wallet.': 'Monto enviado a su wallet.',
        'Monto en proceso de envío por la red de nodos.':
          'Monto en proceso de envío por la red de nodos.',
        'Monto solicitado pendiente de envío a su wallet.':
          'Monto solicitado pendiente de envío a su wallet.',
        'Monto que puede solicitar enviar a su wallet.':
          'Monto que puede solicitar enviar a su wallet.',
        Búsqueda: 'Búsqueda',
        'Transferir X Y pendientes': 'Transferir {{amount}} {{asset}} pendientes',
        'X: Aún no tiene una wallet vinculada': '{{asset}}: Aún no tiene una wallet vinculada',
        'Cantidad de X': 'Cantidad de {{asset}}',
        Nuevo: 'Nuevo',
        Opcional: 'Opcional',
        'Correo electrónico': 'Correo electrónico',
        'Alta de usuario': 'Alta de usuario',
        Siguiente: 'Siguiente',
        'Crear token': 'Crear token',
        'Nombre del token': 'Nombre del token',
        'Símbolo del token': 'Símbolo del token',
        CLOSED: 'Cerrado',
        OPEN: 'Abierto',
        OTHER: 'Otro',
        'Debe ser mayor a 0': 'Debe ser mayor a 0',
        'El valor máximo es X': 'El valor máximo es {{max}}',
        Tokens: 'Tokens',
        'El token ha sido creado con éxito.': 'El token ha sido creado con éxito.',
        'Historial de tenencia': 'Historial de tenencia',
        Símbolo: 'Símbolo',
        Tipo: 'Tipo',
        Decimales: 'Decimales',
        'El símbolo no puede tener más de X carácteres':
          'El símbolo no puede tener más de {{max}} carácteres',
        'El nombre no puede tener más de X carácteres':
          'El nombre no puede tener más de {{max}} carácteres',
        'El símbolo ya se encuentra en uso': 'El símbolo ya se encuentra en uso',
        'Balance de la wallet de pagos': 'Balance de la wallet de pagos',
        'Obteniendo balance...': 'Obteniendo balance...',
        'Historial de tenencias MINEN': 'Historial de tenencias MINEN',
        Solicitado: 'Solicitado',
        'Total pendiente de envio': 'Total pendiente de envio',
        'Total enviado': 'Total enviado',
        'Total solicitado': 'Total solicitado',
        '¿Seguro que desea crear el token X?': '¿Seguro que desea crear el token {{tokenName}}?',
        'No se admiten números decimales': 'No se admiten números decimales',
        'Doble factor activado con éxito.': 'Doble factor activado con éxito.',
        'Doble factor desactivado con éxito.': 'Doble factor desactivado con éxito.',
        'Doble factor de autenticación': 'Doble factor de autenticación',
        'Habilitar doble factor de autenticación con Google Authenticator':
          'Habilitar doble factor de autenticación con Google Authenticator',
        'O utilice el siguiente código para vincularlo manualmente:':
          'O utilice el siguiente código para vincularlo manualmente:',
        'Código de Google Authenticator': 'Código de Google Authenticator',
        'Deshabilitar doble factor de autenticación': 'Deshabilitar doble factor de autenticación',
        Habilitar: 'Habilitar',
        Deshabilitar: 'Deshabilitar',
      },
    },
  },
})

export default i18n
