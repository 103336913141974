import { t } from 'i18next'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogProps,
} from '@mui/material'

interface LockPaymentWalletDialogProps extends Omit<DialogProps, 'open'> {
  rejectCallback: () => void
  resolveCallback: () => void
}

export default function LockPaymentWalletDialog(props: LockPaymentWalletDialogProps) {
  const { rejectCallback, resolveCallback, title, ...rest } = props

  return (
    <Dialog {...rest} open>
      <DialogTitle>{t('Atención')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('¿Esta seguro que desea bloquear la wallet de pagos?')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={rejectCallback}>
          {t('Cancelar')}
        </Button>
        <Button variant="contained" onClick={resolveCallback}>
          {t('Confirmar')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
