const GET_PRICE = '/prices'
const VARIATION_PATH = '/variation'

export const getPriceUrl = (tokenId: string, timeframe?: string, at?: Date): string => {
  let url = `${GET_PRICE}/${tokenId}`
  if (at) {
    url += `?at=${at}`
  }

  if (timeframe) {
    url += `?timeframe=${timeframe}`
  }
  return url
}

export const getVariation = (tokenId: string, at?: string): string => {
  let url = `${getPriceUrl(tokenId)}${VARIATION_PATH}`
  if (at) {
    url += `?at=${at}`
  }
  return url
}
