import { Tab, TabProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const AntTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: 'Segoe UI, Roboto, Arial, sans-serif',
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

export default function CustomTab(props: TabProps) {
  return <AntTab {...props} />
}
