import UserAssetInformation from '../UserAssetInformation/UserAssetInformation'
import { Grid, Skeleton } from '@mui/material'
import Symbol from '../Symbol/Symbol'
import { SymbolData } from '../../types/SymbolData'
import { useQuery } from 'react-query'
import QueryKeys from '../../utils/QueryKeys'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import PriceService from '../../api/services/PriceService/PriceService'
import { TokenPrice } from '../../types/TokenPrice'

interface UserWalletContainerProps {
  symbolData: SymbolData
  userId: string
  assetActions?: React.ReactNode
}

export default function UserWalletContainer(props: UserWalletContainerProps) {
  const userAssetsResponse = useQuery(QueryKeys.getFetchUserAssetsKey(props.userId), async () => {
    return await AssetsService.getAssets(props.userId)
  })
  const assetPriceResponse = useQuery<TokenPrice[], Error>(
    QueryKeys.getFetchPriceKey(props.symbolData.symbol),
    async () => {
      return await PriceService.getAssetPrice(props.symbolData.symbol)
    }
  )

  let assetPrice = assetPriceResponse.data?.length ? assetPriceResponse.data[0] : undefined
  const assetAmountAssigned = userAssetsResponse.data?.[props.symbolData.symbol]
    ? userAssetsResponse.data[props.symbolData.symbol].tokenAmountAssigned
    : 0

  return (
    <Grid container alignItems="center">
      <Grid item container xs={8.3} sm={7} alignItems="center">
        <Grid item xs={6} sm={5}>
          <Symbol symbolData={props.symbolData} />
        </Grid>
        <Grid item xs={6} sm={7}>
          {userAssetsResponse.data && assetPrice ? (
            <UserAssetInformation
              assetCount={assetAmountAssigned}
              assetValue={assetPrice.price}
              symbol={props.symbolData.symbol}
            />
          ) : (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
          )}
        </Grid>
      </Grid>
      <Grid item xs={3.7} sm={5}>
        {userAssetsResponse.data && assetPrice && props.assetActions ? (
          props.assetActions
        ) : (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
        )}
      </Grid>
    </Grid>
  )
}
