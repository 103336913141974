import { TokenData } from '../../types/TokenData'
import { t } from 'i18next'
import { Box, List, Typography, Divider, Card, CardContent } from '@mui/material'
import AdminTokenItem from './AdminTokenItem'
import { makeStyles } from '@mui/styles'
import Constants from '../../utils/Constants'
import useSortList from '../../utils/functions/useSortList'

interface AdminTokenListProps {
  tokens: TokenData[]
}

const useStyles = makeStyles({
  principalCard: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
})

const tokenHeader = {
  symbol: t('Símbolo'),
  name: t('Nombre'),
  type: t('Tipo'),
  totalSupply: t('Total'),
  decimals: t('Decimales'),
}

export default function AdminTokenList(props: AdminTokenListProps) {
  const classes = useStyles()
  const sortedTokens = useSortList(props.tokens, 'name')
  return (
    <Box>
      <Card
        sx={{ minWidth: 275, backgroundColor: Constants.GRAY_RGB }}
        className={classes.principalCard}
      >
        <CardContent sx={{ width: '100%', pt: 0, pb: 0, pl: 1, pr: 1 }}>
          {props.tokens.length > 0 ? (
            <List>
              <Box>
                <AdminTokenItem token={tokenHeader} />
                <Divider />
              </Box>
              {sortedTokens.map((token, i) => (
                <Box key={`${token.symbol}-${i}`}>
                  <AdminTokenItem token={token} />
                  <Divider />
                </Box>
              ))}
            </List>
          ) : (
            <Typography variant="subtitle1" mt={3} mb={2}>
              {t('No se encontraron registros')}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
