const isProduction = window.env.wallet_environment === 'production'
const ethereumNetwork = window.env.ethereum_network
const etherscanUrl = isProduction
  ? 'https://etherscan.io/token'
  : `https://${ethereumNetwork}.etherscan.io/token`

const getTokenUrl = (tokenAddress: string) => {
  return `${etherscanUrl}/${tokenAddress}`
}

export default getTokenUrl
