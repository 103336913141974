import * as yup from 'yup'

import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { Form, Formik } from 'formik'

import EmailSenderService from '../../api/services/EmailSenderService/EmailSenderService'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AdminNotificationsFormProps {
  onSuccess: Function
}

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
  succeed: {
    color: 'green',
  },
})

export default function AdminNotificationsForm({ onSuccess }: AdminNotificationsFormProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [mailErrorsList, setMailErrorsList] = useState<string[]>([])
  const [sendEmailError, setSendEmailError] = useState<string>('')
  const [isEmailSendingCompleted, setIsEmailSendingCompleted] = useState<boolean>(false)

  return (
    <Grid>
      <Formik
        initialValues={{ to: '', subject: '', template: '' }}
        validationSchema={yup.object({
          to: yup.string().required(t('Este campo es requerido')),
          subject: yup.string().required(t('Este campo es requerido')),
          template: yup.string().required(t('Este campo es requerido')),
        })}
        onSubmit={async function (values, actions) {
          setMailErrorsList([])
          setSendEmailError('')
          setIsEmailSendingCompleted(false)
          const shouldSendToAll = 'todos' === values.to
          let recipients: string[] = []
          if (!shouldSendToAll) {
            recipients = values.to.replaceAll(' ', '').split(';')
            if (
              recipients.filter((recipient) => !EmailSenderService.isAValidEmail(recipient))
                .length > 0
            ) {
              actions.setFieldError(
                'to',
                recipients.length > 1 ? t('Ingrese emails válidos') : t('Email invalido')
              )
              return
            }
          }
          actions.setStatus()
          const mailSenderResponse = await EmailSenderService.sendEmails(
            recipients,
            values.subject,
            values.template,
            shouldSendToAll
          )
          if (mailSenderResponse.statusCode !== HTTPStatusCodes.OK) {
            setSendEmailError(
              mailSenderResponse.msg ? mailSenderResponse.msg : 'Ha ocurrido un error'
            )
          } else {
            setMailErrorsList(mailSenderResponse.data.failedEmails)
            onSuccess(mailSenderResponse.data)
            actions.resetForm({})
          }
          setIsEmailSendingCompleted(true)
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              id="to"
              name="to"
              label={t('Destinatario')}
              value={formik.values.to}
              onChange={formik.handleChange}
              sx={{ marginTop: 1 }}
              helperText={t(
                'Para enviar a todos ingrese "todos". Para enviar a varios separarlos por ";"'
              )}
            />
            {formik.touched.to && formik.errors.to && (
              <div className={classes.error}>{formik.errors.to}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="subject"
              name="subject"
              label={t('Asunto')}
              value={formik.values.subject}
              onChange={formik.handleChange}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              sx={{ marginTop: 1 }}
            />
            {formik.touched.subject && formik.errors.subject && (
              <div className={classes.error}>{formik.errors.subject}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="template"
              name="template"
              label={t('Template')}
              value={formik.values.template}
              onChange={formik.handleChange}
              error={formik.touched.template && Boolean(formik.errors.template)}
              sx={{ marginTop: 1 }}
            />
            {formik.touched.template && formik.errors.template && (
              <div className={classes.error}>{formik.errors.template}</div>
            )}
            <Grid className={classes.error}>{formik.status}</Grid>
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              sx={{ mt: 1 }}
              color="primary"
              variant="contained"
              type="submit"
            >
              {formik.isSubmitting ? <CircularProgress size={26} color="inherit" /> : t('Enviar')}
            </Button>
          </Form>
        )}
      </Formik>
      {isEmailSendingCompleted && (
        <Grid sx={{ pt: 2 }}>
          {sendEmailError ? (
            <Grid className={classes.error}>{sendEmailError}</Grid>
          ) : (
            mailErrorsList.length === 0 && (
              <Grid className={classes.succeed}> {t('Enviado con éxito')} </Grid>
            )
          )}
          {mailErrorsList.length > 0 && (
            <Grid sx={{ mt: 2 }} className={classes.error}>
              {' '}
              {t('Notificar usuarios Error', { errorMails: mailErrorsList.join(' ') })}{' '}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}
