import { Box } from '@mui/material'
import { User } from '../../types/User'
import UserProfile from '../UserProfile/UserProfile'
import UserProfileDetails from '../UserProfileDetails/UserProfileDetails'
import { useCallback, useState } from 'react'
import { t } from 'i18next'
import CustomTabs from '../CustomTabs/CustomTabs'
import CustomTab from '../CustomTabs/CustomTab'
import UserProfileAssets from '../UserProfileAssets/UserProfileAssets'
import { AssetDistribution } from '../../types/AssetDistribution'

interface UserManagementProps {
  asset: string
  assetAmount: number
  isAdmin: boolean
  user: User
  assetRewarded: string
  rewardWalletAddress?: string
  rewardAmount: number
  distributions: AssetDistribution[]
  rewardAmountPendingToSend: number
  onRewardSent: () => void
  onAssetSent: () => void
  assetWalletAddress?: string
  assetAmountPendingToSend: number
}

export default function UserManagement(props: UserManagementProps) {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleSelectedTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }, [])

  const getTab = useCallback(
    (tabNumber: number) => {
      switch (tabNumber) {
        case 0:
          return <UserProfileDetails isAdmin={props.isAdmin} user={props.user} />
        case 1:
          return (
            <UserProfileAssets
              asset={props.asset}
              assetAmount={props.assetAmount}
              user={props.user}
              assetRewarded={props.assetRewarded}
              rewardAmount={props.rewardAmount}
              distributions={props.distributions}
              rewardAmountPendingToSend={props.rewardAmountPendingToSend}
              onRewardSent={props.onRewardSent}
              onAssetSent={props.onAssetSent}
              assetAmountPendingToSend={props.assetAmountPendingToSend}
            />
          )
        default:
          return <></>
      }
    },
    [
      props.asset,
      props.assetAmount,
      props.isAdmin,
      props.user,
      props.assetRewarded,
      props.rewardAmount,
      props.distributions,
      props.rewardAmountPendingToSend,
      props.onRewardSent,
      props.assetAmountPendingToSend,
      props.onAssetSent,
    ]
  )

  return (
    <Box mb={3}>
      <Box pt={2} pb={6}>
        <UserProfile
          isAdmin={props.isAdmin}
          user={props.user}
          rewardWalletAddress={props.rewardWalletAddress}
          assetWalletAddress={props.assetWalletAddress}
          asset={props.asset}
          assetRewarded={props.assetRewarded}
        />
      </Box>
      <Box sx={{ bgcolor: '#fff' }}>
        <CustomTabs value={selectedTab} onChange={handleSelectedTabChange}>
          <CustomTab label={t('Datos personales')} />
          <CustomTab label={t('Assets')} />
        </CustomTabs>
      </Box>
      {getTab(selectedTab)}
    </Box>
  )
}
