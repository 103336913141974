const AUTH = '/auth'
const LOGIN = `${AUTH}/login`
const LOGOUT = `${AUTH}/logout`
const CHANGE_PASSWORD = `${AUTH}/change-password`
const FORGOT_PASSWORD = `${AUTH}/forgot-password`
const RESET_PASSWORD = `${AUTH}/reset-password`
const VALIDATE_PASSWORD = `${AUTH}/validate-password`
const REGISTER = `${AUTH}/register`
const TWO_SECOND_FACTOR = `/two-second-factor`
const TOGGLE_TWO_SECOND_FACTOR = `/toggle-two-second-factor`
const LOGIN_WITH_TWO_SECOND_FACTOR = `/login-two-second-factor`

export const getLoginUrl = () => {
  return `${LOGIN}`
}

export const getChangePasswordUrl = () => {
  return `${CHANGE_PASSWORD}`
}

export const getLogoutUrl = () => {
  return `${LOGOUT}`
}

export const getForgotPasswordUrl = () => {
  return `${FORGOT_PASSWORD}`
}

export const getResetPasswordUrl = () => {
  return `${RESET_PASSWORD}`
}

export const getValidatePasswordUrl = (password: string) => {
  return `${VALIDATE_PASSWORD}/${password}`
}

export const getAuthUserUrl = (userId: string) => {
  return `${AUTH}/${userId}`
}

export const getRegisterUrl = () => {
  return `${REGISTER}`
}

export const getGenerateTwoSecondFactorUrl = (userId: string) => {
  return `${AUTH}/${userId}${TWO_SECOND_FACTOR}`
}

export const getToggleTwoSecondFactorUrl = (userId: string) => {
  return `${AUTH}/${userId}${TOGGLE_TWO_SECOND_FACTOR}`
}

export const getLoginWithTwoSecondFactorUrl = () => {
  return `${AUTH}${LOGIN_WITH_TWO_SECOND_FACTOR}`
}
