import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router'
import { AppContext } from '../../contexts/AppContext'
import WithNavigation from './WithNavigation'

interface RequireAdminRoleProps {
  isAuthenticated: boolean
  isAdmin?: boolean
  userId?: string
  mustChangePassword?: boolean
  children: any
}

export function RequireAdminRole(props: RequireAdminRoleProps) {
  const { pathname } = useLocation()
  const { setRedirectRoute } = useContext(AppContext)

  if (props.isAuthenticated && props.userId && props.mustChangePassword)
    return <Navigate to="/change-password" replace />
  if (props.isAuthenticated && props.isAdmin)
    return <WithNavigation userId={props.userId || ''}>{props.children}</WithNavigation>
  if (props.isAuthenticated) return <Navigate to="/user-wallet" replace />
  setRedirectRoute(pathname)
  return <Navigate to="/login" replace />
}
