import './App.css'
import './i18n/i18n'

import { Route, Routes } from 'react-router-dom'
import { RequireAdminRole } from './utils/hooks/RequireAdminRole'
import { RequireAuth } from './utils/hooks/RequireAuth'
import { AppContext } from './contexts/AppContext'
import { UserLoggedIn } from './types/UserLoggedIn'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import { useState, useEffect } from 'react'
import { PaymentWalletStatus } from './types/PaymentWalletStatus'
import { useQuery } from 'react-query'
import PaymentWalletService from './api/services/PaymentWalletService/PaymentWalletService'
import QueryKeys from './utils/QueryKeys'
import { PermissionLevels } from './types/PermissionLevels'
import { MinandoRoute } from './types/MinandoRoute'
import { minandoRoutes } from './minandoRoutes'

function App() {
  const localStorageUser = localStorage.getItem('minando-user')
  const [user, setUser] = useState<UserLoggedIn | null>(
    localStorageUser ? JSON.parse(localStorageUser) : null
  )
  const [redirectRoute, setRedirectRoute] = useState<string>('')
  const assetSymbol = window.env.asset_symbol

  const paymentWalletStatusResponse = useQuery<PaymentWalletStatus, Error>(
    QueryKeys.getFetchPaymentWalletStatusKey(),
    async () => {
      return await PaymentWalletService.getPaymentWalletStatus(assetSymbol)
    },
    { enabled: Boolean(user) && user?.isAdmin }
  )

  const [paymentWalletStatus, setPaymentWalletStatus] = useState<PaymentWalletStatus | null>(
    paymentWalletStatusResponse?.data || null
  )

  useEffect(() => {
    setPaymentWalletStatus(paymentWalletStatusResponse?.data || null)
  }, [paymentWalletStatusResponse])

  const handleRenderRoute = (route: MinandoRoute, key: number) => {
    switch (route.permissionLevel) {
      case PermissionLevels.NONE:
        return <Route key={key} path={route.path} element={route.element} />
      case PermissionLevels.USER:
        return (
          <Route
            key={key}
            path={route.path}
            element={
              <RequireAuth
                isAuthenticated={Boolean(user)}
                userId={user?.id}
                mustChangePassword={user?.mustChangePassword}
              >
                {route.element}
              </RequireAuth>
            }
          />
        )
      case PermissionLevels.ADMIN:
        return (
          <Route
            key={key}
            path={route.path}
            element={
              <RequireAdminRole
                isAuthenticated={Boolean(user)}
                isAdmin={user?.isAdmin}
                userId={user?.id}
                mustChangePassword={user?.mustChangePassword}
              >
                {route.element}
              </RequireAdminRole>
            }
          />
        )
      default:
        return <></>
    }
  }

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <AppContext.Provider
        value={{
          user,
          setUser,
          paymentWalletStatus,
          setPaymentWalletStatus,
          redirectRoute,
          setRedirectRoute,
        }}
      >
        <Routes>
          {minandoRoutes.map((route, index) => {
            if (route.routes) {
              return route.routes.map((childRoute) =>
                handleRenderRoute({ ...childRoute, path: `${route.path}${childRoute.path}` }, index)
              )
            }
            return handleRenderRoute(route, index)
          })}
        </Routes>
      </AppContext.Provider>
    </LocalizationProvider>
  )
}

export default App
