import { apiClient } from '../../axios'
import { getUserById, getUsersUrl, putUserUrl } from './UserServiceUrls'
import { User } from '../../../types/User'
import { HTTPStatusCodes } from '../../../types/HTTPStatusCodes'
import { UpdateUserBody } from '../../../types/UpdateUserBody'

const get = async (id: string): Promise<User> => {
  const response = await apiClient.get(getUserById(id))
  return response.data
}

const getUsers = async (): Promise<User[]> => {
  const response = await apiClient.get(getUsersUrl())
  return response.data
}

const update = async (userId: string, user: UpdateUserBody) => {
  try {
    const response = await apiClient.put(putUserUrl(userId), user)
    return response.status === HTTPStatusCodes.OK
  } catch {
    return false
  }
}

const UserService = { get, getUsers, update }

export default UserService
