import { Alert, Button, Grid } from '@mui/material'
import AdminDistributeUserList from '../AdminDistributeUserList/AdminDistributeUserList'
import AdminSendAssets from '../AdminSendAssets/AdminSendAssets'
import { DistributionDetail } from '../../types/DistributionDetail'
import { t } from 'i18next'
import { AssetDistribution } from '../../types/AssetDistribution'
import { useState, useMemo, useContext, useEffect, useCallback } from 'react'
import { AssetDistributedStatus } from '../../types/AssetDistributedStatus'
import { AppContext } from '../../contexts/AppContext'
import { useNavigate } from 'react-router'
import { PaymentWalletStatus } from '../../types/PaymentWalletStatus'
import SearchBar from '../SearchBar/SearchBar'

interface AdminDistributeUsersPanelProps {
  assetsDistributed: Array<DistributionDetail>
  assetDistribution: AssetDistribution
  total: number
  refetchAssetDistribution: () => void
}

const pendingDistributedStatuses = [
  AssetDistributedStatus.ToDistribute,
  AssetDistributedStatus.Requested,
  AssetDistributedStatus.Failed,
]

export default function AdminDistributeUsersPanel(props: AdminDistributeUsersPanelProps) {
  const [distributionDetail, setDistributionDetail] = useState<DistributionDetail>()
  const [shouldSendToAll, setShouldSendToAll] = useState<boolean>(false)
  const [filteredDistributeds, setFilteredDistributeds] = useState<DistributionDetail[]>(
    props.assetsDistributed
  )
  const { tokenId, assetsDistributed } = props.assetDistribution || {}
  const { paymentWalletStatus } = useContext(AppContext)
  const navigate = useNavigate()
  const isPaymentWalletPresent =
    paymentWalletStatus === PaymentWalletStatus.LOCKED ||
    paymentWalletStatus === PaymentWalletStatus.UNLOCKED
  const areDistributedsFiltered = useCallback(() => {
    return props.assetsDistributed.length !== filteredDistributeds.length
  }, [props.assetsDistributed.length, filteredDistributeds.length])
  const isInvestmentReturnDistribution = props.assetDistribution.isInvestmentReturn
  useEffect(() => {
    if (areDistributedsFiltered()) {
      return
    }
    setFilteredDistributeds(props.assetsDistributed)
  }, [props.assetsDistributed, areDistributedsFiltered])

  const userListPayload = (distributionDetail: DistributionDetail) => {
    setDistributionDetail(distributionDetail)
    setShouldSendToAll(false)
  }

  const goBack = () => {
    setDistributionDetail(undefined)
    setShouldSendToAll(false)
    setFilteredDistributeds(props.assetsDistributed)
  }

  const setAllTransfer = () => {
    if (!tokenId || !assetsDistributed) return undefined
    setShouldSendToAll(true)
    setDistributionDetail({
      amount: props.total,
      status: AssetDistributedStatus.ToDistribute,
      tokenId: tokenId,
      userId: 'N/A',
      username: 'Usuarios pendientes',
      blockchainTransaction: undefined,
      assetDistributionId: props.assetDistribution.id,
      id: 'N/A',
    })
  }

  const filterDistributedsUsers = (searchString: string) => {
    const filteredList = props.assetsDistributed.filter(
      (distributed) =>
        distributed.username?.toLowerCase().includes(searchString) ||
        distributed.userEmail?.toLowerCase().includes(searchString)
    )
    setFilteredDistributeds(filteredList)
  }

  const arePendingUsers = useMemo(
    () =>
      props.assetsDistributed.filter((distributed) =>
        pendingDistributedStatuses.includes(distributed.status)
      ).length > 0,
    [props.assetsDistributed]
  )

  return (
    <Grid container>
      {isInvestmentReturnDistribution && !isPaymentWalletPresent && (
        <Grid container mb={2}>
          <Alert variant="filled" severity="warning" style={{ marginBottom: 2, width: '100%' }}>
            {t('Para enviar X es necesario tener vinculada la wallet de pagos.', {
              asset: tokenId,
            })}
          </Alert>
          {paymentWalletStatus === PaymentWalletStatus.NOT_IMPORTED && (
            <Grid container item justifyContent="end">
              <Button variant="contained" onClick={() => navigate('/admin/private-key')}>
                {t('Vincular')}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {!distributionDetail ? (
        <Grid container item justifyContent="end">
          {(!isInvestmentReturnDistribution ||
            paymentWalletStatus !== PaymentWalletStatus.NOT_IMPORTED) && (
            <Grid item>
              <Button
                variant="contained"
                sx={{ marginBottom: 1, marginRight: 1 }}
                onClick={setAllTransfer}
                disabled={
                  !arePendingUsers ||
                  (isInvestmentReturnDistribution && !isPaymentWalletPresent) ||
                  props.assetDistribution.amountPendingToSend < 0
                }
              >
                {t('Transferir a pendientes')}
              </Button>
            </Grid>
          )}
          <SearchBar filterFunction={filterDistributedsUsers} />
          <Grid item xs={12}>
            <AdminDistributeUserList
              distributions={filteredDistributeds}
              payload={userListPayload}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <AdminSendAssets
            goBack={() => goBack()}
            shouldSendToAll={shouldSendToAll}
            distributionDetail={distributionDetail}
            onSuccess={props.refetchAssetDistribution}
            isInvestmentReturnDistribution={isInvestmentReturnDistribution}
          />
        </Grid>
      )}
    </Grid>
  )
}
