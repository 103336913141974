import { Box, Typography, List, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AssetDistribution } from '../../types/AssetDistribution'
import UserAssetDistributionItem from './UserAssetDistributionItem'
import { DistributedItem } from './DistributedItem'
import useSortList from '../../utils/functions/useSortList'

interface UserAssetDistributionsProps {
  distributions: AssetDistribution[]
  blockchainTransactionUrl: string
  blockchainTransactionIdKey: string
}

export default function UserAssetDistributions(props: UserAssetDistributionsProps) {
  const { t } = useTranslation()
  const sortedDistributions = useSortList(props.distributions, 'dateTo').reverse()
  const getDistributedItem = (distribution: AssetDistribution): DistributedItem => {
    return {
      dateFrom: distribution.dateFrom,
      dateTo: distribution.dateTo,
      amount: distribution.assetsDistributed[0].amount,
      blockchainTransaction: distribution.assetsDistributed[0].blockchainTransaction,
      status: distribution.assetsDistributed[0].status,
    }
  }

  return (
    <>
      {Boolean(props.distributions.length) ? (
        <List>
          {sortedDistributions.map((distribution: AssetDistribution) => (
            <Box key={distribution.id}>
              <UserAssetDistributionItem
                distributed={getDistributedItem(distribution)}
                blockchainTransactionUrl={props.blockchainTransactionUrl}
                blockchainTransactionUrlTxIdKey={props.blockchainTransactionIdKey}
                asset={distribution.tokenId}
              />
              <Divider />
            </Box>
          ))}
        </List>
      ) : (
        <Typography variant="subtitle1" mt={3} mb={2} ml={2}>
          {t('No se encontraron rendimientos')}
        </Typography>
      )}
    </>
  )
}
