const SEND_SINGLE_EMAIL = '/email-sender/single-email'
const SEND_MULTIPLE_EMAIL = '/email-sender/multiple-email'
const COMPLETE_AND_SEND_EMAIL = '/email-sender/complete-and-send'

export const getSendSingleEmailUrl = (to: string, subject: string, template: string): string => {
  return `${SEND_SINGLE_EMAIL}?to=${to}&subject=${subject}&template=${template}`
}

export const getSendMultipleEmailUrl = (): string => {
  return `${SEND_MULTIPLE_EMAIL}`
}

export const getCompleteAndSendEmailsUrl = (subject: string, template: string): string => {
  return `${COMPLETE_AND_SEND_EMAIL}?subject=${subject}&template=${template}`
}
