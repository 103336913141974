import { Grid, Typography } from '@mui/material'
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'

const Title = (props: { text: string }) => {
  return (
    <Typography variant="body2" fontWeight="bold" color="#a2b2c4">
      {props.text}
    </Typography>
  )
}

const Subtitle = (props: { text: string | null }) => {
  return <Typography variant="body1">{props.text || ''}</Typography>
}

export default function Field(props: { title: string; subtitle: string | Boolean | null }) {
  return (
    <Grid item md={6} xs={12}>
      <Title text={props.title.toUpperCase()} />
      {props.subtitle === null ? (
        '-'
      ) : typeof props.subtitle === 'string' ? (
        <Subtitle text={props.subtitle ? props.subtitle.toString() : props.subtitle} />
      ) : props.subtitle ? (
        <CheckIcon color="success" />
      ) : (
        <CloseIcon color="error" />
      )}
    </Grid>
  )
}
