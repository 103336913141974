import { AlertColor } from '@mui/material'
import React from 'react'
import { CustomSnackbar } from '../../components/CustomSnackbar/CustomSnackbar'

type ProviderContext = readonly [(option: SnackbarOption) => void]

const EMPTY_FUNC = () => {}
const SnackbarContext = React.createContext<ProviderContext>([EMPTY_FUNC])
export const useSnackbar = () => React.useContext(SnackbarContext)

type SnackbarParams = {
  open: boolean
  severity: AlertColor
  message: string
  autoHideDuration: number
}
type SnackbarOption = Omit<SnackbarParams, 'open'>
type SnackbarProps = SnackbarParams

function SnackbarContainer(props: SnackbarProps) {
  return (
    <CustomSnackbar
      open={props.open}
      severity={props.severity}
      message={props.message}
      autoHideDuration={props.autoHideDuration}
    />
  )
}

export default function SnackbarProvider(props: { children: React.ReactNode }) {
  const [snackbars, setSnackbars] = React.useState<SnackbarParams[]>([])
  const createSnackbar = (option: SnackbarOption) => {
    const snackbar = { ...option, open: true }
    setSnackbars((snackbars) => [...snackbars, snackbar])
  }
  const contextValue = React.useRef([createSnackbar] as const)

  return (
    <SnackbarContext.Provider value={contextValue.current}>
      {props.children}
      {snackbars.map((snackbar, i) => {
        const { ...SnackbarParams } = snackbar

        return <SnackbarContainer key={i} {...SnackbarParams} />
      })}
    </SnackbarContext.Provider>
  )
}
