import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router'
import { AppContext } from '../../contexts/AppContext'
import WithNavigation from './WithNavigation'

interface RequireAuthProps {
  isAuthenticated: boolean
  userId?: string
  mustChangePassword?: boolean
  children: any
}

export function RequireAuth(props: RequireAuthProps) {
  const { pathname } = useLocation()
  const { setRedirectRoute } = useContext(AppContext)

  if (props.isAuthenticated && props.userId && props.mustChangePassword)
    return <Navigate to="/change-password" replace />
  if (props.isAuthenticated && props.userId)
    return <WithNavigation userId={props.userId}>{props.children}</WithNavigation>
  setRedirectRoute(pathname)
  return <Navigate to="/login" replace />
}
