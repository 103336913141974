import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { GenerateTwoSecondFactor } from '../../types/GenerateTwoSecondFactor'
import { t } from 'i18next'
import { TwoSecondFactorActivation } from '../../types/TwoSecondFactorActivation'
import { useNavigate } from 'react-router'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import Constants from '../../utils/Constants'
import { makeStyles } from '@mui/styles'
import { useCallback } from 'react'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'

interface EnableTwoSecondFactorProps {
  onTwoSecondFactorToggleSubmit: (
    TwoSecondFactorActivation: TwoSecondFactorActivation
  ) => Promise<any>
  twoSecondFactorData: GenerateTwoSecondFactor
}

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
})

export default function EnableTwoSecondFactor(props: EnableTwoSecondFactorProps) {
  const navigate = useNavigate()
  const classes = useStyles()
  const [openSnackbar] = useSnackbar()

  const showSnackbar = useCallback(
    (message: string) => {
      openSnackbar({
        autoHideDuration: 3000,
        message: message,
        severity: 'success',
      })
    },
    [openSnackbar]
  )

  const handleCopyToClipboard = (textToCopy?: string) => {
    navigator.clipboard.writeText(textToCopy || '')
    showSnackbar(t('¡Copiado al portapapeles!'))
  }

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Typography variant="subtitle1" mb={1}>
        {t('Habilitar doble factor de autenticación con Google Authenticator')}
      </Typography>
      <Alert severity="info">{t('Escanee el QR con la aplicación Google Authenticator')}</Alert>
      <img src={props.twoSecondFactorData.qrCodeImageUrl} height="210px" alt="qr" />
      <Alert severity="info">
        {t('O utilice el siguiente código para vincularlo manualmente:')}
      </Alert>
      <Grid container flexDirection="row" alignItems="center" pt={1}>
        <Grid item xs={8} textAlign="right">
          <Typography color="textSecondary" variant="body2">
            {props.twoSecondFactorData.manualEntrySetupCode}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="left" pl={1}>
          <Tooltip title={t('Copiar').toString()}>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => handleCopyToClipboard(props.twoSecondFactorData.manualEntrySetupCode)}
            >
              <ContentCopyIcon fontSize="small" style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Formik
        initialValues={{ authCode: '' }}
        validationSchema={yup.object({
          authCode: yup.string().required(t(Constants.REQUIRED_FIELD_TEXT)),
        })}
        onSubmit={async (values, actions) => {
          const activationData: TwoSecondFactorActivation = {
            code: values.authCode,
            enabled: true,
          }
          const { succeed } = await props.onTwoSecondFactorToggleSubmit(activationData)
          if (succeed) {
            actions.resetForm()
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} style={{ width: '80%' }}>
            <TextField
              sx={{ marginTop: 4 }}
              id="authCode"
              name="authCode"
              label={t('Código de Google Authenticator')}
              variant="outlined"
              fullWidth
              value={formik.values.authCode}
              onChange={formik.handleChange}
              error={formik.touched.authCode && Boolean(formik.errors.authCode)}
              margin="dense"
            />
            {formik.touched.authCode && formik.errors.authCode && (
              <div className={classes.error}>{formik.errors.authCode}</div>
            )}
            <Grid container marginTop={2} flexDirection="row" justifyContent="flex-end">
              <Grid item>
                <>
                  <Button
                    variant="outlined"
                    disabled={formik.isSubmitting}
                    sx={{ mr: 1 }}
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    {t('Volver')}
                  </Button>
                  <Button variant="contained" disabled={formik.isSubmitting} type="submit">
                    {formik.isSubmitting ? (
                      <CircularProgress size={26} color="inherit" />
                    ) : (
                      t('Habilitar')
                    )}
                  </Button>
                </>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
