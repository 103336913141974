import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './UserAssetInformation.module.css'
import Constants from '../../utils/Constants'

interface UserAssetInformationProps {
  symbol: string
  assetCount: number
  assetValue: number
}

export default function UserAssetInformation(props: UserAssetInformationProps) {
  const assetCount = props.assetCount.toFixed(Constants.BITCOIN_DECIMALS)
  const { t } = useTranslation()

  return (
    <Box padding="5px">
      <Typography className={styles.Title}>{t('Acumulado')}</Typography>
      <Typography className={styles.Subtitle}>
        {assetCount} {props.symbol}
      </Typography>
      <Typography className={styles.Subtitle}>
        {+(props.assetValue * props.assetCount).toFixed(2)} USD
      </Typography>
    </Box>
  )
}
