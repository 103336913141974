import { PriceChartColorVariationType } from './PriceChartColorVariationType'
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, YAxis } from 'recharts'

export interface PriceChartData {
  Datetime: String
  Price: number
}

interface PriceChartProps {
  data: Array<PriceChartData>
  showTooltip?: Boolean
}

export default function PriceChart(props: PriceChartProps) {
  const colorStroke =
    props.data[0].Price > props.data[props.data.length - 1].Price
      ? PriceChartColorVariationType.DOWN
      : props.data[0].Price === props.data[props.data.length - 1].Price
      ? PriceChartColorVariationType.EQUAL
      : PriceChartColorVariationType.UP
  const showTooltip = Boolean(props.showTooltip)

  return (
    <ResponsiveContainer width="100%" height={52}>
      <LineChart data={props.data}>
        <XAxis dataKey="Datetime" type="category" hide />
        <YAxis dataKey="Price" domain={['auto', 'auto']} hide />
        {showTooltip && <Tooltip />}
        <Line type="linear" dataKey="Price" stroke={colorStroke} dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}
