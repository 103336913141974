import { apiClient } from '../../axios'
import { getUserWalletsUrl, createUserWalletUrl } from './UserWalletServiceUrls'
import { UserWallet } from '../../../types/UserWallet'
import { HTTPStatusCodes } from '../../../types/HTTPStatusCodes'

const getUserWallets = async (
  userId: string,
  symbol: string,
  isActive?: boolean
): Promise<UserWallet[]> => {
  const res = await apiClient.get(getUserWalletsUrl(userId, symbol, isActive))
  return res.data
}

const createUserWallet = async (userId: string, symbol: string, address: string) => {
  try {
    const response = await apiClient.post(createUserWalletUrl(userId, symbol), { address })
    return response.status === HTTPStatusCodes.OK
  } catch {
    return false
  }
}

const UserWalletService = {
  getUserWallets,
  createUserWallet,
}

export default UserWalletService
