import { Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import AuthService from '../../api/services/AuthService/AuthService'
import { useNavigate } from 'react-router-dom'

interface ResetPasswordProps {
  token: string
  email: string
}

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
  succeed: {
    color: 'green',
  },
  fullWidth: {
    width: '75%',
  },
})

export default function ResetPasswordForm(props: ResetPasswordProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [succeed, setSucceed] = useState(false)
  const [actionFinished, setActionFinished] = useState<boolean>(false)
  const classSucceed = classes.succeed
  const classError = classes.error
  const navigate = useNavigate()

  return (
    <Grid className={classes.fullWidth}>
      <Formik
        initialValues={{ newPassword: '', confirmNewPassword: '' }}
        validationSchema={yup.object({
          newPassword: yup
            .string()
            .min(8, t('La contraseña debe tener al menos 8 carácteres'))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
              t(
                'La contraseña debe contener al menos una minúscula, una mayúscula y un dígito (1 - 9)'
              )
            )
            .required(t('Este campo es requerido')),
          confirmNewPassword: yup
            .string()
            .required(t('Este campo es requerido'))
            .oneOf([yup.ref('newPassword'), null], t('Las contraseñas deben coincidir')),
        })}
        onSubmit={async (values, actions) => {
          setSucceed(false)
          setActionFinished(false)
          actions.setStatus()
          const wasSuccessful = await AuthService.resetPassword(
            values.newPassword,
            props.token,
            props.email
          )
          if (wasSuccessful) {
            actions.resetForm()
          }
          setActionFinished(true)
          setSucceed(wasSuccessful)
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              id="newPassword"
              name="newPassword"
              label={t('Nueva contraseña')}
              type={!showNewPassword ? 'password' : 'text'}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              sx={{ marginTop: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className={classes.error}>{formik.errors.newPassword}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="confirmNewPassword"
              name="confirmNewPassword"
              label={t('Confirmar contraseña')}
              type={!showConfirmPassword ? 'password' : 'text'}
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
              sx={{ marginTop: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
              <div className={classes.error}>{formik.errors.confirmNewPassword}</div>
            )}
            <Grid sx={{ mb: 1 }} className={classes.error}>
              {formik.status}
            </Grid>
            <Button
              fullWidth
              sx={{ mt: 1 }}
              disabled={succeed}
              color="primary"
              variant="contained"
              type="submit"
            >
              {formik.isSubmitting ? (
                <CircularProgress size={26} color="inherit" />
              ) : (
                t('Reestablecer')
              )}
            </Button>
          </Form>
        )}
      </Formik>
      {actionFinished && (
        <Grid sx={{ pt: 2 }}>
          {
            <Grid className={succeed ? classSucceed : classError}>
              {' '}
              {succeed
                ? t('La contraseña se ha actualizado con éxito.')
                : t('Algo salió mal. Por favor reintente.')}{' '}
            </Grid>
          }
        </Grid>
      )}
      {actionFinished && succeed && (
        <Button
          fullWidth
          size="small"
          sx={{ mt: 1 }}
          variant="contained"
          onClick={() => navigate('/login')}
        >
          {t('Iniciar Sesion')}
        </Button>
      )}
    </Grid>
  )
}
