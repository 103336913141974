import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { Button, TextField, Grid, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import AuthService from '../../api/services/AuthService/AuthService'

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
  fullWidth: {
    width: '100%',
  },
  succeed: {
    color: 'green',
  },
})

export default function ForgotPasswordForm() {
  const { t } = useTranslation()
  const classes = useStyles()
  const [succeed, setSucceed] = useState(false)
  const [actionFinished, setActionFinished] = useState<boolean>(false)
  const classSucceed = classes.succeed
  const classError = classes.error

  return (
    <Grid className={classes.fullWidth} maxWidth="625px">
      <Formik
        initialValues={{ email: '' }}
        validationSchema={yup.object({
          email: yup
            .string()
            .email(t('Ingrese un email válido'))
            .required(t('Este campo es requerido')),
        })}
        onSubmit={async (values, actions) => {
          setSucceed(false)
          setActionFinished(false)
          actions.setStatus()
          const wasSuccessful: boolean = await AuthService.forgotPassword(values.email)
          setSucceed(wasSuccessful)
          setActionFinished(true)
          if (wasSuccessful) {
            actions.resetForm({})
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              id="email"
              name="email"
              label={t('Email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            {formik.touched.email && formik.errors.email && (
              <div className={classes.error}>{formik.errors.email}</div>
            )}
            <Grid className={classes.error}>{formik.status}</Grid>
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              sx={{ mt: 1 }}
              size="small"
              color="primary"
              variant="contained"
              type="submit"
            >
              {formik.isSubmitting ? (
                <CircularProgress size={26} color="inherit" />
              ) : (
                t('Reestablecer contraseña')
              )}
            </Button>
          </Form>
        )}
      </Formik>
      {actionFinished && (
        <Grid sx={{ pt: 2 }}>
          {
            <Grid className={succeed ? classSucceed : classError}>
              {' '}
              {succeed
                ? t(
                    'Hemos enviado un email con un link para que puedas recuperar el acceso a tu cuenta.'
                  )
                : t('Algo salió mal. Por favor reintente.')}{' '}
            </Grid>
          }
        </Grid>
      )}
    </Grid>
  )
}
