import { cardTypes } from './CardTypes'

export interface User {
  id: string
  userName: {
    name: string
    lastName: string
  }
  email: string
  idCard: {
    type: string
    number: string
  }
  phoneNumber: string
  cuit: string | null
  mainContact: string
  offerLetter: boolean
  uif: boolean
  pep: boolean
  trustAgreement: boolean
  documentKeeper: string
  createdAt: Date
  updatedAt: Date
}

export const initUser = (options?: Partial<User>): User => {
  const defaults = {
    id: '',
    userName: {
      name: '',
      lastName: '',
    },
    email: '',
    idCard: {
      type: cardTypes[0],
      number: '',
    },
    phoneNumber: '',
    cuit: '',
    mainContact: '',
    offerLetter: false,
    uif: false,
    pep: false,
    trustAgreement: false,
    documentKeeper: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  }

  return {
    ...defaults,
    ...options,
  }
}
