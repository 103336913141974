import * as yup from 'yup'
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import Alert from '@mui/material/Alert'
import { makeStyles } from '@mui/styles'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PaymentWalletStatus } from '../../types/PaymentWalletStatus'
import PaymentWalletService from '../../api/services/PaymentWalletService/PaymentWalletService'
import Constants from '../../utils/Constants'

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
  succeed: {
    color: 'green',
  },
})

interface AdminImportPrivateKeyFormProps {
  paymentWalletStatus: PaymentWalletStatus
  onSuccess: Function
}

export default function AdminImportPrivateKeyForm(props: AdminImportPrivateKeyFormProps) {
  const classes = useStyles()
  const [didSucceed, setDidSucceed] = useState<boolean | undefined>(undefined)
  const classSucceed = classes.succeed
  const classError = classes.error
  const navigate = useNavigate()
  const isModifiyingAddress =
    props.paymentWalletStatus !== PaymentWalletStatus.NOT_IMPORTED &&
    props.paymentWalletStatus !== PaymentWalletStatus.IMPORT_IN_PROGRESS
  const isImportInProgress = props.paymentWalletStatus === PaymentWalletStatus.IMPORT_IN_PROGRESS
  const assetSymbol = window.env.asset_symbol

  return (
    <Box>
      <Typography variant="h5" marginBottom={3}>
        {t('Vincular nueva wallet de pagos')}
      </Typography>
      <Alert severity="info" style={{ marginBottom: '10px' }}>
        {t('Los BTC que se envien provendrán de la wallet a la que pertenezca la private key.')}
      </Alert>
      <Alert severity="info">{t('Este proceso puede demorar varios minutos.')}</Alert>
      {isModifiyingAddress && (
        <Alert variant="filled" severity="warning" style={{ marginTop: '10px' }}>
          {t('Ya hay una wallet vinculada. La puede reemplazar vinculando una nueva.')}
        </Alert>
      )}
      {isImportInProgress && (
        <Alert variant="filled" severity="success" style={{ marginTop: '10px' }}>
          {t('La wallet de pagos se está importando.')}
        </Alert>
      )}
      <Formik
        initialValues={{ privateKey: '', walletPassphrase: '' }}
        validationSchema={yup.object({
          privateKey: yup.string().required(t(Constants.REQUIRED_FIELD_TEXT)),
          walletPassphrase: yup.string().required(t(Constants.REQUIRED_FIELD_TEXT)),
        })}
        onSubmit={async (values, actions) => {
          setDidSucceed(undefined)
          const didSucceed = await PaymentWalletService.importPrivateKey(
            values.privateKey,
            values.walletPassphrase,
            assetSymbol
          )
          setDidSucceed(didSucceed)
          if (didSucceed) {
            props.onSuccess()
            actions.resetForm()
          }
          actions.setSubmitting(false)
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              sx={{ marginTop: 4 }}
              id="privateKey"
              name="privateKey"
              label={t('Clave privada')}
              variant="outlined"
              fullWidth
              value={formik.values.privateKey}
              onChange={formik.handleChange}
              error={formik.touched.privateKey && Boolean(formik.errors.privateKey)}
              margin="dense"
              disabled={isImportInProgress}
              multiline
              helperText={t('Debe ser formato WIF (Wallet Import Format)')}
            />
            {formik.touched.privateKey && formik.errors.privateKey && (
              <div className={classes.error}>{formik.errors.privateKey}</div>
            )}
            <TextField
              id="walletPassphrase"
              name="walletPassphrase"
              label={t('Frase de contraseña')}
              variant="outlined"
              fullWidth
              value={formik.values.walletPassphrase}
              onChange={formik.handleChange}
              error={formik.touched.walletPassphrase && Boolean(formik.errors.walletPassphrase)}
              margin="dense"
              disabled={isImportInProgress}
              multiline
              helperText={t(
                'Será la clave para poder realizar operaciones con la wallet vinculada.'
              )}
            />
            {formik.touched.walletPassphrase && formik.errors.walletPassphrase && (
              <div className={classes.error}>{formik.errors.walletPassphrase}</div>
            )}
            {didSucceed !== undefined && (
              <Grid sx={{ pt: 2 }}>
                {
                  <Grid className={didSucceed ? classSucceed : classError}>
                    {' '}
                    {didSucceed
                      ? t('Se ha iniciado el proceso para importar la wallet exitosamente.')
                      : t('Algo salió mal. Por favor reintente.')}{' '}
                  </Grid>
                }
              </Grid>
            )}
            <Grid container marginTop={2} flexDirection="row" justifyContent="flex-end">
              <Grid item>
                <>
                  <Button
                    variant="outlined"
                    disabled={formik.isSubmitting}
                    sx={{ mr: 1 }}
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    {t('Volver')}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={formik.isSubmitting || isImportInProgress}
                    type="submit"
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={26} color="inherit" />
                    ) : (
                      t('Vincular')
                    )}
                  </Button>
                </>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
