import { isValidWalletAddress } from '../../utils/validators/WalletValidator'
import WalletAddressSetting from '../WalletAddressSetting/WalletAddressSetting'
import { useQuery } from 'react-query'
import { UserWallet } from '../../types/UserWallet'
import QueryKeys from '../../utils/QueryKeys'
import { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../contexts/AppContext'
import UserWalletService from '../../api/services/UserWalletService/UserWalletService'
import InfoGuide from '../InfoGuide/InfoGuide'
import { Button, Grid } from '@mui/material'
import { t } from 'i18next'
import { InfoGuideStepper } from '../../pages/WalletAddressSettingsPage/WalletAddressSettingsPage'
import AssetsService from '../../api/services/AssetsService/AssetsService'

interface WalletSettingsContainerProps {
  symbol: string
  stepsInfoGuide: InfoGuideStepper
}

export default function WalletSettingsContainer(props: WalletSettingsContainerProps) {
  const [showInfoGuide, setShowInfoGuide] = useState(true)
  const [userActiveWalletAddress, setUserActiveWalletAddress] = useState<string>('')
  const { user } = useContext(AppContext)
  const userId = user ? user.id : ''
  const userWalletsResponse = useQuery<UserWallet[], Error>(
    QueryKeys.getFetchUserWalletsKey(userId, props.symbol),
    async () => await UserWalletService.getUserWallets(userId, props.symbol)
  )
  const tokensResponse = useQuery(
    QueryKeys.getFetchTokensKey(user?.id),
    async () => await AssetsService.getTokens(user?.id),
    { enabled: props.stepsInfoGuide.isAToken }
  )

  const tokenAddress = tokensResponse.data?.find(
    (token) => token.symbol.toUpperCase() === props.stepsInfoGuide.tokenSymbol?.toUpperCase()
  )?.address

  const completeGuide = (text: string): string => {
    return text.replaceAll(
      props.stepsInfoGuide.symbolStringToReplace || '',
      props.stepsInfoGuide?.tokenSymbol || ''
    )
  }

  if (props.stepsInfoGuide.isAToken) {
    props.stepsInfoGuide.steps.forEach((step) => {
      step.title = completeGuide(step.title)
      step.details = completeGuide(step.details)
      if (step.showTokenAddress) {
        step.tokenAddress = tokenAddress
      }
    })
  }

  useEffect(() => {
    const activeWallet = userWalletsResponse.data?.find((wallet) => wallet.isActive)?.address || ''
    setUserActiveWalletAddress(activeWallet)
    setShowInfoGuide(!Boolean(activeWallet))
  }, [userWalletsResponse.data])

  const onAddressSet = async (address: string, cryptoSymbol: string): Promise<boolean> => {
    const didSucceed = await UserWalletService.createUserWallet(userId, cryptoSymbol, address)
    return didSucceed
  }

  return (
    <>
      {showInfoGuide ? (
        <InfoGuide
          steps={props.stepsInfoGuide.steps}
          onFinishSteps={() => setShowInfoGuide(false)}
          key={props.symbol}
        />
      ) : (
        <Grid container>
          <Grid item width="100%">
            <WalletAddressSetting
              symbol={props.symbol}
              walletAddress={userActiveWalletAddress}
              walletAddressValidator={isValidWalletAddress}
              onAddressSet={onAddressSet}
            />
            <Grid container flexDirection="row" justifyContent="flex-end" mt={2}>
              <Grid item>
                <Button size="small" onClick={() => setShowInfoGuide(true)}>
                  {t('¿Donde encuentro mi dirección?')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}
