import { Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'
import { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import AuthService from '../../api/services/AuthService/AuthService'

interface ChangePasswordProps {
  onSuccess: Function
  userEmail: string
}

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
  succeed: {
    color: 'green',
  },
})

export default function ChangePasswordForm({ onSuccess, userEmail }: ChangePasswordProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [succeed, setSucceed] = useState(false)

  return (
    <Grid>
      <Formik
        initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
        validationSchema={yup.object({
          currentPassword: yup.string().required(t('Este campo es requerido')),
          newPassword: yup
            .string()
            .min(8, t('La contraseña debe tener al menos 8 carácteres'))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
              t(
                'La contraseña debe contener al menos una minúscula, una mayúscula y un dígito (1 - 9)'
              )
            )
            .required(t('Este campo es requerido')),
          confirmNewPassword: yup
            .string()
            .required(t('Este campo es requerido'))
            .oneOf([yup.ref('newPassword'), null], t('Las contraseñas deben coincidir'))
            .notOneOf(
              [yup.ref('currentPassword'), null],
              t('La nueva contraseña debe diferir de la anterior')
            ),
        })}
        onSubmit={async (values, actions) => {
          actions.setStatus()
          const changePasswordData = await AuthService.changeUserPassword(values)
          if (changePasswordData.statusCode !== HTTPStatusCodes.OK) {
            actions.setStatus(changePasswordData.msg)
          } else {
            onSuccess()
            setSucceed(true)
            actions.resetForm()
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              id="email"
              name="email"
              label={t('Email')}
              value={userEmail}
              disabled
              sx={{ marginTop: 1 }}
            />
            <TextField
              fullWidth
              margin="dense"
              id="currentPassword"
              name="currentPassword"
              label={t('Contraseña actual')}
              type={!showPassword ? 'password' : 'text'}
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
              sx={{ marginTop: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.currentPassword && formik.errors.currentPassword && (
              <div className={classes.error}>{formik.errors.currentPassword}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="newPassword"
              name="newPassword"
              label={t('Nueva contraseña')}
              type={!showNewPassword ? 'password' : 'text'}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              sx={{ marginTop: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className={classes.error}>{formik.errors.newPassword}</div>
            )}
            <TextField
              fullWidth
              margin="dense"
              id="confirmNewPassword"
              name="confirmNewPassword"
              label={t('Confirmar contraseña')}
              type={!showNewPassword ? 'password' : 'text'}
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
              sx={{ marginTop: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
              <div className={classes.error}>{formik.errors.confirmNewPassword}</div>
            )}
            <Grid sx={{ mb: 1 }} className={classes.error}>
              {formik.status}
            </Grid>
            <Button fullWidth sx={{ mt: 1 }} color="primary" variant="contained" type="submit">
              {formik.isSubmitting ? <CircularProgress size={26} color="inherit" /> : t('Guardar')}
            </Button>
          </Form>
        )}
      </Formik>
      {succeed && (
        <Grid sx={{ pt: 1 }} className={classes.succeed}>
          {t('La contraseña se ha actualizado con éxito')}
        </Grid>
      )}
    </Grid>
  )
}
