import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { t } from 'i18next'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import Constants from '../../utils/Constants'
import { makeStyles } from '@mui/styles'
import { TwoSecondFactorLogin } from '../../types/TwoSecondFactorLogin'
import AuthService from '../../api/services/AuthService/AuthService'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'

interface LoginTwoSecondFactorProps {
  onSuccess: Function
  onCancel: Function
  userEmail: string
  userPassword: string
}

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
  fullWidth: {
    width: '100%',
  },
})

export default function LoginTwoSecondFactorForm(props: LoginTwoSecondFactorProps) {
  const classes = useStyles()

  return (
    <Grid className={classes.fullWidth}>
      <Formik
        initialValues={{ authCode: '' }}
        validationSchema={yup.object({
          authCode: yup.string().required(t(Constants.REQUIRED_FIELD_TEXT)),
        })}
        onSubmit={async (values, actions) => {
          actions.setStatus()
          const credentials: TwoSecondFactorLogin = {
            email: props.userEmail,
            password: props.userPassword,
            twoSecondFactorCode: values.authCode,
          }
          const response = await AuthService.loginWithTwoSecondFactor(credentials)
          if (response.statusCode !== HTTPStatusCodes.OK) {
            actions.setStatus(response.msg)
          } else {
            props.onSuccess(response.data)
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              id="authCode"
              name="authCode"
              label={t('Código de Google Authenticator')}
              value={formik.values.authCode}
              onChange={formik.handleChange}
              error={formik.touched.authCode && Boolean(formik.errors.authCode)}
            />
            {formik.touched.authCode && formik.errors.authCode && (
              <div className={classes.error}>{formik.errors.authCode}</div>
            )}

            <Grid className={classes.error}>{formik.status}</Grid>
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              sx={{ mt: 1 }}
              variant="outlined"
              onClick={() => props.onCancel()}
            >
              {t('Volver')}
            </Button>
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              sx={{ mt: 1 }}
              variant="contained"
              type="submit"
            >
              {formik.isSubmitting ? <CircularProgress size={26} color="inherit" /> : t('Aceptar')}
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
