import { Tooltip } from '@mui/material'
import { t } from 'i18next'
import { AssetDistributedStatus } from '../../types/AssetDistributedStatus'
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  WatchLater as WatchLaterIcon,
  PanTool as PanToolIcon,
} from '@mui/icons-material'

export const getDistributionStatusIcon = (
  status: AssetDistributedStatus,
  showTooltip: boolean = true
) => {
  const getIcon = (status: AssetDistributedStatus) => {
    switch (status) {
      case AssetDistributedStatus.ToDistribute:
        return <WatchLaterIcon sx={{ color: 'darkgray' }} />
      case AssetDistributedStatus.Requested:
        return <PanToolIcon sx={{ color: 'darkgray' }} />
      case AssetDistributedStatus.Delivered:
        return <CheckCircleIcon sx={{ color: '#38BF3B' }} />
      case AssetDistributedStatus.InTransit:
        return <PendingIcon sx={{ color: '#FCCE20' }} />
      case AssetDistributedStatus.Failed:
        return <CancelIcon sx={{ color: '#D54815' }} />
      default:
        break
    }
  }

  return (
    <Tooltip title={showTooltip ? t(status.valueOf()).toString() : ''}>
      {getIcon(status) || <></>}
    </Tooltip>
  )
}
