import styles from './Symbol.module.css'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { SymbolData } from '../../types/SymbolData'

interface SymbolProps {
  symbolData: SymbolData
}

export default function Symbol(props: SymbolProps) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={5} className={styles.SymbolImageContainer}>
        <img src={props.symbolData.symbolImageSrc} className={styles.SymbolImage} alt="logo" />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="inherit" align="left">
          {props.symbolData.symbol}
        </Typography>
        <Typography className={styles.Subtitle} align="left">
          {props.symbolData.symbolName}
        </Typography>
      </Grid>
    </Grid>
  )
}
