import AdminDistributeAssetsPage from './pages/AdminDistributeAssetsPage/AdminDistributeAssetsPage'
import AdminDistributeCheckpointPage from './pages/AdminDistributeCheckpointPage/AdminDistributeCheckpointPage'
import AdminDistributeDetailsPage from './pages/AdminDistributeDetailsPage/AdminDistributeDetailsPage'
import AdminImportPrivateKeyPage from './pages/AdminImportPrivateKeyPage/AdminImportPrivateKeyPage'
import AdminNotificationsPage from './pages/AdminNotificationsPage/AdminNotificationsPage'
import AdminTokensPage from './pages/AdminTokensPage/AdminTokensPage'
import AdminUserAddPage from './pages/AdminUserAddPage/AdminUserAddPage'
import AdminUserListManagementPage from './pages/AdminUserListManagementPage/AdminUserListManagementPage'
import AdminUserManagementPage from './pages/AdminUserManagementPage/AdminUserManagementPage'
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import LoginPage from './pages/LoginPage/LoginPage'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import UpdateVersionPage from './pages/UpdateVersionPage/UpdateVersionPage'
import UserAccountPage from './pages/UserAccountPage/UserAccountPage'
import UserAssetDetails from './pages/UserAssetDetailsPage/UserAssetDetailsPage'
import WalletAddressSettingsPage from './pages/WalletAddressSettingsPage/WalletAddressSettingsPage'
import WalletPage from './pages/WalletPage/WalletPage'
import { PermissionLevels } from './types/PermissionLevels'
import { PageNotFound } from './utils/hooks/PageNotFound'
import EnableTwoSecondFactorPage from './pages/EnableTwoSecondFactorPage/EnableTwoSecondFactorPage'

export const minandoRoutes = [
  {
    path: '/login',
    element: <LoginPage />,
    permissionLevel: PermissionLevels.NONE,
  },
  {
    path: '/login/:email',
    element: <LoginPage />,
    permissionLevel: PermissionLevels.NONE,
  },
  {
    path: '/reset-password',
    element: <ForgotPasswordPage />,
    permissionLevel: PermissionLevels.NONE,
  },
  {
    path: '/reset-password/:email/:token',
    element: <ResetPasswordPage />,
    permissionLevel: PermissionLevels.NONE,
  },
  {
    path: '/user-wallet/',
    routes: [
      {
        path: '',
        element: <WalletPage />,
        permissionLevel: PermissionLevels.USER,
      },
      {
        path: 'wallet-settings',
        element: <WalletAddressSettingsPage />,
        permissionLevel: PermissionLevels.USER,
      },
    ],
  },
  {
    path: '/personal-info',
    element: <UserAccountPage />,
    permissionLevel: PermissionLevels.USER,
  },
  {
    path: '/change-password',
    element: <ChangePasswordPage />,
    permissionLevel: PermissionLevels.USER,
  },
  {
    path: '/:asset/details/',
    element: <UserAssetDetails />,
    permissionLevel: PermissionLevels.USER,
  },
  {
    path: '/two-second-factor/',
    element: <EnableTwoSecondFactorPage />,
    permissionLevel: PermissionLevels.USER,
  },
  {
    path: '/admin/',
    routes: [
      {
        path: 'notify',
        element: <AdminNotificationsPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: 'distribute/:dateFrom/:dateTo',
        element: <AdminDistributeAssetsPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: 'checkpoints',
        element: <AdminDistributeCheckpointPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: ':assetId/distribute/:distributionId',
        element: <AdminDistributeDetailsPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: 'private-key',
        element: <AdminImportPrivateKeyPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: 'users',
        element: <AdminUserListManagementPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: 'users/add',
        element: <AdminUserAddPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: 'users/:userId',
        element: <AdminUserManagementPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
      {
        path: 'tokens',
        element: <AdminTokensPage />,
        permissionLevel: PermissionLevels.ADMIN,
      },
    ],
  },
  {
    path: '/update-version',
    element: <UpdateVersionPage />,
    permissionLevel: PermissionLevels.NONE,
  },
  {
    path: '/*',
    element: <PageNotFound />,
    permissionLevel: PermissionLevels.NONE,
  },
]
