import WalletSettingsContainer from '../../components/WalletSettingsContainer/WalletSettingsContainer'
import assetLogo from '../../assets/images/logo-black.svg'
import metamaskLogo from '../../assets/images/logo-metamask.svg'
import Constants from '../../utils/Constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid } from '@mui/material'
import MinandoFeatureFlags from '../../utils/MinandoFeatureFlags'
import CustomTabs from '../../components/CustomTabs/CustomTabs'
import CustomTab from '../../components/CustomTabs/CustomTab'
import { InfoGuideStep } from '../../components/InfoGuide/InfoGuide'
import { useSearchParams } from 'react-router-dom'

export interface InfoGuideStepper {
  isAToken: boolean
  tokenSymbol?: string
  symbolStringToReplace?: string
  tokenAddressStringToReplace?: string
  steps: InfoGuideStep[]
}

const asset = window.env.asset_symbol

const assetTabs = new Map([
  [Constants.BITCOIN_SYMBOL, 0],
  [asset, 1],
])

export default function WalletAddressSettingsPage() {
  const [searchParams] = useSearchParams()
  const assetQueryParam = searchParams.get('asset')?.toUpperCase()
  const [selectedTab, setSelectedTab] = useState(0)
  const handleSelectedTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }, [])

  useEffect(() => {
    if (assetQueryParam) {
      setSelectedTab(assetTabs.get(assetQueryParam) || 0)
    }
  }, [assetQueryParam])

  const stepsInfoGuideBTC: InfoGuideStepper = useMemo<InfoGuideStepper>(() => {
    return {
      isAToken: false,
      steps: [
        {
          title: '¿Tienes una wallet para BTC?',
          details:
            "Si aún no tienes una wallet para tus BTC y tus crypto te recomendamos escoger una wallet segura antes de continuar. Puedes obtener más información en <a href='https://bitcoin.org/es/elige-tu-monedero' target='_blank'>https://bitcoin.org/es/elige-tu-monedero</a> y <a href='https://academy.bit2me.com/wallet-monederos-criptomonedas/' target='_blank'>https://academy.bit2me.com/wallet-monederos-criptomonedas/</a>. Tip importante: siempre debes tener respaldo de tu wallet.",
          image: assetLogo,
        },
        {
          title: '¿Conoces tu address o dirección BTC?',
          details:
            'Una dirección BTC es un código alfanumérico único que identifica tu cuenta, similar a un CBU. Si aún no has operado con este código deberás prestar mucha atención, ¡un carácter equivocado puede conllevar la pérdida de tus BTC!',
          image: assetLogo,
        },
        {
          title: ' ¿Tienes dudas?',
          details:
            'Si aún no has operado con BTC previamente o tienes dudas sobre cómo verificar tu dirección BTC recomendamos solicitar ayuda. En info@minando.io responderemos tu consulta. Podrás continuar este proceso luego de forma segura.',
          image: assetLogo,
        },
      ],
    }
  }, [])

  const stepsInfoGuideMINEN: InfoGuideStepper = useMemo<InfoGuideStepper>(() => {
    return {
      isAToken: true,
      tokenSymbol: asset,
      symbolStringToReplace: '{symbol}',
      steps: [
        {
          title: `¿Tienes una wallet para {symbol}?`,
          details:
            'Si aún no tienes una wallet para tus {symbol} y tus crypto te recomendamos escoger una wallet segura antes de continuar.',
          image: assetLogo,
        },
        {
          title: 'Metamask',
          details:
            "Te recomendamos usar <a href='https://metamask.io/' target='_blank'>Metamask</a>. Es una de las principales wallets de criptomonedas y se puede utilizar tanto como plugin del navegador web como para dispositivos iOS/Android.",
          image: metamaskLogo,
        },
        {
          title: '¿Conoces tu address o dirección?',
          details:
            "Un address es un código alfanumérico único que identifica tu cuenta, similar a un CBU. <a href='https://metamask.zendesk.com/hc/en-us/articles/360015488791-How-to-view-your-account-details-public-address#:~:text=To%20find%20your%20account's%20address,to%20tap%20a%20few%20times.' target='_blank'>En Metamask lo podes encontrar acá</a>",
          image: metamaskLogo,
        },
        {
          title: 'Administrá tus {symbol}',
          details:
            "Para poder ver tus {symbol} importa el token a tu wallet siguiendo <a href='https://support.ledger.com/hc/es/articles/6375103346077-Agregar-tokens-personalizados-a-MetaMask?docs=true' target='_blank'>esta guía.</a> Para eso necesitaras el address del token {symbol}, el cual es el siguiente:",
          image: metamaskLogo,
          showTokenAddress: true,
        },
      ],
    }
  }, [])

  const isMvp6MINENDistributions = MinandoFeatureFlags.IS_MVP_6_MINEN_DISTRIBUTIONS()

  const getTab = useCallback(
    (tabNumber: number) => {
      switch (tabNumber) {
        case 0:
          return (
            <Grid container>
              <Grid item width="100%">
                <WalletSettingsContainer
                  symbol={Constants.BITCOIN_SYMBOL}
                  stepsInfoGuide={stepsInfoGuideBTC}
                />
              </Grid>
            </Grid>
          )
        case 1:
          return (
            <Grid container>
              <Grid item width="100%">
                <WalletSettingsContainer symbol={asset} stepsInfoGuide={stepsInfoGuideMINEN} />
              </Grid>
            </Grid>
          )
        default:
          return <></>
      }
    },
    [stepsInfoGuideBTC, stepsInfoGuideMINEN]
  )

  return (
    <>
      {isMvp6MINENDistributions ? (
        <Box>
          <Box sx={{ bgcolor: '#fff', mb: 2 }}>
            <CustomTabs value={selectedTab} onChange={handleSelectedTabChange}>
              <CustomTab label={Constants.BITCOIN_SYMBOL} />
              <CustomTab label={asset} />
            </CustomTabs>
          </Box>
          {getTab(selectedTab)}
        </Box>
      ) : (
        <WalletSettingsContainer
          symbol={Constants.BITCOIN_SYMBOL}
          stepsInfoGuide={stepsInfoGuideBTC}
        />
      )}
    </>
  )
}
