import { Grid, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { FormikProps } from 'formik'

interface DatesPickerProps {
  formik: FormikProps<{
    totalAmount: number
    dateFrom: Date | undefined
    dateTo: Date | undefined
  }>
}

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
  succeed: {
    color: 'green',
  },
})

export default function DatesPicker({ formik }: DatesPickerProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid spacing={2} container mt={1}>
      <Grid item xs={12} md={6}>
        <DateTimePicker
          label={t('Fecha Desde')}
          value={formik.values.dateFrom}
          onChange={(value) => formik.setFieldValue('dateFrom', value)}
          renderInput={(params) => (
            <TextField
              {...{
                ...params,
                fullWidth: true,
                error: formik.touched.dateFrom && Boolean(formik.errors.dateFrom),
              }}
            />
          )}
          inputFormat="DD/MM/yyyy HH:mm"
        />
        {formik.touched.dateFrom && formik.errors.dateFrom && (
          <div className={classes.error}>{formik.errors.dateFrom}</div>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimePicker
          label={t('Fecha Hasta')}
          value={formik.values.dateTo}
          onChange={(value) => formik.setFieldValue('dateTo', value)}
          renderInput={(params) => (
            <TextField
              {...{
                ...params,
                fullWidth: true,
                error: formik.touched.dateTo && Boolean(formik.errors.dateTo),
              }}
            />
          )}
          inputFormat="DD/MM/yyyy HH:mm"
        />
        {formik.touched.dateTo && formik.errors.dateTo && (
          <div className={classes.error}>{formik.errors.dateTo}</div>
        )}
      </Grid>
    </Grid>
  )
}
