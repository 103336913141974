import { IconButton, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useMemo, useState } from 'react'
import { t } from 'i18next'
import { debounce } from 'lodash'

interface SearchBarProps {
  filterFunction: (searchString: string) => void
}

const SearchBar = ({ filterFunction }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const debounceSearch = useMemo(() => {
    return debounce((searchString: string) => filterFunction(searchString), 500)
  }, [filterFunction])

  const onChangeSearchstring = (searchString: string) => {
    setSearchValue(searchString)
    debounceSearch(searchString.toLowerCase())
  }

  return (
    <TextField
      fullWidth
      margin="dense"
      label={t('Búsqueda')}
      type={'text'}
      value={searchValue}
      onChange={(e) => onChangeSearchstring(e.target.value)}
      sx={{ marginTop: 1 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchBar
