import { Box, BoxProps, Grid } from '@mui/material'

export default function ShadowBox(props: BoxProps) {
  return (
    <Box sx={{ boxShadow: '0px 0px 18px 0px rgb(62 57 107 / 15%)' }}>
      <Grid container pt={4} px={3} pb={3}>
        <Grid item width="100%">
          {props.children}
        </Grid>
      </Grid>
    </Box>
  )
}
