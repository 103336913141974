import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { User } from '../../types/User'
import { Form, Formik } from 'formik'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import QueryKeys from '../../utils/QueryKeys'
import queryClient from '../../utils/QueryClient'
import ShadowBox from '../ShadowBox/ShadowBox'
import Constants from '../../utils/Constants'

const CustomTextField = (props: TextFieldProps) => {
  return (
    <Grid item md={6} xs={12}>
      <TextField
        fullWidth
        required
        onInvalid={(e) =>
          (e.target as HTMLFormElement).setCustomValidity(t(Constants.REQUIRED_FIELD_TEXT))
        }
        variant="outlined"
        {...props}
      />
    </Grid>
  )
}

interface UserProfileAssetsEditableProps {
  asset: string
  assetAmount: number
  rejectCallback: () => void
  resolveCallback: () => void
  user: User
}

export default function UserProfileAssetsEditable(props: UserProfileAssetsEditableProps) {
  const [amountOfAssetEditable, setAmountOfAssetEditable] = useState<string>(
    props.assetAmount.toString()
  )
  const [openSnackbar] = useSnackbar()

  const handleChangeAmountOfAsset = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (newValue.length === 0) {
      setAmountOfAssetEditable('')
      return
    }
    const isNumber = /^(\d+(?:[.,]\d+)?)$/
    if (isNumber.test(newValue)) {
      setAmountOfAssetEditable(newValue)
      return
    }
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        if (amountOfAssetEditable === props.assetAmount.toString()) {
          props.resolveCallback()
          return
        }
        const newAmountOfAsset = parseFloat(amountOfAssetEditable.replace(',', '.'))
        const tokenAmountAssigned = newAmountOfAsset - props.assetAmount
        const asignSuccess = await AssetsService.assignAssets(
          props.user.id,
          props.asset,
          tokenAmountAssigned
        )
        if (asignSuccess) {
          openSnackbar({
            autoHideDuration: 3000,
            message: t('Los cambios se han guardado con éxito.'),
            severity: 'success',
          })
          queryClient.invalidateQueries(QueryKeys.getFetchUserAssetsKey(props.user.id))
          props.resolveCallback()
        } else {
          openSnackbar({
            autoHideDuration: 3000,
            message: t('Ocurrió un error al guardar los cambios.'),
            severity: 'error',
          })
        }
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ShadowBox>
            <Grid container mb={3}>
              <Grid item>
                <Typography variant="h6">{t('Assets')}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <CustomTextField
                label={t('Cantidad de X', { asset: props.asset })}
                value={amountOfAssetEditable}
                onChange={handleChangeAmountOfAsset}
                type="number"
              />
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
              }}
              spacing={1}
            >
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={props.rejectCallback}
                  disabled={formik.isSubmitting}
                >
                  {t('Cancelar')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={26} color="inherit" />
                  ) : (
                    t('Guardar cambios')
                  )}
                </Button>
              </Grid>
            </Grid>
          </ShadowBox>
        </Form>
      )}
    </Formik>
  )
}
