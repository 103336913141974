import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { TwoSecondFactorActivation } from '../../types/TwoSecondFactorActivation'
import { useNavigate } from 'react-router'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import Constants from '../../utils/Constants'
import { makeStyles } from '@mui/styles'

interface DisableTwoSecondFactorProps {
  onTwoSecondFactorToggleSubmit: (
    TwoSecondFactorActivation: TwoSecondFactorActivation
  ) => Promise<any>
}

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
})

export default function DisableTwoSecondFactor(props: DisableTwoSecondFactorProps) {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Typography variant="h6">{t('Deshabilitar doble factor de autenticación')}</Typography>
      <Formik
        initialValues={{ authCode: '' }}
        validationSchema={yup.object({
          authCode: yup.string().required(t(Constants.REQUIRED_FIELD_TEXT)),
        })}
        onSubmit={async (values, actions) => {
          const activationData: TwoSecondFactorActivation = {
            code: values.authCode,
            enabled: false,
          }
          const { succeed } = await props.onTwoSecondFactorToggleSubmit(activationData)
          if (succeed) {
            actions.resetForm()
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} style={{ width: '80%' }}>
            <TextField
              sx={{ marginTop: 4 }}
              id="authCode"
              name="authCode"
              label={t('Código de Google Authenticator')}
              variant="outlined"
              fullWidth
              value={formik.values.authCode}
              onChange={formik.handleChange}
              error={formik.touched.authCode && Boolean(formik.errors.authCode)}
              margin="dense"
            />
            {formik.touched.authCode && formik.errors.authCode && (
              <div className={classes.error}>{formik.errors.authCode}</div>
            )}
            <Grid container marginTop={2} flexDirection="row" justifyContent="flex-end">
              <Grid item>
                <>
                  <Button
                    variant="outlined"
                    disabled={formik.isSubmitting}
                    sx={{ mr: 1 }}
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    {t('Volver')}
                  </Button>
                  <Button variant="contained" disabled={formik.isSubmitting} type="submit">
                    {formik.isSubmitting ? (
                      <CircularProgress size={26} color="inherit" />
                    ) : (
                      t('Deshabilitar')
                    )}
                  </Button>
                </>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
