import { Dialog, DialogProps } from '@mui/material'
import { createContext, PropsWithChildren, useContext, useState, useRef } from 'react'

type ProviderContext = readonly [(option: DialogOption) => void, () => void]

const DialogContext = createContext<ProviderContext>(null as unknown as ProviderContext)

type DialogOption = Omit<DialogProps, 'open'>

export function DialogProvider({ children }: PropsWithChildren<{}>) {
  const [dialogs, setDialogs] = useState<DialogProps[]>([])
  const createDialog = (option: DialogOption) => {
    const dialog = { ...option, open: true }
    setDialogs((dialogs) => [...dialogs, dialog])
  }
  const closeDialog = () => {
    setDialogs((dialogs) => {
      dialogs.pop()
      return [...dialogs]
    })
  }

  const contextValue = useRef([createDialog, closeDialog] as const)
  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        const { children: dialogChildren, ...dialogParams } = dialog
        return (
          <Dialog key={i} onClose={closeDialog} {...dialogParams}>
            {dialogChildren}
          </Dialog>
        )
      })}
    </DialogContext.Provider>
  )
}

export function useDialog(): ProviderContext {
  const result = useContext(DialogContext)
  if (!result) {
    throw new Error('Dialog context is only available inside its provider')
  }
  return result
}
