import bitcoinLogo from '../../assets/images/logo-bitcoin.svg'
import assetLogo from '../../assets/images/logo-white.svg'
import Constants from '../Constants'

const assetLogos = new Map([
  [Constants.BITCOIN_SYMBOL, bitcoinLogo],
  [window.env.asset_symbol, assetLogo],
])

const getAssetLogoSrc = (asset: string) => {
  return assetLogos.get(asset.toUpperCase()) || ''
}

export default getAssetLogoSrc
