import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material'
import { t } from 'i18next'

interface ConfirmationDialogProps extends Omit<DialogProps, 'open'> {
  onCancel?: () => void
  onConfirm?: () => void
  message: string
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { onCancel, onConfirm, message, ...rest } = props
  return (
    <Dialog {...rest} open>
      <DialogTitle>{t('Confirmar operación')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{props.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<CancelIcon />}
          variant="contained"
          color="error"
          onClick={props.onCancel}
        >
          {t('No')}
        </Button>
        <Button startIcon={<CheckCircleIcon />} variant="contained" onClick={props.onConfirm}>
          {t('Si')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
