import { Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'
import { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import AuthService from '../../api/services/AuthService/AuthService'
import { useNavigate } from 'react-router-dom'

interface LoginProps {
  onSuccess: Function
  email?: string
}

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
  fullWidth: {
    width: '100%',
  },
})

export default function LoginForm(props: LoginProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Grid className={classes.fullWidth}>
      <Formik
        initialValues={{ email: props.email || '', password: '' }}
        validationSchema={yup.object({
          email: yup
            .string()
            .email(t('Ingrese un email válido'))
            .required(t('Este campo es requerido')),
          password: yup.string().required(t('Este campo es requerido')),
        })}
        onSubmit={async (values, actions) => {
          actions.setStatus()
          const loginData = await AuthService.login(values)
          if (loginData.statusCode !== HTTPStatusCodes.OK) {
            actions.setStatus(loginData.msg)
          } else {
            props.onSuccess(loginData.data, values.password)
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="dense"
              id="email"
              name="email"
              label={t('Email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            {formik.touched.email && formik.errors.email && (
              <div className={classes.error}>{formik.errors.email}</div>
            )}

            <TextField
              fullWidth
              margin="dense"
              id="password"
              name="password"
              label={t('Contraseña')}
              type={!showPassword ? 'password' : 'text'}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              autoComplete="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.password && formik.errors.password && (
              <div className={classes.error}>{formik.errors.password}</div>
            )}

            <Grid className={classes.error}>{formik.status}</Grid>
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              sx={{ mt: 1 }}
              color="primary"
              variant="contained"
              type="submit"
            >
              {formik.isSubmitting ? (
                <CircularProgress size={26} color="inherit" />
              ) : (
                t('Iniciar Sesión')
              )}
            </Button>
            <Button
              fullWidth
              size="small"
              sx={{ mt: 1 }}
              onClick={() => navigate('/reset-password')}
            >
              {t('¿Olvidaste tu contraseña?')}
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
