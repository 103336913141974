import { Grid, ListItem, Typography, ListItemButton, ListItemIcon } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

interface AdminUserListItemProps {
  userId: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  onClickUser: (userId: string) => void
}

export default function AdminUserListItem(props: AdminUserListItemProps) {
  return (
    <ListItem disablePadding onClick={() => props.onClickUser(props.userId)}>
      <ListItemButton>
        <Grid container>
          <Grid display="flex" item xs={12} sm={3} md={4}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">
              {props.firstName} {props.lastName}
            </Typography>
          </Grid>
          <Grid display="flex" item xs={12} sm={6} md={4}>
            <ListItemIcon>
              <MailIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">{props.email}</Typography>
          </Grid>
          <Grid display="flex" item xs={12} sm={3} md={4}>
            <ListItemIcon>
              <LocalPhoneIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">{props.phoneNumber}</Typography>
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  )
}
