import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import queryClient from './utils/QueryClient'
import { DialogProvider } from './utils/hooks/UseDialog'
import SnackbarProvider from './utils/hooks/UseSnackbar'

declare global {
  interface Window {
    MyNamespace: any
    env: any
  }
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <DialogProvider>
            <App />
          </DialogProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
