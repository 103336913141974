import { User } from '../../types/User'
import UserProfileAssetsEditable from './UserProfileAssetsEditable'
import UserProfileAssetsViewable from './UserProfileAssetsViewable'
import { useState } from 'react'
import { AssetDistribution } from '../../types/AssetDistribution'

export interface UserProfileAssetsProps {
  asset: string
  assetAmount: number
  user: User
  assetRewarded: string
  rewardAmount: number
  distributions: AssetDistribution[]
  rewardAmountPendingToSend: number
  onRewardSent: () => void
  onAssetSent: () => void
  assetAmountPendingToSend: number
}

export default function UserProfileAssets(props: UserProfileAssetsProps) {
  const [editMode, setEditMode] = useState(false)

  const switchEditMode = () => {
    setEditMode(!editMode)
  }

  return (
    <>
      {editMode ? (
        <UserProfileAssetsEditable
          asset={props.asset}
          assetAmount={props.assetAmount}
          rejectCallback={switchEditMode}
          resolveCallback={switchEditMode}
          user={props.user}
        />
      ) : (
        <UserProfileAssetsViewable {...props} editCallback={switchEditMode} />
      )}
    </>
  )
}
