import styles from './PriceInformation.module.css'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

interface PriceInformationProps {
  currentPrice: number
  variation: number | null
}

const getVariationStyle = (variation: number | null) => {
  return variation ? (variation > 0 ? 'Positive-Variation' : 'Negative-Variation') : ''
}

export default function PriceInformation(props: PriceInformationProps) {
  const { t } = useTranslation()
  const differenceFormattedNumber = props.variation
    ? `${t('formatNumber', { val: props.variation })}%`
    : '-'
  const currentPrice = t('formatCurrency', { val: props.currentPrice })

  return (
    <div className={styles.Container}>
      <Typography align="right" className={styles.Title}>
        {currentPrice}
      </Typography>
      <Typography align="right" className={styles[getVariationStyle(props.variation)]}>
        {differenceFormattedNumber}
      </Typography>
    </div>
  )
}
