import AdminDistributeDetailsContainer from '../../components/AdminDistributeDetailsContainer/AdminDistributeDetailsContainer'
import { useParams } from 'react-router-dom'

export default function AdminDistributeDetailsPage() {
  const { assetId, distributionId } = useParams()
  const assetDistributionId = distributionId || ''
  const asset = assetId || ''

  return (
    <AdminDistributeDetailsContainer assetId={asset} assetDistributionId={assetDistributionId} />
  )
}
