import { CardContent, Card, Grid, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Constants from '../../utils/Constants'
import { Asset } from '../../types/Asset'
import UserAssetAmountDetailItem from './UserAssetAmountDetailItem'

interface UserAssetAmountDetailsProps {
  asset: Asset
  onClickAskForAvailable: () => void
}

export default function UserAssetAmountDetails(props: UserAssetAmountDetailsProps) {
  const { t } = useTranslation()

  return (
    <Card sx={{ minWidth: 275, backgroundColor: Constants.GRAY_RGB }}>
      <CardContent sx={{ width: '100%' }}>
        <Grid container mb={1}>
          <UserAssetAmountDetailItem
            assetId={props.asset.tokenId}
            label={t('Acumulado')}
            description={t('Monto total asignado.')}
            amount={props.asset.tokenAmountAssigned}
            showButton={false}
            isPrimary={true}
          />
          <UserAssetAmountDetailItem
            assetId={props.asset.tokenId}
            label={t('Recibido')}
            description={t('Monto enviado a su wallet.')}
            amount={props.asset.tokenAmountDelivered}
            showButton={false}
            isPrimary={true}
          />
          <UserAssetAmountDetailItem
            assetId={props.asset.tokenId}
            label={t('Disponible')}
            description={t('Monto que puede solicitar enviar a su wallet.')}
            amount={props.asset.tokenAmountToDistribute}
            showButton={props.asset.isTransferable && props.asset.tokenAmountToDistribute > 0}
            buttonLabel={t('Solicitar')}
            onClickButton={props.onClickAskForAvailable}
            isPrimary={true}
          />
        </Grid>
        <Divider />
        <Grid container mt={1}>
          <UserAssetAmountDetailItem
            assetId={props.asset.tokenId}
            label={t('En camino')}
            description={t('Monto en proceso de envío por la red de nodos.')}
            amount={props.asset.tokenAmountInTransit}
            showButton={false}
            isPrimary={false}
          />
          <UserAssetAmountDetailItem
            assetId={props.asset.tokenId}
            label={t('Solicitados')}
            description={t('Monto solicitado pendiente de envío a su wallet.')}
            amount={props.asset.tokenAmountRequested}
            showButton={false}
            isPrimary={false}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}
