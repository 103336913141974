import { Box, Button, Grid, Typography, Skeleton } from '@mui/material'
import { t } from 'i18next'
import { Navigate, useNavigate } from 'react-router'
import MinandoFeatureFlags from '../../utils/MinandoFeatureFlags'
import { User } from '../../types/User'
import QueryKeys from '../../utils/QueryKeys'
import { useQuery } from 'react-query'
import UserService from '../../api/services/UserService/UserService'
import AdminUserList from '../../components/AdminUserList/AdminUserList'
import SearchBar from '../../components/SearchBar/SearchBar'
import { useState } from 'react'
import { Add } from '@mui/icons-material'

export default function AdminUserListManagementPage() {
  const [users, setUsers] = useState<User[]>([])
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users)
  const navigate = useNavigate()
  const usersResponse = useQuery<User[], Error>(
    QueryKeys.getFetchUsersKey(),
    async () => {
      return await UserService.getUsers()
    },
    {
      onSuccess: (data) => {
        if (users === filteredUsers) {
          setFilteredUsers(data)
        }
        setUsers(data)
      },
    }
  )

  if (!MinandoFeatureFlags.IS_MVP_4_ADMIN()) {
    return <Navigate to="/user-wallet" replace />
  }

  const onClickUser = (userId: string) => {
    navigate(`/admin/users/${userId}`)
  }

  const onClickAdd = () => {
    navigate(`/admin/users/add`)
  }

  const filterUsersByUsername = (searchString: string) => {
    const filteredList = users.filter(
      (user) =>
        (user.userName.name.toLowerCase() + ' ' + user.userName.lastName.toLowerCase()).includes(
          searchString
        ) || user.email?.includes(searchString)
    )
    setFilteredUsers(filteredList)
  }

  return (
    <Grid container>
      <Grid item width="100%">
        <Grid container direction="row" justifyContent="space-between" mb={2}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Grid item>
              <Typography variant="h5">{t('Usuarios')}</Typography>
            </Grid>
          </Box>
          <Grid item>
            <Button variant="contained" startIcon={<Add />} onClick={onClickAdd}>
              {t('Nuevo')}
            </Button>
          </Grid>
        </Grid>
        <SearchBar filterFunction={filterUsersByUsername} />
        {usersResponse.isLoading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
        ) : (
          <AdminUserList users={filteredUsers} onClickUser={onClickUser} />
        )}
      </Grid>
    </Grid>
  )
}
