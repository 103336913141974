import { User } from '../../types/User'
import UserProfileDetailsEditable from './UserProfileDetailsEditable'
import UserProfileDetailsViewable from './UserProfileDetailsViewable'
import { useCallback, useState } from 'react'

interface UserProfileDetailsProps {
  isAdmin: boolean
  user: User
}

export default function UserProfileDetails(props: UserProfileDetailsProps) {
  const [editMode, setEditMode] = useState(false)

  const switchEditMode = useCallback(() => {
    setEditMode(!editMode)
  }, [editMode])

  return (
    <>
      {editMode ? (
        <UserProfileDetailsEditable
          rejectCallback={switchEditMode}
          resolveCallback={switchEditMode}
          isAdmin={props.isAdmin}
          user={props.user}
        />
      ) : (
        <UserProfileDetailsViewable editCallback={switchEditMode} user={props.user} />
      )}
    </>
  )
}
