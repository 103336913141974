import {
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton,
  Box,
  Divider,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { useState, useContext } from 'react'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'
import { DistributionDetail } from '../../types/DistributionDetail'
import { getDistributionStatusIcon } from '../../utils/functions/getDistributionStatusIcon'
import AuthService from '../../api/services/AuthService/AuthService'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import { AppContext } from '../../contexts/AppContext'
import { PaymentWalletStatus } from '../../types/PaymentWalletStatus'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'
import queryClient from '../../utils/QueryClient'
import QueryKeys from '../../utils/QueryKeys'

interface SendAssetToUserWithActiveWalletProps {
  distributionDetail: DistributionDetail
  goBack: () => void
  shouldSendToAll: boolean
  readonly?: boolean
  onSuccess: () => void
  shouldSendAllPendingAssets?: boolean
  paymentWalletBalance: string
  isInvestmentReturn: boolean
}

const useStyles = makeStyles({
  principalCard: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
  error: {
    color: 'red',
  },
  succeed: {
    color: 'green',
  },
})

const inputStyle = {
  style: {
    backgroundColor: 'white',
    WebkitTextFillColor: 'black',
  },
}

const inputDisabledStyle = {
  style: {
    backgroundColor: 'lightgray',
    WebkitTextFillColor: 'black',
  },
}

export default function SendAssetToUserWithActiveWallet(
  props: SendAssetToUserWithActiveWalletProps
) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showAdminPassword, setShowAdminPassword] = useState(false)
  const { paymentWalletStatus } = useContext(AppContext)
  const isPaymentWalletPresent =
    paymentWalletStatus === PaymentWalletStatus.LOCKED ||
    paymentWalletStatus === PaymentWalletStatus.UNLOCKED
  const [openSnackbar] = useSnackbar()

  const onClickCancel = (event: any) => {
    event.preventDefault()
    props.goBack()
  }

  const handleSendAsset = async (actions: FormikHelpers<{ adminPassword: string }>) => {
    const didSucceed = props.shouldSendToAll
      ? await AssetsService.sendAssetToAllUsers(props.distributionDetail.assetDistributionId)
      : props.shouldSendAllPendingAssets
      ? await AssetsService.sendPendingAssetToUser(
          props.distributionDetail.userId,
          props.distributionDetail.tokenId
        )
      : await AssetsService.sendAssetToUser(
          props.distributionDetail.userId,
          props.distributionDetail.assetDistributionId
        )
    if (didSucceed) {
      openSnackbar({
        autoHideDuration: 3000,
        message: t('Acción ejecutada con éxito'),
        severity: 'success',
      })
      props.onSuccess()
      props.goBack()
      if (props.isInvestmentReturn) {
        queryClient.refetchQueries([QueryKeys.getPaymentWalletBalanceKey()])
      }
    } else {
      actions.setStatus(t('Error'))
    }
  }

  return (
    <Box>
      <Typography variant="h6">{props.distributionDetail.username}</Typography>
      {props.distributionDetail.userEmail && (
        <Typography variant="subtitle2">{props.distributionDetail.userEmail}</Typography>
      )}
      {props.shouldSendToAll && (
        <Typography variant="caption" mb={2}>
          {t('Solo se enviará a los usuarios pendientes y que dispongan de una wallet asociada.')}
        </Typography>
      )}
      {!props.shouldSendToAll && (
        <>
          <Grid container direction="row" alignItems="center">
            <Grid item mr={1}>
              <Typography variant="body1">{`${t('Asignado')}:`}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {`${props.distributionDetail.amount} ${props.distributionDetail.tokenId}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" mb={1}>
            <Grid item mr={1}>
              <Typography variant="body1">{`${t('Estado')}:`}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{t(props.distributionDetail.status)}</Typography>
            </Grid>
            <Grid item>{getDistributionStatusIcon(props.distributionDetail.status, false)}</Grid>
          </Grid>
          <Divider />
          {props.isInvestmentReturn && (
            <TextField
              label={t('Balance de la wallet de pagos')}
              id="amountToSend"
              disabled
              fullWidth
              value={
                props.paymentWalletBalance
                  ? `${props.paymentWalletBalance} ${props.distributionDetail.tokenId}`
                  : t('Obteniendo balance...')
              }
              variant="filled"
              inputProps={inputDisabledStyle}
              sx={{ mt: 2, mb: 1, WebkitTextFillColor: 'black' }}
            />
          )}
        </>
      )}
      {!props.readonly ? (
        <Formik
          initialValues={{ adminPassword: '' }}
          validationSchema={yup.object({
            adminPassword: yup.string().required(t('Este campo es requerido')),
          })}
          onSubmit={async (values, actions) => {
            actions.setStatus()
            if (props.isInvestmentReturn && paymentWalletStatus !== PaymentWalletStatus.UNLOCKED) {
              actions.setStatus(t('La wallet de pagos debe estar desbloqueada para poder enviar.'))
              return
            }
            const validatePasswordResponse = await AuthService.validatePassword(
              values.adminPassword
            )
            if (
              validatePasswordResponse.statusCode === HTTPStatusCodes.OK &&
              Boolean(validatePasswordResponse.data)
            ) {
              await handleSendAsset(actions)
            } else {
              validatePasswordResponse.statusCode !== HTTPStatusCodes.OK
                ? actions.setStatus(t('Error'))
                : actions.setStatus(t('Contraseña incorrecta'))
            }
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                margin="dense"
                id="adminPassword"
                name="adminPassword"
                label={t('Contraseña del admin')}
                type={!showAdminPassword ? 'password' : 'text'}
                value={formik.values.adminPassword}
                onChange={formik.handleChange}
                error={formik.touched.adminPassword && Boolean(formik.errors.adminPassword)}
                sx={{ marginTop: 1 }}
                inputProps={inputStyle}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowAdminPassword(!showAdminPassword)}>
                        {showAdminPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formik.touched.adminPassword && formik.errors.adminPassword && (
                <div className={classes.error}>{formik.errors.adminPassword}</div>
              )}
              <Grid sx={{ mb: 1 }} className={classes.error}>
                {formik.status}
              </Grid>
              <Grid container marginTop={2} flexDirection="row" justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ mr: 1 }}
                    type="button"
                    disabled={formik.isSubmitting}
                    onClick={onClickCancel}
                  >
                    {t('Cancelar')}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      (props.isInvestmentReturn && !isPaymentWalletPresent) ||
                      props.distributionDetail.amount < 0 ||
                      formik.isSubmitting
                    }
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={26} color="inherit" />
                    ) : (
                      t('Aceptar')
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Grid container marginTop={2} flexDirection="row" justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" sx={{ mr: 1 }} type="button" onClick={onClickCancel}>
              {t('Volver')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
