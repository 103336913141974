import { Button, Grid } from '@mui/material'
import { useState } from 'react'
import AdminSendAssets from '../AdminSendAssets/AdminSendAssets'
import { DistributionDetail } from '../../types/DistributionDetail'
import Field from '../utils/Field'
import { t } from 'i18next'

interface UserProfileAssetsSendAssetProps {
  distributionDetail: DistributionDetail
  onAssetSent: () => void
  assetAmount: number
  assetAmountPendingToSend: number
  isInvestmentReturnDistribution: boolean
}

export default function UserProfileAssetsSendAsset(props: UserProfileAssetsSendAssetProps) {
  const [showSendAsset, setShowSendAsset] = useState<boolean>(false)

  return (
    <>
      <Grid container spacing={1}>
        <Field
          title={t('Cantidad de X', { asset: props.distributionDetail.tokenId })}
          subtitle={props.assetAmount.toString()}
        />
        {showSendAsset && (
          <Grid item xs={12}>
            <AdminSendAssets
              goBack={() => setShowSendAsset(false)}
              shouldSendToAll={false}
              distributionDetail={props.distributionDetail}
              shouldSendAllPendingAssets={true}
              onSuccess={props.onAssetSent}
              isInvestmentReturnDistribution={props.isInvestmentReturnDistribution}
            />
          </Grid>
        )}
      </Grid>
      {!showSendAsset && props.assetAmountPendingToSend > 0 && (
        <Button
          variant="contained"
          sx={{ margin: 1, display: 'flex', marginLeft: 'auto' }}
          onClick={() => setShowSendAsset(true)}
        >
          {t('Transferir X Y pendientes', {
            amount: props.assetAmountPendingToSend,
            asset: props.distributionDetail.tokenId,
          })}
        </Button>
      )}
    </>
  )
}
