import { Alert, Box, Skeleton } from '@mui/material'
import { useMemo, useCallback } from 'react'
import AdminDistributeHeader from '../AdminDistributeHeader/AdminDistributeHeader'
import { DistributionDetail } from '../../types/DistributionDetail'
import { useQueries, useQuery } from 'react-query'
import QueryKeys from '../../utils/QueryKeys'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import UserService from '../../api/services/UserService/UserService'
import { t } from 'i18next'
import AdminDistributeUsersPanel from '../AdminDistributeUsersPanel/AdminDistributeUsersPanel'
import { AssetDistributedStatus } from '../../types/AssetDistributedStatus'
import { User } from '../../types/User'

const getPaidUsers = (distributions: DistributionDetail[]) => {
  const paidUsersQuantity: number = distributions.filter(
    (distribution) => distribution.status === AssetDistributedStatus.Delivered
  ).length
  const paidUsers: number = distributions.length
  return `${paidUsersQuantity}/${paidUsers}`
}

interface AdminDistributeDetailsContainerProps {
  assetId: string
  assetDistributionId: string
}

export default function AdminDistributeDetailsContainer(
  props: AdminDistributeDetailsContainerProps
) {
  const refetchInterval = parseInt(window.env.refetch_distributed_in_minutes || '5') * 60000
  const assetDistributionResponse = useQuery(
    QueryKeys.getFetchAssetDistributionKey(props.assetDistributionId),
    async () => AssetsService.getDistribution(props.assetId, props.assetDistributionId),
    { refetchInterval }
  )
  const assetDistribution = assetDistributionResponse?.data
  const { adminId, tokenId, assetsDistributed, dateFrom, dateTo, quantity } =
    assetDistribution || {}
  const assetsDistributedLists = assetsDistributed || []
  const usersFromDistributionResponses = useQueries(
    assetsDistributedLists.map((assetDistributed) => {
      return {
        queryKey: ['assetsDistributed.userId', QueryKeys.getFetchUserKey(assetDistributed.userId)],
        queryFn: () => UserService.get(assetDistributed.userId),
      }
    })
  )

  const users = useMemo<(User | undefined)[]>(() => {
    return usersFromDistributionResponses.map((userResponse) => userResponse.data)
  }, [usersFromDistributionResponses])

  useMemo(() => {
    assetsDistributed?.forEach((distributed: DistributionDetail) => {
      const userFromDistributed = users.find((user) => user?.id === distributed.userId)
      distributed.username = userFromDistributed
        ? `${userFromDistributed.userName.name} ${userFromDistributed.userName.lastName}`
        : ''
      distributed.userEmail = userFromDistributed ? userFromDistributed.email : ''
    })
  }, [assetsDistributed, users])

  const adminIdNotUndefined = adminId || 'N/A'
  const responsableUserResponse = useQuery(
    QueryKeys.getFetchUserKey(adminIdNotUndefined),
    async () => await UserService.get(adminIdNotUndefined),
    { enabled: assetDistribution !== undefined && adminIdNotUndefined !== 'N/A', retry: false }
  )
  const responsableUsername = responsableUserResponse?.data
    ? `${responsableUserResponse?.data.userName.name} ${responsableUserResponse?.data.userName.lastName}`
    : t('Default admin user')

  const paidUsers = useMemo(
    () => getPaidUsers(assetDistribution?.assetsDistributed || []),
    [assetDistribution]
  )

  const refetchDistributions = useCallback(
    () => assetDistributionResponse.refetch(),
    [assetDistributionResponse]
  )

  return (
    <Box>
      {assetDistributionResponse.isLoading ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
      ) : tokenId && assetDistribution && assetsDistributed && dateFrom && dateTo && quantity ? (
        <>
          <AdminDistributeHeader
            dateFrom={dateFrom}
            dateTo={dateTo}
            paidUsers={paidUsers}
            responsable={responsableUsername}
            amountPaid={assetDistribution.amountDelivered}
            amountToPay={assetDistribution.amountPendingToSend}
            amountRequested={assetDistribution.amountRequested}
            tokenId={tokenId}
            total={quantity}
            style={{ marginBottom: 30 }}
          />
          <AdminDistributeUsersPanel
            assetsDistributed={assetsDistributed}
            assetDistribution={assetDistribution}
            total={quantity}
            refetchAssetDistribution={refetchDistributions}
          />
        </>
      ) : (
        <Alert variant="filled" severity="error">
          {t('No se encontró la distribución solicitada. Por favor, intente nuevamente.')}
        </Alert>
      )}
    </Box>
  )
}
