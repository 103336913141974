import { List, Divider, Typography, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import { User } from '../../types/User'
import AdminUserListItem from './AdminUserListItem'

interface AdminUserListProps {
  users: User[]
  onClickUser: (userId: string) => void
}

export default function AdminUserList(props: AdminUserListProps) {
  return (
    <Box>
      {props.users.length > 0 ? (
        <Grid>
          <List>
            {props.users.map((user, i) => (
              <Box key={i}>
                <AdminUserListItem
                  userId={user.id}
                  firstName={user.userName.name}
                  lastName={user.userName.lastName}
                  email={user.email}
                  phoneNumber={user.phoneNumber}
                  onClickUser={props.onClickUser}
                />
                <Divider />
              </Box>
            ))}
          </List>
        </Grid>
      ) : (
        <Typography variant="subtitle1" mt={3} mb={2}>
          {t('No se encontraron registros')}
        </Typography>
      )}
    </Box>
  )
}
