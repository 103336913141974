import { apiClient } from '../../axios'
import { t } from 'i18next'
import { HTTPStatusCodes } from '../../../types/HTTPStatusCodes'
import UserService from '../UserService/UserService'
import { User } from '../../../types/User'
import { getCompleteAndSendEmailsUrl, getSendSingleEmailUrl } from './EmailSenderServiceUrls'

const sendEmails = async (
  to: string[],
  subject: string,
  template: string,
  shouldSendToAll: boolean
) => {
  let res
  let recipients: string[]
  try {
    if (shouldSendToAll) {
      const allUsers: User[] = await UserService.getUsers()
      recipients = allUsers.map((user) => user.email)
    } else {
      recipients = to
    }
    res = await apiClient.post(getCompleteAndSendEmailsUrl(subject, template), recipients)
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      msg: e.response.status !== HTTPStatusCodes.ERROR ? e.response.data : t('Error'),
    }
  }
}

const sendRequestAssociateWalletEmail = async (
  to: string,
  subject: string,
  template: string,
  username: string,
  tokenId: string
) => {
  let res
  try {
    const requestBody = {
      Name: username,
      TokenId: tokenId?.toUpperCase(),
    }
    res = await apiClient.post(getSendSingleEmailUrl(to, subject, template), requestBody)
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      msg: e.response.status !== HTTPStatusCodes.ERROR ? e.response.data : t('Error'),
    }
  }
}

const isAValidEmail = (email: string) => {
  const res =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return res.test(String(email).toLowerCase())
}

const EmailSenderService = {
  sendEmails,
  sendRequestAssociateWalletEmail,
  isAValidEmail,
}

export default EmailSenderService
