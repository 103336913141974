import { Typography, Grid, Button, CircularProgress, Popover } from '@mui/material'
import { useState, useCallback } from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  smallFont: {
    fontSize: 'small !important',
  },
})

const labelTextColor = '#7d7878'

interface UserAssetAmountDetailItemProps {
  assetId: string
  amount: number
  label: string
  description: string
  showButton: boolean
  buttonLabel?: string
  onClickButton?: () => void
  isPrimary: boolean
}

export default function UserAssetAmountDetailItem(props: UserAssetAmountDetailItemProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const className = props.isPrimary ? '' : classes.smallFont

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)
  const popoverId = openPopover ? 'simple-popover' : undefined

  const onClickButton = useCallback(async () => {
    setIsLoading(true)
    if (props.onClickButton) {
      await props.onClickButton()
    }
    setIsLoading(false)
  }, [props])

  return (
    <Grid item xs={6} md={6} alignItems="right" mt={0.5}>
      <Grid container>
        <Grid item>
          <Typography className={className} variant="subtitle1" sx={{ color: labelTextColor }}>
            {props.label}
          </Typography>
        </Grid>
        <Grid item ml={0.2} pt={props.isPrimary ? 0.7 : 0.4}>
          <HelpOutlineOutlinedIcon
            sx={{ color: labelTextColor, fontSize: 'medium' }}
            onClick={handlePopoverOpen}
          />
          <Popover
            id={popoverId}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            sx={{ width: '90%' }}
            onClose={handlePopoverClose}
          >
            <Typography sx={{ p: 1 }}>{props.description}</Typography>
          </Popover>
        </Grid>
      </Grid>
      {!props.showButton ? (
        <Typography variant="subtitle2" className={className}>
          {`${props.amount} ${props.assetId}`}
        </Typography>
      ) : (
        <Grid container>
          <Grid item pt={0.5}>
            <Typography variant="subtitle2" className={className}>
              {`${props.amount} ${props.assetId}`}
            </Typography>
          </Grid>
          <Grid item ml={1}>
            <Button
              variant="contained"
              onClick={onClickButton}
              disabled={isLoading}
              size="small"
              sx={{ p: 0.5, fontSize: 11 }}
            >
              {isLoading ? <CircularProgress size={26} color="inherit" /> : props.buttonLabel}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
