import { Box, Grid, Typography } from '@mui/material'
import { t } from 'i18next'
import { useNavigate } from 'react-router'
import AdminDistributeCheckpointList from '../../components/AdminDistributeCheckpointList/AdminDistributeCheckpointList'
import { AssetDistribution } from '../../types/AssetDistribution'
import QueryKeys from '../../utils/QueryKeys'
import { useQuery } from 'react-query'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import Constants from '../../utils/Constants'
import { useCallback, useState } from 'react'
import CustomTabs from '../../components/CustomTabs/CustomTabs'
import CustomTab from '../../components/CustomTabs/CustomTab'
import MinandoFeatureFlags from '../../utils/MinandoFeatureFlags'
import useSortList from '../../utils/functions/useSortList'

export default function AdminDistributeCheckpointPage() {
  const [selectedTab, setSelectedTab] = useState(0)
  const handleSelectedTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }, [])
  const asset = window.env.asset_symbol

  const isMvp6MINENDistributions = MinandoFeatureFlags.IS_MVP_6_MINEN_DISTRIBUTIONS()

  const BTCdistributionsResponse = useQuery<AssetDistribution[], Error>(
    QueryKeys.getFetchAssetsDistributionsKey(Constants.BITCOIN_SYMBOL),
    async () => {
      return await AssetsService.getDistributions(Constants.BITCOIN_SYMBOL)
    }
  )

  const MINENdistributionsResponse = useQuery<AssetDistribution[], Error>(
    QueryKeys.getFetchAssetsDistributionsKey(asset),
    async () => await AssetsService.getDistributions(asset),
    { enabled: isMvp6MINENDistributions }
  )

  const BTCcheckpoints = useSortList(BTCdistributionsResponse?.data || [], 'dateFrom').reverse()
  const MINENcheckpoints = useSortList(MINENdistributionsResponse?.data || [], 'dateFrom').reverse()

  const navigate = useNavigate()

  const onAddBTCCheckpoint = useCallback(() => {
    let dateFrom = new Date().toISOString()
    const dateTo = new Date().toISOString()
    if (BTCcheckpoints.length > 0) {
      dateFrom = new Date(BTCcheckpoints[0].dateTo).toISOString()
    }
    navigate(`/admin/distribute/${dateFrom}/${dateTo}`)
  }, [BTCcheckpoints, navigate])

  const onClickCheckpoint = useCallback(
    (asset: string, distributionId: string) => {
      navigate(`/admin/${asset}/distribute/${distributionId}`)
    },
    [navigate]
  )

  const getTab = useCallback(
    (tabNumber: number) => {
      switch (tabNumber) {
        case 0:
          return (
            <Grid container>
              <Grid item width="100%">
                <Typography variant="h6" mb={2}>
                  {t(`Historial de distribuciones ${Constants.BITCOIN_SYMBOL}`)}
                </Typography>
                <AdminDistributeCheckpointList
                  checkpoints={BTCcheckpoints}
                  onAddCheckpoint={() => onAddBTCCheckpoint()}
                  onClickCheckpoint={(tokenId: string, distributionId: string) =>
                    onClickCheckpoint(tokenId, distributionId)
                  }
                />
              </Grid>
            </Grid>
          )
        case 1:
          return (
            <Grid container>
              <Grid item width="100%">
                <Typography variant="h6" mb={2}>
                  {t(`Historial de tenencias ${asset}`)}
                </Typography>
                <AdminDistributeCheckpointList
                  checkpoints={MINENcheckpoints}
                  onClickCheckpoint={onClickCheckpoint}
                />
              </Grid>
            </Grid>
          )
        default:
          return <></>
      }
    },
    [BTCcheckpoints, MINENcheckpoints, onAddBTCCheckpoint, onClickCheckpoint, asset]
  )

  return (
    <>
      {isMvp6MINENDistributions ? (
        <Box>
          <Box sx={{ bgcolor: '#fff', mb: 2 }}>
            <CustomTabs value={selectedTab} onChange={handleSelectedTabChange}>
              <CustomTab label={Constants.BITCOIN_SYMBOL} />
              <CustomTab label={asset} />
            </CustomTabs>
          </Box>
          {getTab(selectedTab)}
        </Box>
      ) : (
        <Grid container>
          <Grid item width="100%">
            <Typography variant="h5" mb={2}>
              {t(`Historial de distribuciones ${Constants.BITCOIN_SYMBOL}`)}
            </Typography>
            <AdminDistributeCheckpointList
              checkpoints={BTCcheckpoints}
              onAddCheckpoint={() => onAddBTCCheckpoint()}
              onClickCheckpoint={onClickCheckpoint}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}
