import { apiClient } from '../../axios'
import { TokenPrice } from '../../../types/TokenPrice'
import { PriceVariation } from '../../../types/PriceVariation'
import { getPriceUrl, getVariation } from './PriceServiceUrls'

const getAssetPrice = async (tokenId: string): Promise<TokenPrice[]> => {
  const { data } = await apiClient.get<TokenPrice[]>(getPriceUrl(tokenId))
  return data
}

// timeframe = day/week/month
const getPriceHistory = async (
  tokenId: string,
  timeframe: string,
  at?: Date
): Promise<TokenPrice[]> => {
  const { data } = await apiClient.get<TokenPrice[]>(getPriceUrl(tokenId, timeframe, at))
  return data
}

const getAssetVariation = async (tokenId: string, at?: string): Promise<PriceVariation> => {
  const response = await apiClient.get<PriceVariation>(getVariation(tokenId, at))
  return response.data
}

const PriceService = {
  getAssetPrice,
  getPriceHistory,
  getAssetVariation,
}

export default PriceService
