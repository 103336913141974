import { PaymentWalletStatus } from '../../types/PaymentWalletStatus'
import AdminImportPrivateKeyForm from '../../components/AdminImportPrivateKeyForm/AdminImportPrivateKeyForm'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import queryClient from '../../utils/QueryClient'
import QueryKeys from '../../utils/QueryKeys'

export default function AdminImportPrivateKeyPage() {
  const { paymentWalletStatus, setPaymentWalletStatus } = useContext(AppContext)
  const paymentWalletStatusProp = paymentWalletStatus || PaymentWalletStatus.NOT_IMPORTED
  const onImportPrivateKey = () => {
    queryClient.invalidateQueries(QueryKeys.getFetchPaymentWalletStatusKey())
    setPaymentWalletStatus(PaymentWalletStatus.IMPORT_IN_PROGRESS)
  }

  return (
    <AdminImportPrivateKeyForm
      paymentWalletStatus={paymentWalletStatusProp}
      onSuccess={() => onImportPrivateKey()}
    />
  )
}
