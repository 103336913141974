import { useContext } from 'react'
import { useQuery } from 'react-query'
import AuthService from '../../api/services/AuthService/AuthService'
import { AppContext } from '../../contexts/AppContext'
import QueryKeys from '../../utils/QueryKeys'
import { TwoSecondFactorActivation } from '../../types/TwoSecondFactorActivation'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'
import { t } from 'i18next'
import EnableTwoSecondFactor from '../../components/EnableTwoSecondFactor/EnableTwoSecondFactor'
import { Skeleton } from '@mui/material'
import DisableTwoSecondFactor from '../../components/DisableTwoSecondFactor/DisableTwoSecondFactor'

export default function EnableTwoSecondFactorPage() {
  const { user, setUser } = useContext(AppContext)
  const [openSnackbar] = useSnackbar()

  const userId = user?.id || ''

  const generateTwoSecondFactorResponse = useQuery(
    QueryKeys.getGenerateTwoSecondFactorKey(userId),
    async () => await AuthService.generateTwoSecondFactor(userId),
    { enabled: !Boolean(user?.twoSecondFactorEnabled), refetchOnWindowFocus: false }
  )

  const twoSecondFactorData = generateTwoSecondFactorResponse.data || {
    qrCodeImageUrl: '',
    manualEntrySetupCode: '',
  }

  const onTwoSecondFactorToggleSubmit = async (
    twoSecondFactorActivation: TwoSecondFactorActivation
  ) => {
    const result = await AuthService.toggleTwoSecondFactor(userId, twoSecondFactorActivation)
    if (result.succeed) {
      openSnackbar({
        autoHideDuration: 5000,
        message: twoSecondFactorActivation.enabled
          ? t('Doble factor activado con éxito.')
          : t('Doble factor desactivado con éxito.'),
        severity: 'success',
      })
      if (user) {
        user.twoSecondFactorEnabled = twoSecondFactorActivation.enabled
        setUser(user)
      }
    } else {
      openSnackbar({
        autoHideDuration: 3000,
        message: t('Algo salió mal. Por favor reintente.'),
        severity: 'error',
      })
    }
    return result
  }

  return (
    <>
      {user?.twoSecondFactorEnabled ? (
        <DisableTwoSecondFactor onTwoSecondFactorToggleSubmit={onTwoSecondFactorToggleSubmit} />
      ) : generateTwoSecondFactorResponse.isLoading ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
      ) : (
        <EnableTwoSecondFactor
          onTwoSecondFactorToggleSubmit={onTwoSecondFactorToggleSubmit}
          twoSecondFactorData={twoSecondFactorData}
        />
      )}
    </>
  )
}
