import styles from './PriceDirection.module.css'
import { PriceVariationType } from '../../types/PriceVariationType'
import { Grid } from '@mui/material'

interface PriceDirectionProps {
  variation: PriceVariationType
}

const symbols = {
  equal: '=',
  up: '\u2191',
  down: '\u2193',
}

export default function PriceDirection(props: PriceDirectionProps) {
  return (
    <Grid
      container
      className={`${styles.Symbol} ${styles[props.variation]}`}
      flexDirection="column"
      alignItems="center"
    >
      <Grid item>{symbols[props.variation]}</Grid>
    </Grid>
  )
}
