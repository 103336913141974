import React from 'react'
import { UserLoggedIn } from '../types/UserLoggedIn'
import { AppContextType } from '../types/AppContextType'
import { PaymentWalletStatus } from '../types/PaymentWalletStatus'

export const AppContext = React.createContext<AppContextType>({
  user: null,
  setUser: (user: UserLoggedIn | null) => {},
  paymentWalletStatus: null,
  setPaymentWalletStatus: (paymentWalletStatus: PaymentWalletStatus | null) => {},
  redirectRoute: '',
  setRedirectRoute: (redirectRoute: string) => {},
})
