import { apiClient } from '../../axios'
import { t } from 'i18next'
import { HTTPStatusCodes } from '../../../types/HTTPStatusCodes'
import { BTCDistributionData } from '../../../types/BTCDistributionData'
import {
  geAssetsByUserId,
  getAskForReceiveUrl,
  getDistributionUrl,
  getCalculateBTCDistributionURL,
  getDistributeBTCURL,
  getTotalTokenTenencyUrl,
  getDistributionsUrl,
  getSendAssetToUserUrl,
  getSendAssetToAllUsersUrl,
  getAssignAssetsUrl,
  getSendPendingAssetToUserUrl,
  getAssetDistributionsUrl,
  getCreateTokenUrl,
  getTokensUrl,
} from './AssetsServiceUrls'
import { AssetDistribution } from '../../../types/AssetDistribution'
import { AssignAsset } from '../../../types/AssignAsset'
import { TokenData } from '../../../types/TokenData'
import axios from 'axios'

const calculateDistribution = async (tokenId: string, amountToDistribute: number) => {
  let res
  try {
    res = await apiClient.get(getCalculateBTCDistributionURL(tokenId, amountToDistribute))
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      msg: e.response.status !== HTTPStatusCodes.ERROR ? e.response.data : t('Error'),
    }
  }
}

const distributeAssets = async (distributionData: BTCDistributionData) => {
  let res
  try {
    res = await apiClient.post(getDistributeBTCURL(), distributionData)
    return {
      statusCode: res.status,
      data: res.data,
    }
  } catch (e: any) {
    return {
      statusCode: e.response.status,
      msg: e.response.status !== HTTPStatusCodes.ERROR ? e.response.data : t('Error'),
    }
  }
}

const getUserAssets = async (id: string) => {
  const response = await apiClient.get(geAssetsByUserId(id))
  return response.data
}

const askForReceive = async (userId: string, tokenId: string, errorCallback?: () => void) => {
  try {
    const response = await apiClient.post(getAskForReceiveUrl(userId, tokenId))
    if (response.status !== HTTPStatusCodes.OK) {
      if (errorCallback) errorCallback()
    }
    return response.status === HTTPStatusCodes.OK
  } catch {
    if (errorCallback) errorCallback()
    return false
  }
}

const getTotalTokenTenency = async (tokenId: string) => {
  const response = await apiClient.get(getTotalTokenTenencyUrl(tokenId))
  return response.data
}

const getDistribution = async (
  tokenId: string,
  assetDistributionId: string
): Promise<AssetDistribution> => {
  const { data } = await apiClient.get<AssetDistribution>(
    getDistributionUrl(tokenId, assetDistributionId)
  )
  return data
}

const getDistributions = async (tokenId: string, userId?: string): Promise<AssetDistribution[]> => {
  const { data } = await apiClient.get<AssetDistribution[]>(getDistributionsUrl(tokenId, userId))
  return data
}

const getAssetDistributions = async (
  asset: string,
  userId?: string
): Promise<AssetDistribution[]> => {
  const { data } = await apiClient.get<AssetDistribution[]>(getAssetDistributionsUrl(asset, userId))
  return data
}

const sendAssetToUser = async (userId: string, distributionId: string) => {
  try {
    const response = await apiClient.post(getSendAssetToUserUrl(userId, distributionId))
    return response.status === HTTPStatusCodes.OK
  } catch {
    return false
  }
}

const sendPendingAssetToUser = async (userId: string, asset: string) => {
  try {
    const response = await apiClient.post(getSendPendingAssetToUserUrl(userId), { reward: asset })
    return response.status === HTTPStatusCodes.OK
  } catch {
    return false
  }
}

const sendAssetToAllUsers = async (distributionId: string) => {
  try {
    const response = await apiClient.post(getSendAssetToAllUsersUrl(distributionId))
    return response.status === HTTPStatusCodes.OK
  } catch {
    return false
  }
}

const assignAssets = async (userId: string, tokenId: string, tokenAmountAssigned: number) => {
  try {
    const requestBody: AssignAsset[] = [{ tokenId, tokenAmountAssigned }]
    const response = await apiClient.post(getAssignAssetsUrl(userId), requestBody)
    return response.status === HTTPStatusCodes.OK
  } catch {
    return false
  }
}

const createToken = async (token: TokenData) => {
  try {
    const response = await apiClient.post(getCreateTokenUrl(), token)
    return {
      succeed: response.status === HTTPStatusCodes.OK,
      error: '',
    }
  } catch (err) {
    const error = buildError(err)
    return {
      succeed: false,
      error: error,
    }
  }
}

const buildError = (err: any) => {
  if (axios.isAxiosError(err)) {
    return err.response?.data
  }
  return ''
}

const getTokens = async (userId?: string): Promise<TokenData[]> => {
  const response = await apiClient.get(getTokensUrl(userId))
  return response.data
}

const AssetsService = {
  calculateDistribution,
  distributeAssets,
  getAssets: getUserAssets,
  askForReceive,
  getTotalTokenTenency,
  getDistribution,
  getDistributions,
  sendAssetToUser,
  sendAssetToAllUsers,
  assignAssets,
  sendPendingAssetToUser,
  getAssetDistributions,
  createToken,
  getTokens,
}

export default AssetsService
