const GET_USERS = '/users'

export const getUserById = (id: string): string => {
  return `${GET_USERS}/${id}`
}

export const getUsersUrl = (): string => {
  return `${GET_USERS}`
}

export const putUserUrl = (id: string): string => {
  return `${GET_USERS}/${id}`
}
