import { Alert, Grid, Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { User } from '../../types/User'
import QueryKeys from '../../utils/QueryKeys'
import { useQuery } from 'react-query'
import UserService from '../../api/services/UserService/UserService'
import UserManagement from '../../components/UserManagement/UserManagement'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import Constants from '../../utils/Constants'
import UserWalletService from '../../api/services/UserWalletService/UserWalletService'
import { t } from 'i18next'
import AuthService from '../../api/services/AuthService/AuthService'
import { AssetDistribution } from '../../types/AssetDistribution'
import { useMemo } from 'react'
import { UserWallet } from '../../types/UserWallet'

const getAmountAssigned = (assetId: string, assets: any) => {
  return assets?.[assetId] ? assets[assetId].tokenAmountAssigned : 0
}

const getAmountToDistribute = (assetId: string, assets: any) => {
  return assets?.[assetId] ? assets[assetId].tokenAmountToDistribute : 0
}

const getAmountRequested = (assetId: string, assets: any) => {
  return assets?.[assetId] ? assets[assetId].tokenAmountRequested : 0
}

export default function AdminUserManagementPage() {
  const { userId } = useParams()
  const nonNullUserId = userId!
  const userResponse = useQuery<User, Error>(QueryKeys.getFetchUserKey(nonNullUserId), async () => {
    return await UserService.get(nonNullUserId)
  })
  const user = userResponse?.data
  const userAssetsResponse = useQuery(
    QueryKeys.getFetchUserAssetsKey(nonNullUserId),
    async () => await AssetsService.getAssets(nonNullUserId)
  )
  const asset = window.env.asset_symbol
  const amountOfAsset = getAmountAssigned(asset, userAssetsResponse.data)
  const amountOfAssetToDistribute = getAmountToDistribute(asset, userAssetsResponse.data)
  const amountOfAssetRequested = getAmountRequested(asset, userAssetsResponse.data)
  const amountOfBtc = getAmountAssigned(Constants.BITCOIN_SYMBOL, userAssetsResponse.data)
  const amountOfBtcToDistribute = getAmountToDistribute(
    Constants.BITCOIN_SYMBOL,
    userAssetsResponse.data
  )
  const amountOfBtcRequested = getAmountRequested(Constants.BITCOIN_SYMBOL, userAssetsResponse.data)

  const isAdminResponse = useQuery<boolean, Error>(
    QueryKeys.getFetchAuthUserKey(nonNullUserId),
    async () => await AuthService.getUserIsAdmin(nonNullUserId)
  )
  const isAdmin = isAdminResponse?.data || false

  const BTCWalletAddressResponse = useQuery<UserWallet[], Error>(
    QueryKeys.getFetchUserWalletsKey(nonNullUserId, Constants.BITCOIN_SYMBOL),
    async () =>
      await UserWalletService.getUserWallets(nonNullUserId, Constants.BITCOIN_SYMBOL, true)
  )
  const BTCWalletAddress = BTCWalletAddressResponse?.data?.at(0)?.address
  const userBtcDistributionsResponse = useQuery<AssetDistribution[], Error>(
    QueryKeys.getFetchAssetsDistributionsKey(Constants.BITCOIN_SYMBOL, userId),
    async () => await AssetsService.getDistributions(Constants.BITCOIN_SYMBOL, userId)
  )

  const MINENWalletAddressResponse = useQuery<UserWallet[], Error>(
    QueryKeys.getFetchUserWalletsKey(nonNullUserId, Constants.MINEN_SYMBOL),
    async () => {
      return await UserWalletService.getUserWallets(nonNullUserId, Constants.MINEN_SYMBOL, true)
    }
  )
  const MINENWalletAddress = MINENWalletAddressResponse?.data?.at(0)?.address
  const userBtcDistributions = useMemo(
    () => (userBtcDistributionsResponse.data ? userBtcDistributionsResponse.data : []),
    [userBtcDistributionsResponse]
  )

  const onRewardSent = () => {
    userAssetsResponse.refetch()
    userBtcDistributionsResponse.refetch()
  }

  const onAssetSent = () => {
    userAssetsResponse.refetch()
  }

  return (
    <Grid container>
      <Grid item width="100%">
        {userResponse.isLoading ||
        userAssetsResponse.isLoading ||
        BTCWalletAddressResponse.isLoading ||
        MINENWalletAddressResponse.isLoading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
        ) : user ? (
          <UserManagement
            asset={asset}
            assetAmount={amountOfAsset}
            isAdmin={isAdmin}
            user={user}
            assetRewarded={Constants.BITCOIN_SYMBOL}
            rewardWalletAddress={BTCWalletAddress}
            rewardAmount={amountOfBtc}
            distributions={userBtcDistributions}
            rewardAmountPendingToSend={amountOfBtcToDistribute + amountOfBtcRequested}
            onRewardSent={onRewardSent}
            onAssetSent={onAssetSent}
            assetWalletAddress={MINENWalletAddress}
            assetAmountPendingToSend={amountOfAssetToDistribute + amountOfAssetRequested}
          />
        ) : (
          <Alert variant="filled" severity="error">
            {t(
              'Hubo un error al obtener la información del usuario X. Por favor, intente nuevamente o contacte al administrador.',
              { userId }
            )}
          </Alert>
        )}
      </Grid>
    </Grid>
  )
}
