import Constants from '../Constants'
const chain = window.env.wallet_environment !== 'production' ? 'btc-testnet' : 'btc'
const blockchainTransactionUrl = `https://www.blockchain.com/${chain}/tx/{transactionIdKey}`

const assetBlockchainTransactionUrls = new Map([
  [Constants.BITCOIN_SYMBOL, blockchainTransactionUrl],
  [window.env.asset_symbol, ''],
])

const getBlockchainTransactionUrl = (asset: string, blockchainTransactionIdKey: string) => {
  return (
    assetBlockchainTransactionUrls
      .get(asset.toUpperCase())
      ?.replace('{transactionIdKey}', blockchainTransactionIdKey) || ''
  )
}

export default getBlockchainTransactionUrl
