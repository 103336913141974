import axios from 'axios'
import { HTTPStatusCodes } from '../types/HTTPStatusCodes'
import Constants from '../utils/Constants'
const baseURL = window.env.minando_api_base_url

export const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-type': 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const localStorageUser = localStorage.getItem('minando-user')
    config.headers = {
      'Client-version': process.env.REACT_APP_VERSION || '',
      Sender: Constants.MINANDO_WEB_CLIENT,
      ...(localStorageUser &&
        config.headers && {
          Authorization: `Bearer ${JSON.parse(localStorageUser).token.accessToken}`,
        }),
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === HTTPStatusCodes.UNAUTHORIZED) {
      localStorage.removeItem('minando-user')
      if (window.location.pathname !== '/login') window.location.replace('/login')
    }
    if (
      error.response.status === HTTPStatusCodes.BAD_REQUEST &&
      error.response.data === 'MUST_UPDATE'
    ) {
      localStorage.removeItem('minando-user')
      window.location.replace('/update-version')
      return
    }
    return Promise.reject(error)
  }
)
