import { Typography, Grid, ListItem, Link } from '@mui/material'
import { getDistributionStatusIcon } from '../../utils/functions/getDistributionStatusIcon'
import { useTranslation } from 'react-i18next'
import { DistributedItem } from './DistributedItem'

interface UserAssetDistributionItemProps {
  distributed: DistributedItem
  blockchainTransactionUrl: string
  blockchainTransactionUrlTxIdKey: string
  asset: string
}

export default function UserAssetDistributionItem(props: UserAssetDistributionItemProps) {
  const { t } = useTranslation()
  const dateFrom = new Date(props.distributed.dateFrom)
  const month = dateFrom.toLocaleDateString('es-ES', { month: 'short' })
  const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1)
  const year = dateFrom.getFullYear()

  return (
    <ListItem disablePadding>
      <Grid
        container
        item
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        textAlign="left"
      >
        <Grid item xs={4} md={4}>
          <Typography variant="body1" align="center">
            {`${monthCapitalized} ${year}`}
          </Typography>
        </Grid>
        <Grid item xs={5} md={5}>
          <Typography variant="body1" align="center">
            {`${props.distributed.amount} ${props.asset}`}
          </Typography>
        </Grid>
        <Grid item xs={2} md={2}>
          {props.blockchainTransactionUrl && props.distributed.blockchainTransaction && (
            <Typography variant="body1" align="center">
              <Link
                target="_blank"
                href={props.blockchainTransactionUrl.replace(
                  props.blockchainTransactionUrlTxIdKey,
                  props.distributed.blockchainTransaction
                )}
              >
                {t('Ver tx')}
              </Link>
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          {getDistributionStatusIcon(props.distributed.status, true)}
        </Grid>
      </Grid>
    </ListItem>
  )
}
