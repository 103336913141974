import LoginForm from '../../components/LoginForm/LoginForm'
import assetLogo from '../../assets/images/logo-black.svg'
import { Container, Grid } from '@mui/material'
import { useContext, useState } from 'react'
import { AppContext } from '../../contexts/AppContext'
import { UserLoggedIn } from '../../types/UserLoggedIn'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import LoginTwoSecondFactorForm from '../../components/LoginTwoSecondFactorForm/LoginTwoSecondFactorForm'

export default function LoginPage() {
  const { setUser, redirectRoute, setRedirectRoute } = useContext(AppContext)
  const [userPassword, setUserPassword] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const navigate = useNavigate()
  const { email } = useParams()

  const onLogin = (user: UserLoggedIn) => {
    setUser(user)
    redirectRoute ? navigate(redirectRoute) : navigate(`/user-wallet`)
    localStorage.setItem('minando-user', JSON.stringify(user))
    setRedirectRoute('')
  }

  const onOneFirstFactorLogin = (user: UserLoggedIn, pwd: string) => {
    if (user.twoSecondFactorEnabled) {
      setUserEmail(user.email)
      setUserPassword(pwd)
      return
    }

    onLogin(user)
  }

  const resetUserCredentials = () => {
    setUserEmail('')
    setUserPassword('')
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100vh"
      >
        <img src={assetLogo} height="210px" alt="logo" style={{ marginBottom: 20 }} />
        <Grid item style={{ width: '100%' }}>
          {userEmail.length > 0 && userPassword.length > 0 ? (
            <LoginTwoSecondFactorForm
              onSuccess={(user: UserLoggedIn) => onLogin(user)}
              onCancel={resetUserCredentials}
              userEmail={userEmail}
              userPassword={userPassword}
            />
          ) : (
            <LoginForm
              email={email}
              onSuccess={(user: UserLoggedIn, pwd: string) => onOneFirstFactorLogin(user, pwd)}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
