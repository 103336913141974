import { Container } from '@mui/material'
import Navbar from '../../components/Navbar/Navbar'

interface WithNavigationProps {
  userId: string
  children: any
}

/**
 * A functional React component as a wrapper for a page with navigation.
 * Renders the page's navbar and content
 */
function WithNavigation(props: WithNavigationProps) {
  return (
    <>
      <Navbar userId={props.userId} style={{ marginBottom: 30 }} />
      <Container maxWidth="lg">{props.children}</Container>
    </>
  )
}

export default WithNavigation
