import { TokenData } from '../../types/TokenData'
import { Grid, ListItem, Typography, ListItemButton } from '@mui/material'
import getTokenUrl from '../../utils/functions/getTokenUrl'

interface AdminTokenItemProps {
  token: TokenData
}

const navigateToToken = (address?: string) => {
  if (address) {
    window.open(getTokenUrl(address), '_blank', 'noopener,noreferrer')
  }
}

export default function AdminTokenItem(props: AdminTokenItemProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => navigateToToken(props.token.address)}
        sx={{ paddingRight: 0, paddingLeft: 0 }}
      >
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          textAlign="right"
        >
          <Grid item xs={2} md={2}>
            <Typography variant="caption" align="center">
              {props.token.symbol}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="caption" align="right">
              {props.token.name}
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant="caption" align="right">
              {props.token.type}
            </Typography>
          </Grid>
          <Grid item xs={2} md={2} sx={{ overflow: 'auto', pr: 1, pl: 1 }}>
            <Typography variant="caption" align="right">
              {props.token.totalSupply}
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant="caption" align="right">
              {props.token.decimals}
            </Typography>
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  )
}
