import React, { useState } from 'react'
import { AlertColor, AlertProps, Slide, SlideProps, Snackbar, SnackbarProps } from '@mui/material'

import MuiAlert from '@mui/material/Alert'

interface CustomSnackbarProps extends SnackbarProps {
  severity?: AlertColor
}

function TransitionLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />
}

export const CustomSnackbar = (props: CustomSnackbarProps) => {
  const [open, setOpen] = useState(props.open)

  const handleClose = () => {
    setOpen(false)
  }

  const CustomAlert = React.forwardRef(function Alert(
    props: AlertProps,
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={handleClose}
      autoHideDuration={props.autoHideDuration}
      TransitionComponent={TransitionLeft}
    >
      <CustomAlert severity={props.severity}>{props.message}</CustomAlert>
    </Snackbar>
  )
}
