import Grid from '@mui/material/Grid'
import Symbol from '../Symbol/Symbol'
import PriceDirection from '../PriceDirection/PriceDirection'
import PriceInformation from '../PriceInformation/PriceInformation'
import { PriceVariationType } from '../../types/PriceVariationType'
import Skeleton from '@mui/material/Skeleton'
import { useQuery } from 'react-query'
import PriceService from '../../api/services/PriceService/PriceService'
import { TokenPrice } from '../../types/TokenPrice'
import { PriceVariation } from '../../types/PriceVariation'
import PriceChart from '../PriceChart/PriceChart'
import { PriceChartData } from '../PriceChart/PriceChart'
import { SymbolData } from '../../types/SymbolData'
import { useMemo } from 'react'
import QueryKeys from '../../utils/QueryKeys'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { makeStyles } from '@mui/styles'
import { t } from 'i18next'

interface SymbolInformationProps {
  symbolData: SymbolData
  showChart: boolean
}

const WEEK_TIMEFRAME = 'week'

const useStyles = makeStyles({
  title: {
    color: '#7d7878',
    fontSize: 'small !important',
    textAlign: 'center',
  },
})

export default function SymbolInformation(props: SymbolInformationProps) {
  let tokenPrice
  let priceVariation

  const classes = useStyles()
  const priceResponse = useQuery<TokenPrice[], Error>(
    QueryKeys.getFetchPriceKey(props.symbolData.symbol),
    async () => {
      return await PriceService.getAssetPrice(props.symbolData.symbol)
    }
  )
  const variationResponse = useQuery<PriceVariation, Error>(
    QueryKeys.getFetchVariationKey(props.symbolData.symbol),
    async () => {
      return await PriceService.getAssetVariation(props.symbolData.symbol)
    }
  )
  const priceHistoryResponse = useQuery<TokenPrice[], Error>(
    QueryKeys.getFetchPriceHistoryKey(props.symbolData.symbol, WEEK_TIMEFRAME),
    async () => {
      return await PriceService.getPriceHistory(props.symbolData.symbol, WEEK_TIMEFRAME)
    },
    { enabled: props.showChart }
  )
  let priceChartData: PriceChartData[] = useMemo(
    () => mapTokenPricesToPriceChartData(priceHistoryResponse?.data),
    [priceHistoryResponse.data]
  )

  if (!priceResponse.isLoading && !variationResponse.isLoading) {
    tokenPrice = priceResponse.data?.length ? priceResponse.data[0] : undefined
    priceVariation = variationResponse.data
  }

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item container xs={8.3} alignItems="center">
        <Grid item xs={6}>
          <Symbol symbolData={props.symbolData} />
        </Grid>
        <Grid item xs={6}>
          {tokenPrice && priceVariation && (!props.showChart || priceChartData?.length) ? (
            props.showChart ? (
              <Box>
                <Typography className={classes.title}>{t('Últ. 7 días')}</Typography>
                <PriceChart data={priceChartData} showTooltip={false} />
              </Box>
            ) : (
              <PriceDirection
                variation={
                  priceVariation.percentageVariation !== 0
                    ? priceVariation.percentageVariation > 0
                      ? PriceVariationType.UP
                      : PriceVariationType.DOWN
                    : PriceVariationType.EQUAL
                }
              />
            )
          ) : (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
          )}
        </Grid>
      </Grid>
      <Grid item xs={3.7}>
        {tokenPrice && priceVariation ? (
          <PriceInformation
            currentPrice={tokenPrice.price}
            variation={priceVariation.percentageVariation}
          />
        ) : (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
        )}
      </Grid>
    </Grid>
  )
}

const mapTokenPricesToPriceChartData = (
  tokenPrices: TokenPrice[] | undefined
): PriceChartData[] => {
  return tokenPrices
    ? tokenPrices.map((priceHistory) => {
        const historyData: PriceChartData = {
          Datetime: priceHistory.period.dateFrom.toString(),
          Price: priceHistory.price,
        }
        return historyData
      })
    : []
}
