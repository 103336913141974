import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { User } from '../../types/User'
import Field from '../utils/Field'

interface UserProfileDetailsViewableProps {
  user: User
  editCallback: () => void
}

export default function UserProfileDetailsViewable(props: UserProfileDetailsViewableProps) {
  return (
    <Paper
      sx={{
        p: 3,
        py: 4,
        boxShadow: '0px 0px 18px 0px rgb(62 57 107 / 15%)',
      }}
      elevation={0}
    >
      <Grid container mb={3} flexDirection="row" spacing={1}>
        <Grid item>
          <Typography variant="h6">{t('Datos personales')}</Typography>
        </Grid>
        <Grid item>
          <Tooltip title={t('Editar').toString()}>
            <IconButton sx={{ p: 0, color: 'rgba(0, 0, 0, 0.87)' }} onClick={props.editCallback}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Field title={t('Nombre')} subtitle={props.user.userName.name} />
        <Field title={t('Apellido')} subtitle={props.user.userName.lastName} />
        <Field title={t('Tipo documento')} subtitle={props.user.idCard.type} />
        <Field title={t('Número de documento')} subtitle={props.user.idCard.number} />
        <Field title={t('CUIT')} subtitle={props.user.cuit} />
        <Field title={t('Número de teléfono')} subtitle={props.user.phoneNumber} />
        <Field title={t('Contacto')} subtitle={props.user.mainContact} />
        <Field title={t('Poseedor de documentos')} subtitle={props.user.documentKeeper} />
        <Field title={t('Carta oferta')} subtitle={props.user.offerLetter} />
        <Field title={t('UIF')} subtitle={props.user.uif} />
        <Field title={t('PEP')} subtitle={props.user.pep} />
        <Field title={t('Contrato de fideicomiso')} subtitle={props.user.trustAgreement} />
      </Grid>
    </Paper>
  )
}
