import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import { t } from 'i18next'
import EmailForm from '../../components/EmailForm/EmailForm'
import UserProfileDetailsEditable from '../../components/UserProfileDetails/UserProfileDetailsEditable'
import UserProfileAssetsEditable from '../../components/UserProfileAssets/UserProfileAssetsEditable'
import { Grid } from '@mui/material'
import { initUser, User } from '../../types/User'
import { useNavigate } from 'react-router'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'

const steps = [t('Correo electrónico'), t('Datos personales'), t('Assets')]

export default function AdminUserAddPage() {
  const [activeStep, setActiveStep] = React.useState(0)
  const [user, setUser] = React.useState<User>(initUser())
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const [openSnackbar] = useSnackbar()
  const asset = window.env.asset_symbol

  const handleNext = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [setActiveStep])

  const handleBack = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [setActiveStep])

  const stepZeroConfirmChanges = React.useCallback(
    (email: string) => {
      setUser({ ...user, email: email })
    },
    [setUser, user]
  )

  const stepOneConfirmChanges = React.useCallback(
    (user: User, isAdmin: boolean) => {
      setUser(user)
      setIsAdmin(isAdmin)
      handleNext()
    },
    [handleNext, setIsAdmin, setUser]
  )

  const stepTwoConfirmChanges = React.useCallback(() => {
    openSnackbar({
      autoHideDuration: 3000,
      message: t('Los cambios se han guardado con éxito.'),
      severity: 'success',
    })
    navigate('/admin/users')
  }, [navigate, openSnackbar])

  const getStepView = (step: number) => {
    switch (step) {
      case 0:
        return (
          <EmailForm
            email={user.email}
            nextCallback={handleNext}
            confirmChanges={stepZeroConfirmChanges}
          />
        )
      case 1:
        return (
          <UserProfileDetailsEditable
            rejectCallback={handleBack}
            resolveCallback={stepOneConfirmChanges}
            isAdmin={isAdmin}
            user={user}
          />
        )
      case 2:
        return (
          <UserProfileAssetsEditable
            asset={asset}
            assetAmount={0}
            rejectCallback={handleBack}
            resolveCallback={stepTwoConfirmChanges}
            user={user}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Grid container>
      <Grid item width="100%">
        <Typography variant="h5" mb={2}>
          {t('Alta de usuario')}
        </Typography>
      </Grid>
      <Grid item width="100%">
        <Stepper activeStep={activeStep} sx={{ pb: 4 }}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box mb={2}>{getStepView(activeStep)}</Box>
      </Grid>
    </Grid>
  )
}
