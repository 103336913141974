import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'
import { t } from 'i18next'

interface UserProfileWalletAddressProps {
  asset: string
  walletAddress?: string
  handleCopyToClipboard: (address?: string) => void
}

export default function UserProfileWalletAddress(props: UserProfileWalletAddressProps) {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item>
        <Typography color="textSecondary" variant="caption">
          {props.walletAddress
            ? `${props.asset}: ${props.walletAddress}`
            : t('X: Aún no tiene una wallet vinculada', { asset: props.asset })}
        </Typography>
      </Grid>
      {props.walletAddress && (
        <Grid item>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Tooltip title={t('Copiar').toString()}>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => props.handleCopyToClipboard(props.walletAddress)}
              >
                <ContentCopyIcon fontSize="small" style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
