import { Grid, GridProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

interface DistributeInformationItemProps extends GridProps {
  showBorderRight?: boolean
  title: string
  description: string
  alignSm: 'center' | 'left' | 'right'
}

const GridWithBorderRight = styled(Grid)(({ theme }) => ({
  padding: '8px !important',
  borderRight: '1px solid #eef0f0',
  [theme.breakpoints.up('xs')]: {
    borderRight: 'none',
  },
  [theme.breakpoints.up('md')]: {
    borderRight: '1px solid #eef0f0',
  },
}))

export default function DistributeInformationItem(props: DistributeInformationItemProps) {
  const { alignSm, showBorderRight, ...rest } = props

  return showBorderRight ? (
    <GridWithBorderRight item {...rest}>
      <Typography
        variant="body2"
        fontWeight="bold"
        color="#a2b2c4"
        sx={{ textAlign: { xs: 'center', sm: alignSm, md: 'left' } }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{ textAlign: { xs: 'center', sm: alignSm, md: 'left' } }}
      >
        {props.description}
      </Typography>
    </GridWithBorderRight>
  ) : (
    <Grid item padding={1} {...rest}>
      <Typography
        variant="body2"
        fontWeight="bold"
        color="#a2b2c4"
        sx={{ textAlign: { xs: 'center', sm: alignSm, md: 'left' } }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{ textAlign: { xs: 'center', sm: alignSm, md: 'left' } }}
      >
        {props.description}
      </Typography>
    </Grid>
  )
}
