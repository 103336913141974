import { Grid, IconButton, Paper, Tooltip, Typography, Card } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { t } from 'i18next'
import UserAssetDistributions from '../UserAssetDistributions/UserAssetDistributions'
import getBlockchainTransactionUrl from '../../utils/functions/getBlockchainTransactionUrl'
import Constants from '../../utils/Constants'
import { DistributionDetail } from '../../types/DistributionDetail'
import { AssetDistributedStatus } from '../../types/AssetDistributedStatus'
import { UserProfileAssetsProps } from './UserProfileAssets'
import Field from '../utils/Field'
import UserProfileAssetsSendAsset from './UserProfileAssetsSendAsset'
import MinandoFeatureFlags from '../../utils/MinandoFeatureFlags'

const blockchainTransactionIdKey = '{txId}'

interface UserProfileAssetsViewableProps extends UserProfileAssetsProps {
  editCallback: () => void
}

const buildDistributionDetail = (
  props: UserProfileAssetsViewableProps,
  amount: number,
  tokenId: string
) => {
  return {
    id: props.user.id,
    userId: props.user.id,
    username: `${props.user.userName.name} ${props.user.userName.lastName}`,
    userEmail: props.user.email,
    tokenId: tokenId,
    amount: amount,
    status: AssetDistributedStatus.ToDistribute,
    assetDistributionId: props.user.id,
  }
}

export default function UserProfileAssetsViewable(props: UserProfileAssetsViewableProps) {
  const isMvp6MINENDistributions = MinandoFeatureFlags.IS_MVP_6_MINEN_DISTRIBUTIONS()

  const rewardDistributionDetail: DistributionDetail = buildDistributionDetail(
    props,
    props.rewardAmountPendingToSend,
    props.assetRewarded
  )
  const assetDistributionDetail: DistributionDetail = buildDistributionDetail(
    props,
    props.assetAmountPendingToSend,
    props.asset
  )

  return (
    <Paper
      sx={{
        p: 3,
        py: 4,
        boxShadow: '0px 0px 18px 0px rgb(62 57 107 / 15%)',
      }}
      elevation={0}
    >
      <Grid
        container
        mb={3}
        flexDirection="row"
        spacing={1}
        sx={{ backgroundColor: Constants.GRAY_RGB, pb: 1 }}
      >
        <Grid item md={10.5} xs={10.5}>
          <Typography variant="h6">{t('Assets')}</Typography>
        </Grid>
        <Grid item md={1.5} xs={1.5}>
          <Tooltip title={t('Editar').toString()}>
            <IconButton sx={{ p: 0, color: 'rgba(0, 0, 0, 0.87)' }} onClick={props.editCallback}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        {!isMvp6MINENDistributions ? (
          <Field
            title={t('Cantidad de X', { asset: props.asset })}
            subtitle={props.assetAmount.toString()}
          />
        ) : (
          <Grid container ml={1}>
            <UserProfileAssetsSendAsset
              distributionDetail={assetDistributionDetail}
              onAssetSent={props.onAssetSent}
              assetAmount={props.assetAmount}
              assetAmountPendingToSend={props.assetAmountPendingToSend}
              isInvestmentReturnDistribution={false}
            />
          </Grid>
        )}
      </Grid>
      <UserProfileAssetsSendAsset
        distributionDetail={rewardDistributionDetail}
        onAssetSent={props.onRewardSent}
        assetAmount={props.rewardAmount}
        assetAmountPendingToSend={props.rewardAmountPendingToSend}
        isInvestmentReturnDistribution={true}
      />
      <Card sx={{ backgroundColor: Constants.GRAY_RGB, marginTop: 2, padding: 1 }}>
        <Typography variant="h6" mb={2}>
          {t('Historial de rendimientos')}
        </Typography>
        <UserAssetDistributions
          distributions={props.distributions}
          blockchainTransactionUrl={getBlockchainTransactionUrl(
            props.assetRewarded,
            blockchainTransactionIdKey
          )}
          blockchainTransactionIdKey={blockchainTransactionIdKey}
        />
      </Card>
    </Paper>
  )
}
