import * as yup from 'yup'
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import Alert from '@mui/material/Alert'
import { makeStyles } from '@mui/styles'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
  succeed: {
    color: 'green',
  },
})

interface WalletAddressSettingProps {
  symbol: string
  walletAddress?: string
  walletAddressValidator: (walletAddress: string, cryptoSymbol: string) => Boolean
  onAddressSet: (address: string, cryptoSymbol: string) => Promise<boolean>
}

export default function WalletAddressSetting(props: WalletAddressSettingProps) {
  const classes = useStyles()
  const [succeed, setSucceed] = useState(false)
  const [overridingAddress, setOverridingAddress] = useState(!Boolean(props.walletAddress))
  const [disabled, setDisabled] = useState(Boolean(props.walletAddress))
  const [wasAddressSetCalled, setWasAddressSetCalled] = useState<boolean>(false)
  const classSucceed = classes.succeed
  const classError = classes.error
  const navigate = useNavigate()

  const handleSubmit = async (walletAddress: string, actions: any) => {
    setWasAddressSetCalled(false)
    setSucceed(false)
    if (props.walletAddressValidator(walletAddress, props.symbol)) {
      const settedAddress = await props.onAddressSet(walletAddress, props.symbol)
      const didSucceed = Boolean(settedAddress)
      setWasAddressSetCalled(true)
      setSucceed(didSucceed)
      if (didSucceed) {
        setDisabled(true)
        setOverridingAddress(false)
      }
    } else {
      actions.setFieldError('address', t('La dirección ingresada no es válida'))
    }
    actions.setSubmitting(false)
  }

  const handleModifyAddress = useCallback((event: any, formik: any) => {
    formik.setFieldValue('address', '')
    event.preventDefault()
    setOverridingAddress(true)
    setDisabled(false)
  }, [])

  const handleCancelModifyAddress = useCallback(
    (event: any, formik: any) => {
      event.preventDefault()
      formik.setFieldValue('address', props.walletAddress)
      setOverridingAddress(false)
      setDisabled(true)
    },
    [props.walletAddress]
  )

  return (
    <Box>
      <Typography variant="h5" marginBottom={3}>
        Wallet {props.symbol}
      </Typography>
      <Alert variant="filled" severity="warning" style={{ marginBottom: 10 }}>
        {t('Asegúrese que la dirección sea correcta')}
      </Alert>
      <Formik
        initialValues={{ address: props.walletAddress }}
        validationSchema={yup.object({
          address: yup.string().required(t('Este campo es requerido')),
        })}
        onSubmit={(values, actions) => {
          if (values.address) {
            handleSubmit(values.address, actions)
          }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <TextField
              id="address"
              name="address"
              label={t('Dirección de la wallet')}
              variant="outlined"
              fullWidth
              value={formik.values.address}
              onChange={formik.handleChange}
              disabled={disabled}
              error={formik.touched.address && Boolean(formik.errors.address)}
              margin="dense"
            />
            {formik.touched.address && formik.errors.address && (
              <div className={classes.error}>{formik.errors.address}</div>
            )}
            {wasAddressSetCalled && (
              <Grid sx={{ pt: 2 }}>
                {
                  <Grid className={succeed ? classSucceed : classError}>
                    {' '}
                    {succeed
                      ? t('La wallet fue asociada con éxito.')
                      : t('Algo salió mal. Por favor reintente.')}{' '}
                  </Grid>
                }
              </Grid>
            )}
            <Grid container marginTop={2} flexDirection="row" justifyContent="flex-end">
              <Grid item>
                {!overridingAddress ? (
                  <>
                    <Button
                      variant="outlined"
                      sx={{ mr: 1 }}
                      type="button"
                      onClick={() => navigate('/user-wallet')}
                    >
                      {t('Volver')}
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={(event) => handleModifyAddress(event, formik)}
                    >
                      {t('Modificar')}
                    </Button>
                  </>
                ) : (
                  <>
                    {props.walletAddress && (
                      <Button
                        variant="outlined"
                        sx={{ mr: 1 }}
                        type="button"
                        onClick={(event) => handleCancelModifyAddress(event, formik)}
                      >
                        {t('Cancelar')}
                      </Button>
                    )}
                    {!props.walletAddress && (
                      <Button
                        variant="outlined"
                        disabled={formik.isSubmitting}
                        sx={{ mr: 1 }}
                        type="button"
                        onClick={() => navigate('/user-wallet')}
                      >
                        {t('Volver')}
                      </Button>
                    )}
                    <Button variant="contained" disabled={formik.isSubmitting} type="submit">
                      {formik.isSubmitting ? (
                        <CircularProgress size={26} color="inherit" />
                      ) : (
                        t('Aceptar')
                      )}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
