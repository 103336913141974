import { Grid, Typography, Paper, Button, Tooltip, IconButton, AlertColor } from '@mui/material'
import { useCallback, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Constants from '../../utils/Constants'
import { useTranslation } from 'react-i18next'
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'

interface InfoGuideProps {
  steps: InfoGuideStep[]
  onFinishSteps: Function
}

export interface InfoGuideStep {
  title: string
  details: string
  image: string | undefined
  showTokenAddress?: boolean
  tokenAddress?: string
}

export default function InfoGuide(props: InfoGuideProps) {
  const [currentStep, setCurrentStep] = useState(0)
  const [openSnackbar] = useSnackbar()

  const showSnackbar = useCallback(
    (message: string, severity: AlertColor) => {
      openSnackbar({
        autoHideDuration: 3000,
        message: message,
        severity: severity,
      })
    },
    [openSnackbar]
  )

  const handleCopyToClipboard = (textToCopy?: string) => {
    navigator.clipboard.writeText(textToCopy || '')
    showSnackbar(t('¡Copiado al portapapeles!'), 'success')
  }

  const { t } = useTranslation()

  const isFirstStep = () => {
    return Boolean(currentStep === 0)
  }

  const isLastStep = () => {
    return Boolean(currentStep === props.steps.length - 1)
  }

  return (
    <Paper sx={{ maxWidth: 900 }}>
      <Grid sx={{ backgroundColor: Constants.GRAY_RGB, p: 2, minHeight: '120px' }}>
        {props.steps[currentStep].image && (
          <Grid sx={{ float: 'right' }} ml={1}>
            <img src={props.steps[currentStep].image} height={'100px'} alt="" />
          </Grid>
        )}
        <Typography variant="h6" sx={{ lineHeight: 'unset' }}>
          {props.steps[currentStep].title}
        </Typography>
        <Typography variant="body1" textAlign="inherit">
          <div dangerouslySetInnerHTML={{ __html: props.steps[currentStep].details }}></div>
        </Typography>
        {props.steps[currentStep].showTokenAddress && (
          <Grid container pt={1}>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {props.steps[currentStep].tokenAddress}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={t('Copiar').toString()}>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => handleCopyToClipboard(props.steps[currentStep].tokenAddress)}
                >
                  <ContentCopyIcon fontSize="small" style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid mt={2}>
        {!isFirstStep() && (
          <ArrowBackIcon
            sx={{ float: 'left', cursor: 'pointer' }}
            onClick={() => setCurrentStep(currentStep - 1)}
          />
        )}
        {!isLastStep() && (
          <ArrowForwardIcon
            sx={{ float: 'right', cursor: 'pointer' }}
            onClick={() => setCurrentStep(currentStep + 1)}
          />
        )}
        {isLastStep() && (
          <Button
            size="small"
            onClick={() => props.onFinishSteps()}
            variant="contained"
            sx={{ float: 'right' }}
          >
            {' '}
            {t('Finalizar')}{' '}
          </Button>
        )}
      </Grid>
    </Paper>
  )
}
