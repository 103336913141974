import AdminNotificationsForm from '../../components/AdminNotificationsForm/AdminNotificationsForm'
import { Grid, Typography } from '@mui/material'
import { t } from 'i18next'

export default function AdminNotificationsPage() {
  return (
    <Grid container sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Grid item sx={{ width: '100%' }}>
        <Typography variant="h5" mb={2}>
          {t('Envío de notificaciones')}
        </Typography>
        <AdminNotificationsForm onSuccess={() => {}} />
      </Grid>
    </Grid>
  )
}
