import { Button, Grid, Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  title: {
    color: '#7d7878',
    fontSize: 'small !important',
    alignSelf: 'right',
  },
  subtitle: {
    fontSize: 'x-small !important',
    alignSelf: 'right',
  },
})

interface AssetActionsProps {
  asset: string
  availableAmount: number
}

export default function AssetActions(props: AssetActionsProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <Grid container padding="5px" flexDirection="column" alignContent="flex-end">
      <Grid item>
        <Typography align="right" className={classes.title}>
          {t('Disponible')}
        </Typography>
        <Typography className={classes.subtitle}>
          {`${props.availableAmount} ${props.asset}`}
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={() => navigate(`/${props.asset}/details`)}
            size="small"
            sx={{ p: 0.5, fontSize: 11 }}
          >
            {t('Ver más')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
