import { List, Divider, IconButton, Typography, CardContent, Card, Grid } from '@mui/material'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import { Box } from '@mui/system'
import AdminDistributeCheckpointItem from './AdminDistributeCheckpointItem'
import { t } from 'i18next'
import { makeStyles } from '@mui/styles'
import DistributeInformationItem from '../DistributeInformationItem/DistributeInformationItem'
import { AssetDistribution } from '../../types/AssetDistribution'
import { useMemo } from 'react'

interface AdminDistributeCheckpointListProps {
  checkpoints: AssetDistribution[]
  onClickCheckpoint: Function
  onAddCheckpoint?: Function
}

const useStyles = makeStyles({
  principalCard: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
})

export default function AdminDistributeCheckpointList(props: AdminDistributeCheckpointListProps) {
  const classes = useStyles()
  const tokenId = props.checkpoints.length > 0 ? props.checkpoints[0].tokenId : ''
  const totalAmount = useMemo(
    () =>
      props.checkpoints.reduce((partialSum, currentValue) => partialSum + currentValue.quantity, 0),
    [props.checkpoints]
  )
  const amountDelivered = useMemo(
    () =>
      props.checkpoints.reduce(
        (partialSum, currentValue) => partialSum + currentValue.amountDelivered,
        0
      ),
    [props.checkpoints]
  )
  const amountPendingToSend = useMemo(
    () =>
      props.checkpoints.reduce(
        (partialSum, currentValue) => partialSum + currentValue.amountPendingToSend,
        0
      ),
    [props.checkpoints]
  )
  const amountRequested = useMemo(
    () =>
      props.checkpoints.reduce(
        (partialSum, currentValue) => partialSum + currentValue.amountRequested,
        0
      ),
    [props.checkpoints]
  )

  return (
    <Box>
      {props.checkpoints.length > 0 ? (
        <>
          <Card sx={{ minWidth: 275 }} className={classes.principalCard}>
            <CardContent sx={{ width: '100%' }}>
              <Grid container>
                <DistributeInformationItem
                  xs={12}
                  sm={6}
                  md={3}
                  showBorderRight
                  title={t('Total')}
                  description={`${totalAmount} ${tokenId}`}
                  alignSm="left"
                />
                <DistributeInformationItem
                  xs={12}
                  sm={6}
                  md={3}
                  showBorderRight
                  title={t('Total pendiente de envio')}
                  description={`${amountPendingToSend} ${tokenId}`}
                  alignSm="right"
                />
                <DistributeInformationItem
                  xs={12}
                  sm={6}
                  md={3}
                  showBorderRight
                  title={t('Total enviado')}
                  description={`${amountDelivered} ${tokenId}`}
                  alignSm="left"
                />
                <DistributeInformationItem
                  xs={12}
                  sm={6}
                  md={3}
                  showBorderRight
                  title={t('Total solicitado')}
                  description={`${amountRequested} ${tokenId}`}
                  alignSm="right"
                />
              </Grid>
            </CardContent>
          </Card>
          <List>
            {props.checkpoints.map((checkpoint, i) => (
              <Box key={i}>
                <AdminDistributeCheckpointItem
                  distributionId={checkpoint.id}
                  quantity={checkpoint.quantity}
                  tokenId={checkpoint.tokenId}
                  dateFrom={checkpoint.dateFrom}
                  dateTo={checkpoint.dateTo}
                  onClickCheckPoint={props.onClickCheckpoint}
                  status={checkpoint.status}
                />
                <Divider />
              </Box>
            ))}
          </List>
        </>
      ) : (
        <Typography variant="subtitle1" mt={3} mb={2}>
          {t('No se encontraron registros')}
        </Typography>
      )}
      {props.onAddCheckpoint && (
        <IconButton
          size="large"
          sx={{ position: 'fixed', right: '0px', bottom: '0px' }}
          onClick={() => {
            if (props.onAddCheckpoint) props.onAddCheckpoint()
          }}
        >
          <AddCircleSharpIcon sx={{ width: '50px', height: '50px' }} />
        </IconButton>
      )}
    </Box>
  )
}
