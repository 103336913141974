import { apiClient } from '../../axios'
import { HTTPStatusCodes } from '../../../types/HTTPStatusCodes'
import {
  getPaymentWalletStatusUrl,
  getImportPrivateKeyUrl,
  getUnlockPaymentWalletUrl,
  getLockPaymentWalletUrl,
  getPaymentWalletBalanceUrl,
} from './PaymentWalletServiceUrls'
import { PaymentWalletStatus } from '../../../types/PaymentWalletStatus'

const getPaymentWalletStatus = async (tokenId: string): Promise<PaymentWalletStatus> => {
  const { data } = await apiClient.get<PaymentWalletStatus>(getPaymentWalletStatusUrl(tokenId))
  return data
}

const importPrivateKey = async (privateKey: string, walletPassphrase: string, tokenId: string) => {
  try {
    const response = await apiClient.post(getImportPrivateKeyUrl(tokenId), {
      privateKey,
      walletPassphrase,
    })
    return response.status === HTTPStatusCodes.OK
  } catch (error: any) {
    console.log('could not import private key. error: ', error)
    return false
  }
}

const unlockPaymentWallet = async (walletPassphrase: string, tokenId: string) => {
  try {
    const response = await apiClient.put(getUnlockPaymentWalletUrl(tokenId), { walletPassphrase })
    return response.status === HTTPStatusCodes.OK
  } catch (error: any) {
    console.log('could not unlock payment wallet. error: ', error)
    return false
  }
}

const lockPaymentWallet = async (tokenId: string) => {
  try {
    const response = await apiClient.put(getLockPaymentWalletUrl(tokenId), {})
    return response.status === HTTPStatusCodes.OK
  } catch (error: any) {
    console.log('could not lock payment wallet. error: ', error)
    return false
  }
}

const getPaymentWalletBalance = async (tokenId: string): Promise<string> => {
  const { data } = await apiClient.get<string>(getPaymentWalletBalanceUrl(tokenId))
  return data
}

const PaymentWalletService = {
  getPaymentWalletStatus,
  importPrivateKey,
  unlockPaymentWallet,
  lockPaymentWallet,
  getPaymentWalletBalance,
}

export default PaymentWalletService
