import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { t } from 'i18next'
import { CSSProperties } from 'react'
import DistributeInformationItem from '../DistributeInformationItem/DistributeInformationItem'

interface AdminDistributeHeaderProps {
  dateFrom: Date
  dateTo: Date
  paidUsers: string
  responsable: string
  amountPaid: number
  amountToPay: number
  amountRequested: number
  tokenId: string
  total: number
  style?: CSSProperties
}

const useStyles = makeStyles({
  principalCard: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
})

const formatDate = (date: Date) => {
  return new Date(date).toLocaleDateString('en-GB')
}

export default function AdminDistributeHeader(props: AdminDistributeHeaderProps) {
  const classes = useStyles()

  return (
    <Box style={props.style}>
      <Typography variant="h5" mb={2}>
        {t('Información de la distribución')}
      </Typography>
      <Card sx={{ minWidth: 275 }} className={classes.principalCard}>
        <CardContent sx={{ width: '100%' }}>
          <Grid container>
            <DistributeInformationItem
              xs={12}
              sm={6}
              md={2.5}
              showBorderRight
              title={t('Período')}
              description={`${formatDate(props.dateFrom)} - ${formatDate(props.dateTo)}`}
              alignSm="left"
            />
            <DistributeInformationItem
              xs={12}
              sm={6}
              md={2.5}
              showBorderRight
              title={t('Responsable')}
              description={props.responsable}
              alignSm="right"
            />
            <DistributeInformationItem
              xs={12}
              sm={6}
              md={1.5}
              showBorderRight
              title={t('Total')}
              description={`${props.total} ${props.tokenId}`}
              alignSm="left"
            />
            <DistributeInformationItem
              xs={12}
              sm={6}
              md={1.5}
              showBorderRight
              title={t('Pendiente de envio')}
              description={`${props.amountToPay} ${props.tokenId}`}
              alignSm="right"
            />
            <DistributeInformationItem
              xs={12}
              sm={6}
              md={1.5}
              title={t('Enviado')}
              showBorderRight
              description={`${props.amountPaid} ${props.tokenId}`}
              alignSm="left"
            />
            <DistributeInformationItem
              xs={12}
              sm={6}
              md={1.5}
              title={t('Solicitado')}
              showBorderRight
              description={`${props.amountRequested} ${props.tokenId}`}
              alignSm="right"
            />
            <DistributeInformationItem
              xs={12}
              sm={6}
              md={1}
              title={t('Usuarios pagados')}
              description={props.paidUsers}
              alignSm="left"
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}
