import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm'
import { Grid, Typography } from '@mui/material'
import { t } from 'i18next'
import { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../contexts/AppContext'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useNavigate } from 'react-router'

export default function ChangePasswordPage() {
  const { user, setUser } = useContext(AppContext)
  const navigate = useNavigate()
  const [hasChangeFirstTimePassword, setHasChangeFirstTimePassword] = useState<boolean>(false)
  let timeout: { current: NodeJS.Timeout | null } = useRef(null)

  const onChangePassword = () => {
    if (user && user.mustChangePassword) {
      setUser({
        ...user,
        mustChangePassword: false,
      })
      setHasChangeFirstTimePassword(true)
      localStorage.setItem('minando-user', JSON.stringify({ ...user, mustChangePassword: false }))
      timeout.current = setTimeout(() => navigate(`/user-wallet`), 5000)
    }
  }

  useEffect(() => {
    return () => clearTimeout(timeout.current as NodeJS.Timeout)
  }, [])

  return (
    <>
      <Grid container mb={2} flexDirection="row" justifyContent="space-between">
        <Grid item xs={11}>
          <Typography variant="h5">{t('Cambiar contraseña')}</Typography>
        </Grid>
        {hasChangeFirstTimePassword && (
          <Grid item xs={1} textAlign="right">
            {<ArrowForwardIcon onClick={() => navigate('/user-wallet')} />}
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item width="100%">
          {user && <ChangePasswordForm userEmail={user.email} onSuccess={onChangePassword} />}
        </Grid>
      </Grid>
    </>
  )
}
