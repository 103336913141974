import { Tabs, TabsProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
})

export default function CustomTabs(props: TabsProps) {
  return <AntTabs {...props}>{props.children}</AntTabs>
}
