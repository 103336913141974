import Constants from '../Constants'

const WAValidator = require('wallet-address-validator')

// source https://github.com/ognus/wallet-address-validator
export const isValidWalletAddress = (walletAddress: string, cryptoSymbol: string): boolean => {
  try {
    if (cryptoSymbol.toUpperCase() !== Constants.BITCOIN_SYMBOL) {
      cryptoSymbol = 'ETH'
    }
    return WAValidator.validate(walletAddress, cryptoSymbol)
  } catch (e) {
    return false
  }
}
