import { Box, Divider, List } from '@mui/material'
import { DistributionDetail } from '../../types/DistributionDetail'
import AdminDistributeUserListItem from '../AdminDistributeUserListItem/AdminDistributeUserListItem'

interface AdminDistributeUserListProps {
  distributions: Array<DistributionDetail>
  payload: (distribution: DistributionDetail) => void
}

export default function AdminDistributeUserList(props: AdminDistributeUserListProps) {
  return (
    <Box>
      <List>
        {props.distributions.map((distribution) => (
          <Box key={distribution.userId}>
            <AdminDistributeUserListItem distribution={distribution} payload={props.payload} />
            <Divider />
          </Box>
        ))}
      </List>
    </Box>
  )
}
