const isEnabled = (featureFlagValue: string | undefined) => {
  return featureFlagValue?.toString() === 'true'
}

const IS_MVP_2 = () => {
  return isEnabled(window.env.is_mvp_2)
}

const IS_MVP_3 = () => {
  return isEnabled(window.env.is_mvp_3)
}

const IS_MVP_4_ADMIN = () => {
  return isEnabled(window.env.is_mvp_4_admin)
}

const IS_MVP_4_USER = () => {
  return isEnabled(window.env.is_mvp_4_user)
}

const IS_MVP_5_CREATE_TOKEN = () => {
  return isEnabled(window.env.is_mvp_5_create_token)
}

const IS_MVP_5_ASSET_DETAILS = () => {
  return isEnabled(window.env.is_mvp_5_asset_details)
}

const IS_MVP_6_MINEN_DISTRIBUTIONS = () => {
  return isEnabled(window.env.is_mvp_6_minen_distributions)
}

const MinandoFeatureFlags = {
  IS_MVP_2,
  IS_MVP_3,
  IS_MVP_4_ADMIN,
  IS_MVP_4_USER,
  IS_MVP_5_CREATE_TOKEN,
  IS_MVP_5_ASSET_DETAILS,
  IS_MVP_6_MINEN_DISTRIBUTIONS,
}

export default MinandoFeatureFlags
