import { Button, Grid, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { t } from 'i18next'
import { useState } from 'react'
import ShadowBox from '../ShadowBox/ShadowBox'

interface EmailFormProps {
  confirmChanges: Function
  email: string
  nextCallback: () => void
}

export default function EmailForm(props: EmailFormProps) {
  const [emailEditable, setEmailEditable] = useState<string>(props.email)

  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        props.confirmChanges(emailEditable)
        props.nextCallback()
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ShadowBox>
            <TextField
              fullWidth
              required
              variant="outlined"
              label={t('Correo electrónico')}
              value={emailEditable}
              onChange={(e) => setEmailEditable(e.target.value)}
              type="email"
            />
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                pt: 2,
              }}
              spacing={1}
            >
              <Grid item>
                <Button color="primary" variant="contained" type="submit">
                  {t('Siguiente')}
                </Button>
              </Grid>
            </Grid>
          </ShadowBox>
        </Form>
      )}
    </Formik>
  )
}
