import MinandoFeatureFlags from '../../utils/MinandoFeatureFlags'
import UserAssetDistributions from '../../components/UserAssetDistributions/UserAssetDistributions'
import { useContext, useMemo, useCallback } from 'react'
import { AppContext } from '../../contexts/AppContext'
import { Navigate } from 'react-router'
import { Box, Typography, Skeleton, CardContent, Card, Paper, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { AssetDistribution } from '../../types/AssetDistribution'
import QueryKeys from '../../utils/QueryKeys'
import Constants from '../../utils/Constants'
import AssetsService from '../../api/services/AssetsService/AssetsService'
import { makeStyles } from '@mui/styles'
import UserAssetAmountDetails from '../../components/UserAssetAmountDetails/UserAssetAmountDetails'
import { UserWallet } from '../../types/UserWallet'
import { useNavigate, useParams } from 'react-router'
import UserWalletService from '../../api/services/UserWalletService/UserWalletService'
import getBlockchainTransactionUrl from '../../utils/functions/getBlockchainTransactionUrl'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'
import queryClient from '../../utils/QueryClient'
import getAssetLogoSrc from '../../utils/functions/getAssetLogoSrc'

const useStyles = makeStyles({
  principalCard: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
  symbolImage: {
    pointerEvents: 'none',
  },
  symbolImageContainer: {
    minHeight: '40px !important',
    minWidth: '40px !important',
    maxHeight: '70px !important',
    maxWidth: '70px !important',
  },
})

const blockchainTransactionIdKey = '{txId}'

export default function UserAssetDetails() {
  const { asset } = useParams()
  const assetParam = asset?.toUpperCase() || ''
  const { t } = useTranslation()
  const { user } = useContext(AppContext)
  const userId = useMemo(() => (user ? user.id : ''), [user])
  const classes = useStyles()
  const navigate = useNavigate()
  const [openSnackbar] = useSnackbar()

  const userDistributions = useQuery<AssetDistribution[], Error>(
    QueryKeys.getFetchAssetsDistributionsKey(assetParam, userId),
    async () => await AssetsService.getAssetDistributions(assetParam, userId)
  )
  const distributions = useMemo(
    () => (userDistributions.data ? userDistributions.data : []),
    [userDistributions]
  )
  const userAssetsResponse = useQuery(
    QueryKeys.getFetchUserAssetsKey(userId),
    async () => await AssetsService.getAssets(userId)
  )
  const defaultUserAsset = {
    tokenId: asset,
    tokenAmountAssigned: 0,
    tokenAmountDelivered: 0,
    tokenAmountInTransit: 0,
    tokenAmountRequested: 0,
    tokenAmountToDistribute: 0,
    isTransferable: false,
    isInvestmentReturn: false,
  }
  const userAsset = userAssetsResponse.data
    ? userAssetsResponse.data[assetParam] || defaultUserAsset
    : defaultUserAsset
  const userWalletsResponse = useQuery<UserWallet[], Error>(
    QueryKeys.getFetchUserWalletsKey(userId, assetParam),
    async () => {
      return await UserWalletService.getUserWallets(userId, assetParam)
    }
  )

  const hasWallet = useCallback((): boolean => {
    const wallets: UserWallet[] = userWalletsResponse?.data || []
    return wallets.length > 0
  }, [userWalletsResponse])

  const askForAvailable = useCallback(async () => {
    if (!hasWallet()) {
      navigate({
        pathname: '/user-wallet/wallet-settings',
        search: `?asset=${asset}`,
      })
      return
    }

    const didSucceed = await AssetsService.askForReceive(userId, assetParam)
    if (didSucceed) {
      openSnackbar({
        autoHideDuration: 5000,
        message: t(
          'Los X han sido solicitados. En breve recibirá un correo electrónico con información sobre la transferencia.',
          { asset: assetParam }
        ),
        severity: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.getFetchAssetsDistributionsKey(assetParam, userId))
      userDistributions.refetch()
      userAssetsResponse.refetch()
    } else {
      openSnackbar({
        autoHideDuration: 3000,
        message: t('Algo salió mal. Por favor reintente.'),
        severity: 'error',
      })
    }
  }, [
    userId,
    navigate,
    hasWallet,
    openSnackbar,
    t,
    userAssetsResponse,
    assetParam,
    userDistributions,
    asset,
  ])

  if (!MinandoFeatureFlags.IS_MVP_4_USER() || !asset) {
    return <Navigate to="/user-wallet" replace />
  }

  return (
    <Box>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item mr={1}>
          <Typography variant="h5" pt={0.5}>
            {userAsset.isInvestmentReturn
              ? t('Mis ganancias X', { asset: assetParam })
              : t('Mis X', { asset: assetParam })}
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.symbolImageContainer}>
            <img src={getAssetLogoSrc(assetParam)} className={classes.symbolImage} alt="logo" />
          </div>
        </Grid>
      </Grid>
      {userAssetsResponse.isLoading ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
      ) : (
        <UserAssetAmountDetails asset={userAsset} onClickAskForAvailable={askForAvailable} />
      )}
      <Card
        sx={{ marginTop: 2, minWidth: 275, backgroundColor: Constants.GRAY_RGB }}
        className={classes.principalCard}
      >
        <CardContent sx={{ width: '100%' }}>
          <Typography variant="h6" mb={2}>
            {userAsset.isInvestmentReturn
              ? t('Historial de rendimientos')
              : t('Historial de tenencia')}
          </Typography>
          <Paper elevation={1}>
            <Card sx={{ boxShadow: 'none' }}>
              {userDistributions.isLoading ? (
                <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
              ) : (
                <UserAssetDistributions
                  distributions={distributions}
                  blockchainTransactionUrl={getBlockchainTransactionUrl(
                    assetParam,
                    blockchainTransactionIdKey
                  )}
                  blockchainTransactionIdKey={blockchainTransactionIdKey}
                />
              )}
            </Card>
          </Paper>
        </CardContent>
      </Card>
    </Box>
  )
}
