import UserAccount from '../../components/UserAccount/UserAccount'
import { useQuery } from 'react-query'
import QueryKeys from '../../utils/QueryKeys'
import UserService from '../../api/services/UserService/UserService'
import { User } from '../../types/User'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'

export default function UserAccountPage() {
  const { user } = useContext(AppContext)
  const userResponse = useQuery<User, Error>(
    QueryKeys.getFetchUserKey(user?.id || ''),
    async () => {
      return await UserService.get(user?.id || '')
    }
  )

  return <UserAccount user={userResponse?.data} />
}
