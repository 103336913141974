import { Typography, Button, Grid, Box, CircularProgress } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DistributionDetail } from '../../types/DistributionDetail'

interface SendAssetToUserWithoutActiveWalletProps {
  distributionDetail: DistributionDetail
  noWalletAssignedCallback: () => void
  goBack: () => void
  callbackStatusMessage: string
  isErrorMessage: boolean
}

export default function SendAssetToUserWithoutActiveWallet({
  distributionDetail,
  noWalletAssignedCallback,
  goBack,
  callbackStatusMessage,
  isErrorMessage,
}: SendAssetToUserWithoutActiveWalletProps) {
  const { t } = useTranslation()

  const onClickCancel = (event: any) => {
    event.preventDefault()
    goBack()
  }

  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [shouldShowCallbackStatus, setShouldShowCallbackStatus] = useState<boolean>(false)
  const onClickAskToAssociateWallet = useCallback(async () => {
    setIsProcessing(true)
    await noWalletAssignedCallback()
    setIsProcessing(false)
    setShouldShowCallbackStatus(true)
  }, [noWalletAssignedCallback])

  return (
    <Box>
      <Typography variant="h6">{t('Asociar wallet')}</Typography>
      <Typography variant="body1" mb={2}>
        {`${distributionDetail.username} ${t(
          'no posee una wallet activa. Puede enviarle un correo electrónico para solicitarle que asocie una presionando el botón de abajo.'
        )}`}
      </Typography>
      <Grid container marginTop={2} flexDirection="row" justifyContent="flex-end">
        <Grid item>
          <Button variant="outlined" color="primary" sx={{ mr: 1 }} onClick={onClickCancel}>
            {t('Volver')}
          </Button>
          <Button
            disabled={isProcessing}
            variant="contained"
            color="primary"
            onClick={onClickAskToAssociateWallet}
          >
            {isProcessing ? <CircularProgress size={26} color="inherit" /> : t('Solicitar wallet')}
          </Button>
          {shouldShowCallbackStatus && (
            <Typography
              variant="body1"
              align="right"
              mt={1}
              color={isErrorMessage ? 'red' : 'green'}
            >
              {callbackStatusMessage}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
