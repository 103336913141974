const PAYMENT_WALLET_URL = '/payment-wallet'

export const getPaymentWalletStatusUrl = (tokenId: string) => {
  return `${PAYMENT_WALLET_URL}/${tokenId}/status`
}

export const getImportPrivateKeyUrl = (tokenId: string) => {
  return `${PAYMENT_WALLET_URL}/${tokenId}`
}

export const getUnlockPaymentWalletUrl = (tokenId: string) => {
  return `${PAYMENT_WALLET_URL}/${tokenId}/unlock`
}

export const getLockPaymentWalletUrl = (tokenId: string) => {
  return `${PAYMENT_WALLET_URL}/${tokenId}/lock`
}

export const getPaymentWalletBalanceUrl = (tokenId: string) => {
  return `${PAYMENT_WALLET_URL}/${tokenId}/balance`
}
