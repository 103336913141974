import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { makeStyles } from '@mui/styles'
import Skeleton from '@mui/material/Skeleton'
import { User } from '../../types/User'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Constants from '../../utils/Constants'
import { Box } from '@mui/material'

interface UserAccountProps {
  user?: User
}

const useStyles = makeStyles({
  principalCard: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
})

const inputStyle = {
  style: {
    backgroundColor: 'white',
  },
}

export default function UserAccount(props: UserAccountProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const user = props.user

  return (
    <Box>
      <Card
        sx={{ minWidth: 275, backgroundColor: Constants.GRAY_RGB }}
        className={classes.principalCard}
      >
        <CardContent sx={{ width: '100%' }}>
          <Typography variant="h5" mb={2}>
            {t('Tus datos')}
          </Typography>
          <Typography variant="h6" align="left">
            {t('Datos de cuenta')}
          </Typography>
          <Paper elevation={1}>
            <Card sx={{ boxShadow: 'none' }}>
              {Boolean(user) ? (
                <TextField
                  label={t('Email')}
                  id="user-account-email"
                  disabled
                  fullWidth
                  value={user?.email}
                  variant="filled"
                  inputProps={inputStyle}
                />
              ) : (
                <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
              )}
            </Card>
          </Paper>

          <Typography variant="h6" align="left" mt={2}>
            {t('Datos personales')}
          </Typography>
          <Paper elevation={1}>
            <Card sx={{ boxShadow: 'none' }}>
              {Boolean(user) ? (
                <TextField
                  label={t('Nombre y apellido')}
                  id="user-account-name"
                  disabled
                  fullWidth
                  value={`${user?.userName.name} ${user?.userName.lastName}`}
                  variant="filled"
                  inputProps={inputStyle}
                />
              ) : (
                <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
              )}
              {Boolean(user) ? (
                <TextField
                  label={t('Documento')}
                  id="user-account-document"
                  disabled
                  fullWidth
                  value={`${user?.idCard.type} ${user?.idCard.number}`}
                  variant="filled"
                  inputProps={inputStyle}
                />
              ) : (
                <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
              )}
              {Boolean(user) ? (
                <TextField
                  label={t('Teléfono')}
                  id="user-account-phone"
                  disabled
                  fullWidth
                  value={user?.phoneNumber}
                  variant="filled"
                  inputProps={inputStyle}
                />
              ) : (
                <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
              )}
              {Boolean(user) ? (
                <TextField
                  label={t('CUIT')}
                  id="user-account-cuit"
                  disabled
                  fullWidth
                  value={user?.cuit || ''}
                  variant="filled"
                  inputProps={inputStyle}
                />
              ) : (
                <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
              )}
            </Card>
          </Paper>
        </CardContent>
      </Card>
    </Box>
  )
}
