import {
  AppBar,
  AppBarProps,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Tooltip,
  AlertColor,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link, useLocation } from 'react-router-dom'
import assetLogo from '../../assets/images/logo-black.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Lock from '@mui/icons-material/Lock'
import LockOpen from '@mui/icons-material/LockOpen'
import { useContext, useState, useCallback, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthService from '../../api/services/AuthService/AuthService'
import { AppContext } from '../../contexts/AppContext'
import MinandoFeatureFlags from '../../utils/MinandoFeatureFlags'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { PaymentWalletStatus } from '../../types/PaymentWalletStatus'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { EnumDictionary } from '../../types/EnumDictionary'
import PaymentWalletService from '../../api/services/PaymentWalletService/PaymentWalletService'
import LockPaymentWalletDialog from '../LockPaymentWalletDialog/LockPaymentWalletDialog'
import { t } from 'i18next'
import queryClient from '../../utils/QueryClient'
import QueryKeys from '../../utils/QueryKeys'
import { useDialog } from '../../utils/hooks/UseDialog'
import EnterWalletPasswordDialog from '../EnterWalletPasswordDialog/EnterWalletPasswordDialog'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 2,
  },
  symbolImage: {
    pointerEvents: 'none',
    minHeight: '60px',
    minWidth: '60px',
    maxHeight: '70px',
    maxWidth: '70px',
  },
  iconPaymentWallet: {
    pointerEvents: 'none',
    minHeight: '20px',
    minWidth: '20px',
    maxHeight: '30px',
    maxWidth: '30px',
  },
  iconUser: {
    pointerEvents: 'none',
    minHeight: '40px',
    minWidth: '40px',
    maxHeight: '50px',
    maxWidth: '50px',
  },
})

interface PaymentWalletStatusIcon {
  color: string
  tooltip: string
  iconElement: ReactElement
}

const paymentWalletIcon: EnumDictionary<PaymentWalletStatus, PaymentWalletStatusIcon> = {
  [PaymentWalletStatus.LOCKED]: { color: '#e0caca', tooltip: 'Bloqueada', iconElement: <Lock /> },
  [PaymentWalletStatus.UNLOCKED]: { color: '', tooltip: 'Desbloqueada', iconElement: <LockOpen /> },
  [PaymentWalletStatus.IMPORT_IN_PROGRESS]: {
    color: '#e0caca',
    tooltip: 'Procesando',
    iconElement: <HourglassTopIcon />,
  },
  [PaymentWalletStatus.NOT_IMPORTED]: { color: '', tooltip: '', iconElement: <></> },
}

interface NavbarProps extends AppBarProps {
  userId: string
}

export default function Navbar(props: NavbarProps) {
  const curentLocation = useLocation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { user, setUser, paymentWalletStatus, setPaymentWalletStatus } = useContext(AppContext)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const paymentWalletStatusIcon =
    paymentWalletIcon[paymentWalletStatus || PaymentWalletStatus.NOT_IMPORTED]
  const [openDialog, closeDialog] = useDialog()
  const [openSnackbar] = useSnackbar()
  const assetSymbol = window.env.asset_symbol

  const handleClose = () => {
    setAnchorEl(null)
  }
  const onClickUserIcon = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleLogout = async () => {
    handleClose()
    await AuthService.logout()
    navigate('/login')
    setUser(null)
    localStorage.removeItem('minando-user')
    setPaymentWalletStatus(null)
  }

  const onClickEntry = (url: string) => {
    handleClose()
    navigate(url)
  }

  const showSnackbar = useCallback(
    (message: string, severity: AlertColor) => {
      openSnackbar({
        autoHideDuration: 3000,
        message: message,
        severity: severity,
      })
    },
    [openSnackbar]
  )

  const onEnteredCorrectWalletPassphrase = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.getFetchPaymentWalletStatusKey())
    closeDialog()
    setPaymentWalletStatus(PaymentWalletStatus.UNLOCKED)
    showSnackbar(t('La wallet de pagos ha sido desbloqueada con éxito.'), 'success')
  }, [closeDialog, showSnackbar, setPaymentWalletStatus])

  const onLockPaymentWallet = useCallback(async () => {
    const didSucceed = await PaymentWalletService.lockPaymentWallet(assetSymbol)
    if (didSucceed) {
      queryClient.invalidateQueries(QueryKeys.getFetchPaymentWalletStatusKey())
      showSnackbar(t('La wallet de pagos ha sido bloqueada con éxito.'), 'success')
      setPaymentWalletStatus(PaymentWalletStatus.LOCKED)
      closeDialog()
    } else {
      showSnackbar(t('No se pudo bloquear. Por favor reintente.'), 'error')
    }
  }, [closeDialog, showSnackbar, setPaymentWalletStatus, assetSymbol])

  const onChangePaymentWalletStatus = useCallback(async () => {
    switch (paymentWalletStatus) {
      case PaymentWalletStatus.LOCKED: {
        openDialog({
          children: (
            <EnterWalletPasswordDialog
              resolveCallback={onEnteredCorrectWalletPassphrase}
              rejectCallback={closeDialog}
            />
          ),
        })
        break
      }
      case PaymentWalletStatus.UNLOCKED: {
        openDialog({
          children: (
            <LockPaymentWalletDialog
              resolveCallback={onLockPaymentWallet}
              rejectCallback={closeDialog}
            />
          ),
        })
        break
      }
      case PaymentWalletStatus.IMPORT_IN_PROGRESS: {
        const newPaymentWalletStatus = await PaymentWalletService.getPaymentWalletStatus(
          assetSymbol
        )
        if (PaymentWalletStatus.IMPORT_IN_PROGRESS === newPaymentWalletStatus) {
          showSnackbar(t('La clave privada ingresada se sigue procesando.'), 'warning')
        } else {
          setPaymentWalletStatus(newPaymentWalletStatus)
        }
        break
      }
      default: {
        break
      }
    }
  }, [
    closeDialog,
    onEnteredCorrectWalletPassphrase,
    onLockPaymentWallet,
    openDialog,
    paymentWalletStatus,
    setPaymentWalletStatus,
    showSnackbar,
    assetSymbol,
  ])

  return (
    <>
      <AppBar position="static" className={classes.root} style={props.style}>
        <Toolbar variant="dense">
          {curentLocation.pathname === '/user-wallet' ? (
            <>
              <Link to={`/userwallet/${props.userId}`}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                >
                  <img src={assetLogo} className={classes.symbolImage} alt="logo" />
                </IconButton>
              </Link>
              <Typography variant="h5" className={classes.root}>
                Minando
              </Typography>
            </>
          ) : (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <div className={classes.root}></div>
            </>
          )}
          {user?.isAdmin &&
            paymentWalletStatus !== null &&
            paymentWalletStatus !== PaymentWalletStatus.NOT_IMPORTED && (
              <Tooltip title={t(paymentWalletStatusIcon.tooltip).toString()}>
                <IconButton
                  edge="end"
                  className={classes.menuButton}
                  color="inherit"
                  size="small"
                  sx={{
                    paddingTop: '20px',
                    marginRight: '1px',
                    color: paymentWalletStatusIcon.color,
                  }}
                  onClick={onChangePaymentWalletStatus}
                >
                  <div>
                    {paymentWalletStatusIcon.iconElement}
                    <Typography variant="body2">{t('Wallet')}</Typography>
                  </div>
                </IconButton>
              </Tooltip>
            )}
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={onClickUserIcon}
          >
            <AccountCircleIcon className={classes.iconUser} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {!user?.isAdmin && (
              <MenuItem onClick={() => onClickEntry('/personal-info')}>{t('Mis datos')}</MenuItem>
            )}
            <MenuItem onClick={() => onClickEntry('/user-wallet/wallet-settings')}>
              {t('Mi wallet')}
            </MenuItem>
            <MenuItem onClick={() => onClickEntry('/btc/details')}>
              {t('Mis ganancias BTC')}
            </MenuItem>
            {MinandoFeatureFlags.IS_MVP_5_ASSET_DETAILS() && (
              <MenuItem onClick={() => onClickEntry('/minen/details')}>{t('Mis MINEN')}</MenuItem>
            )}
            <MenuItem onClick={() => onClickEntry('/change-password')}>
              {t('Cambiar contraseña')}
            </MenuItem>
            <MenuItem onClick={() => onClickEntry('/two-second-factor')}>
              {t('Doble factor de autenticación')}
            </MenuItem>
            {user?.isAdmin && <Divider />}
            {user?.isAdmin && (
              <MenuItem onClick={() => onClickEntry('/admin/checkpoints')}>
                {t('Distribuciones')}
              </MenuItem>
            )}
            {user?.isAdmin && (
              <MenuItem onClick={() => onClickEntry('/admin/notify')}>{t('Notificar')}</MenuItem>
            )}
            {user?.isAdmin && (
              <MenuItem onClick={() => onClickEntry('/admin/private-key')}>
                {t('Vincular wallet de pagos')}
              </MenuItem>
            )}
            {user?.isAdmin && (
              <MenuItem onClick={() => onClickEntry('/admin/users')}>{t('Usuarios')}</MenuItem>
            )}
            {user?.isAdmin && (
              <MenuItem onClick={() => onClickEntry('/admin/tokens')}>{t('Tokens')}</MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleLogout}>{t('Cerrar sesión')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  )
}
