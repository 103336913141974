import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm'
import assetLogo from '../../assets/images/logo-black.svg'
import { Grid, Typography } from '@mui/material'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import { Navigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export default function ResetPasswordPage() {
  const { user } = useContext(AppContext)
  const { t } = useTranslation()
  const { token, email } = useParams()

  if (user) {
    return <Navigate to="/user-wallet" replace />
  }

  if (!Boolean(token) || !Boolean(email)) {
    return <Navigate to="/login" replace />
  }

  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          p: 1,
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <img src={assetLogo} height={'150px'} alt="logo" />
        <Typography gutterBottom variant="h5" align="center" sx={{ width: '75%' }}>
          {t('Crea una contraseña segura')}
        </Typography>
        <Typography variant="body2" align="center" color="text.secondary" sx={{ width: '75%' }}>
          {t(
            'Su contraseña debe tener 8 o más carácteres e incluir al menos un número, una minúscula y una mayúscula.'
          )}
        </Typography>
        <ResetPasswordForm token={token ? token : ''} email={email ? email : ''} />
      </Grid>
    </>
  )
}
