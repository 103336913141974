import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
  Typography,
  MenuItem,
} from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { User } from '../../types/User'
import { useDialog } from '../../utils/hooks/UseDialog'
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog'
import { Form, Formik } from 'formik'
import UserService from '../../api/services/UserService/UserService'
import { useSnackbar } from '../../utils/hooks/UseSnackbar'
import queryClient from '../../utils/QueryClient'
import QueryKeys from '../../utils/QueryKeys'
import { UpdateUserBody } from '../../types/UpdateUserBody'
import { cardTypes } from '../../types/CardTypes'
import AuthService from '../../api/services/AuthService/AuthService'
import { HTTPStatusCodes } from '../../types/HTTPStatusCodes'
import ShadowBox from '../ShadowBox/ShadowBox'
import { CreateUserBody } from '../../types/CreateUserBody'
import Constants from '../../utils/Constants'

const CustomTextField = (props: TextFieldProps) => {
  return (
    <Grid item md={6} xs={12}>
      <TextField fullWidth {...props} />
    </Grid>
  )
}

const CustomTextFieldRequired = (props: TextFieldProps) => {
  return (
    <CustomTextField
      required
      onInvalid={(e) =>
        (e.target as HTMLFormElement).setCustomValidity(t(Constants.REQUIRED_FIELD_TEXT))
      }
      variant="outlined"
      {...props}
    />
  )
}

interface CustomSwitchProps extends SwitchProps {
  label: string
}

const CustomSwitch = (props: CustomSwitchProps) => {
  return (
    <Grid item md={6} xs={12}>
      <FormControlLabel
        checked={props.checked}
        control={<Switch color="primary" onChange={props.onChange} />}
        label={props.label}
        labelPlacement="end"
        sx={{ m: 0 }}
      />
    </Grid>
  )
}

const CustomSelect = (props: TextFieldProps) => {
  return (
    <Grid item md={6} xs={12}>
      <TextField fullWidth name="state" required select variant="outlined" {...props}>
        {cardTypes.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )
}

interface UserProfileDetailsEditableProps {
  isAdmin: boolean
  rejectCallback: () => void
  resolveCallback: Function
  user: User
}

export default function UserProfileDetailsEditable({
  isAdmin,
  rejectCallback,
  resolveCallback,
  user,
}: UserProfileDetailsEditableProps) {
  const [userEditable, setUserEditable] = useState<User>(user)
  const [isAdminEditable, setIsAdminEditable] = useState<boolean>(isAdmin)
  const [didSucceed, setDidSucceed] = useState<boolean | undefined>()
  const [openDialog, closeDialog] = useDialog()
  const [openSnackbar] = useSnackbar()

  useEffect(() => {
    if (didSucceed && user.id === '') {
      resolveCallback(userEditable, isAdminEditable)
    }
  }, [didSucceed, isAdminEditable, resolveCallback, user.id, userEditable])

  const handleChangeCUIT = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (newValue.length === 0) {
      setUserEditable({ ...userEditable, cuit: null })
      return
    }
    if (newValue.length >= 12) {
      setUserEditable({ ...userEditable, cuit: newValue.substring(0, 11) })
      return
    }
    const isNumber = /^\d+$/
    if (isNumber.test(newValue)) {
      setUserEditable({ ...userEditable, cuit: newValue })
      return
    }
  }

  const handleChangeIsAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    if (!checked) {
      setIsAdminEditable(checked)
      return
    }

    openDialog({
      children: (
        <ConfirmationDialog
          message={t('¿Seguro que desea darle permisos de Administrador a este usuario?')}
          onConfirm={() => {
            setIsAdminEditable(checked)
            closeDialog()
          }}
          onCancel={closeDialog}
        />
      ),
    })
  }

  const openSuccessSnackbar = () => {
    openSnackbar({
      autoHideDuration: 3000,
      message: t('Los cambios se han guardado con éxito.'),
      severity: 'success',
    })
  }

  const openErrorSnackbar = () => {
    openSnackbar({
      autoHideDuration: 3000,
      message: t('Ocurrió un error al guardar los cambios.'),
      severity: 'error',
    })
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        if (user.id !== '') {
          const userToUpdate: UpdateUserBody = {
            name: userEditable.userName.name,
            lastName: userEditable.userName.lastName,
            cardType: userEditable.idCard.type.toUpperCase(),
            cardNumber: userEditable.idCard.number,
            phoneNumber: userEditable.phoneNumber,
            cuit: userEditable.cuit,
            mainContact: userEditable.mainContact,
            offerLetter: userEditable.offerLetter,
            uif: userEditable.uif,
            pep: userEditable.pep,
            trustAgreement: userEditable.trustAgreement,
            documentKeeper: userEditable.documentKeeper,
          }
          let updateSuccess = await UserService.update(user.id, userToUpdate)
          if (updateSuccess && isAdminEditable !== isAdmin) {
            const response = await AuthService.updateAuthUser(user.id, isAdminEditable)
            updateSuccess = response.statusCode === HTTPStatusCodes.OK
          }
          if (updateSuccess) {
            openSuccessSnackbar()
            queryClient.invalidateQueries(QueryKeys.getFetchUserKey(user.id))
            queryClient.invalidateQueries(QueryKeys.getFetchAuthUserKey(user.id))
            resolveCallback()
          } else {
            openErrorSnackbar()
          }
        } else {
          setDidSucceed(undefined)
          const userToCreate: CreateUserBody = {
            firstName: userEditable.userName.name,
            lastName: userEditable.userName.lastName,
            email: userEditable.email,
            isAdmin: isAdminEditable,
            cardType: userEditable.idCard.type,
            cardNumber: userEditable.idCard.number,
            phoneNumber: userEditable.phoneNumber,
            cuit: userEditable.cuit || null,
            mainContact: userEditable.mainContact,
            offerLetter: userEditable.offerLetter,
            uif: userEditable.uif,
            pep: userEditable.pep,
            trustAgreement: userEditable.trustAgreement,
            documentKeeper: userEditable.documentKeeper,
          }
          let createSuccess = await AuthService.createAuthUser(userToCreate)
          if (createSuccess.statusCode === HTTPStatusCodes.OK) {
            openSuccessSnackbar()
            setUserEditable({ ...userEditable, id: createSuccess.data })
            setDidSucceed(true)
          } else {
            openErrorSnackbar()
            setDidSucceed(false)
          }
        }
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ShadowBox>
            <Grid container mb={3}>
              <Grid item>
                <Typography variant="h6">{t('Datos personales')}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <CustomTextFieldRequired
                label={t('Nombre')}
                value={userEditable.userName.name}
                onChange={(e) =>
                  setUserEditable({
                    ...userEditable,
                    userName: { ...userEditable.userName, name: e.target.value },
                  })
                }
              />
              <CustomTextFieldRequired
                label={t('Apellido')}
                value={userEditable.userName.lastName}
                onChange={(e) =>
                  setUserEditable({
                    ...userEditable,
                    userName: { ...userEditable.userName, lastName: e.target.value },
                  })
                }
              />
              <CustomSelect
                label={t('Tipo documento')}
                value={userEditable.idCard.type?.toUpperCase()}
                onChange={(e) =>
                  setUserEditable({
                    ...userEditable,
                    idCard: { ...userEditable.idCard, type: e.target.value },
                  })
                }
              />
              <CustomTextFieldRequired
                label={t('Número de documento')}
                value={userEditable.idCard.number}
                onChange={(e) =>
                  setUserEditable({
                    ...userEditable,
                    idCard: { ...userEditable.idCard, number: e.target.value },
                  })
                }
              />
              <CustomTextField
                label={t('CUIT')}
                value={userEditable.cuit}
                onChange={handleChangeCUIT}
                type="number"
              />
              <CustomTextFieldRequired
                label={t('Número de teléfono')}
                value={userEditable.phoneNumber}
                onChange={(e) => setUserEditable({ ...userEditable, phoneNumber: e.target.value })}
                type="number"
              />
              <CustomTextFieldRequired
                label={t('Contacto')}
                value={userEditable.mainContact}
                onChange={(e) => setUserEditable({ ...userEditable, mainContact: e.target.value })}
              />
              <CustomTextField
                label={t('Poseedor de documentos')}
                value={userEditable.documentKeeper}
                onChange={(e) =>
                  setUserEditable({ ...userEditable, documentKeeper: e.target.value })
                }
              />
              <CustomSwitch
                label={t('Carta oferta').toString()}
                checked={userEditable.offerLetter}
                onChange={(e) =>
                  setUserEditable({ ...userEditable, offerLetter: e.target.checked })
                }
              />
              <CustomSwitch
                label={t('UIF').toString()}
                checked={userEditable.uif}
                onChange={(e) => setUserEditable({ ...userEditable, uif: e.target.checked })}
              />
              <CustomSwitch
                label={t('PEP').toString()}
                checked={userEditable.pep}
                onChange={(e) => setUserEditable({ ...userEditable, pep: e.target.checked })}
              />
              <CustomSwitch
                label={t('Contrato de fideicomiso').toString()}
                checked={userEditable.trustAgreement}
                onChange={(e) =>
                  setUserEditable({ ...userEditable, trustAgreement: e.target.checked })
                }
              />
              <CustomSwitch
                label={t('Es admin').toString()}
                checked={isAdminEditable.valueOf()}
                onChange={handleChangeIsAdmin}
              />
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
              }}
              spacing={1}
            >
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={rejectCallback}
                  disabled={formik.isSubmitting}
                >
                  {t('Cancelar')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={26} color="inherit" />
                  ) : (
                    t('Guardar cambios')
                  )}
                </Button>
              </Grid>
            </Grid>
          </ShadowBox>
        </Form>
      )}
    </Formik>
  )
}
