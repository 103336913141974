import { t } from 'i18next'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  DialogProps,
} from '@mui/material'
import { useState, useCallback } from 'react'
import Constants from '../../utils/Constants'
import { makeStyles } from '@mui/styles'
import PaymentWalletService from '../../api/services/PaymentWalletService/PaymentWalletService'

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginBottom: 10,
  },
})

interface EnterWalletPasswordDialogProps extends Omit<DialogProps, 'open'> {
  rejectCallback: Function
  resolveCallback: Function
}

export default function EnterWalletPasswordDialog(props: EnterWalletPasswordDialogProps) {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [walletPassphrase, setWalletPassphrase] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { rejectCallback, resolveCallback, title, ...rest } = props
  const assetSymbol = window.env.asset_symbol

  const cleanState = () => {
    setWalletPassphrase('')
    setErrorMessage('')
  }

  const onSubmit = async () => {
    setErrorMessage('')

    if (!walletPassphrase) {
      setErrorMessage(t(Constants.REQUIRED_FIELD_TEXT))
      return
    }
    setIsSubmitting(true)
    const didSucceed = await PaymentWalletService.unlockPaymentWallet(walletPassphrase, assetSymbol)
    if (didSucceed) {
      cleanState()
      props.resolveCallback()
    } else {
      setErrorMessage(t('No se pudo desbloquear. Por favor reintente.'))
    }
    setIsSubmitting(false)
  }

  const onClose = useCallback(() => {
    cleanState()
    props.rejectCallback()
  }, [props])

  const handleChange = (event: any) => {
    var value = event.target.value
    setWalletPassphrase(value)
  }

  return (
    <Dialog {...rest} open>
      <DialogTitle>{t('Desbloquear wallet de pagos')}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            id="walletPassphrase"
            name="walletPassphrase"
            label={t('Frase de contraseña')}
            variant="outlined"
            fullWidth
            value={walletPassphrase}
            onChange={(event) => handleChange(event)}
            margin="dense"
            multiline
          />
          {errorMessage && <Grid>{<Grid className={classes.error}> {errorMessage} </Grid>}</Grid>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isSubmitting}
          sx={{ mr: 1 }}
          type="button"
          onClick={() => onClose()}
        >
          {t('Cancelar')}
        </Button>
        <Button
          variant="contained"
          disabled={isSubmitting}
          type="button"
          onClick={() => onSubmit()}
        >
          {isSubmitting ? <CircularProgress size={26} color="inherit" /> : t('Aceptar')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
