import { Grid, ListItem, ListItemButton, ListItemIcon, Typography, Skeleton } from '@mui/material'
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material'
import { DistributionDetail } from '../../types/DistributionDetail'
import { getDistributionStatusIcon } from '../../utils/functions/getDistributionStatusIcon'

interface AdminDistributeUserListItemProps {
  distribution: DistributionDetail
  payload: (distribution: DistributionDetail) => void
}

export default function AdminDistributeUserListItem(props: AdminDistributeUserListItemProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => props.payload(props.distribution)}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="large" />
        </ListItemIcon>
        <Grid container item flexDirection="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={5} textAlign="left">
            {props.distribution.username ? (
              <>
                <Typography variant="body1">{props.distribution.username}</Typography>
                <Typography variant="caption">{props.distribution.userEmail}</Typography>
              </>
            ) : (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={50} />
            )}
          </Grid>
          <Grid item xs={5} textAlign="center">
            <Typography variant="body1" sx={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
              {props.distribution.amount} {props.distribution.tokenId}
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <>{getDistributionStatusIcon(props.distribution.status)}</>
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  )
}
