import { Grid, ListItem, Typography, ListItemButton, ListItemIcon } from '@mui/material'
import { t } from 'i18next'
import { CalendarTodayRounded as CalendarIcon } from '@mui/icons-material'
import { getDistributionStatusIcon } from '../../utils/functions/getDistributionStatusIcon'
import { AssetDistributedStatus } from '../../types/AssetDistributedStatus'

interface AdminDistributeCheckpointItemProps {
  distributionId: string
  dateTo: Date
  dateFrom: Date
  tokenId: string
  quantity: number
  onClickCheckPoint: Function
  status: AssetDistributedStatus
}

export default function AdminDistributeCheckpointItem(props: AdminDistributeCheckpointItemProps) {
  const dateFromString = new Date(props.dateFrom).toLocaleDateString('en-GB')
  const dateToString = new Date(props.dateTo).toLocaleDateString('en-GB')
  const quantity = props.quantity

  return (
    <ListItem
      disablePadding
      onClick={() => props.onClickCheckPoint(props.tokenId, props.distributionId)}
    >
      <ListItemButton>
        <Grid
          container
          item
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          textAlign="left"
        >
          <Grid item xs={1}>
            <ListItemIcon>
              <CalendarIcon fontSize="medium" />
            </ListItemIcon>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography variant="body1" align="center">
              {dateFromString} - {dateToString}
            </Typography>
          </Grid>
          <Grid item xs={5} md={4}>
            <Typography variant="body1" align="right">
              {t('X Distribuido', { tokenId: props.tokenId })}: {quantity}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {getDistributionStatusIcon(props.status, false)}
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  )
}
